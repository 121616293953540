import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaPlaneDeparture, FaPlaneArrival, FaCalendarAlt, FaClock, FaUsers, FaSuitcase } from 'react-icons/fa';
import '../../styles/FlightDetails.css';
import ProgressTracker from './ProgressTracker';

const FlightDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFlight, setSelectedFlight] = useState(location.state?.selectedFlight || {});
  const [isRoundTrip, setIsRoundTrip] = useState(location.state?.isRoundTrip || false);
  const [searchParams, setSearchParams] = useState(location.state?.searchParams || {});

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, '', '/flight-details');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!selectedFlight || Object.keys(selectedFlight).length === 0) {
      navigate('/book-flight', { replace: true });
    }
  }, [selectedFlight, navigate]);

  const handleContinue = () => {
    navigate('/flight-tickets', { 
      state: { 
        selectedFlight,
        isRoundTrip,
        searchParams
      }, 
      replace: true 
    });
  }

  const renderFlightInfo = (isReturn = false) => (
    <div className="flight-info">
      <div className="info-item">
        <FaCalendarAlt />
        <span>{t('Date')}: {isReturn ? selectedFlight.returnDate : selectedFlight.departureDate}</span>
      </div>
      <div className="info-item">
        <FaClock />
        <span>{t('Time')}: {isReturn ? selectedFlight.returnTime : selectedFlight.departureTime}</span>
      </div>
      <div className="info-item">
        <FaUsers />
        <span>{t('Total Seats')}: {selectedFlight.economyClassTotalSeats + selectedFlight.businessClassTotalSeats + selectedFlight.firstClassTotalSeats}</span>
      </div>
      <div className="info-item">
        <FaSuitcase />
        <span>{t('Baggage Allowance')}: 23kg</span>
      </div>
    </div>
  );

  return (
    <>
      <ProgressTracker currentStep={0} />
      <div className="flight-details-page">
        <div className="flight-details-container">
          <div className="flight-header">
            <h1>{isRoundTrip ? t('Round Trip Flight Details') : t('Flight Details')}</h1>
            <div className="flight-route">
              <span>{t(selectedFlight.routeSource)}</span>
              <FaPlaneDeparture className="plane-icon" />
              <span>{t(selectedFlight.routeDestination)}</span>
              {isRoundTrip && (
                <>
                  <FaPlaneArrival className="plane-icon" />
                  <span>{t(selectedFlight.routeSource)}</span>
                </>
              )}
            </div>
          </div>

          <div className="flight-info-container">
            <h2>{t('Outbound Flight')}</h2>
            {renderFlightInfo()}
          </div>

          {isRoundTrip && (
            <div className="flight-info-container">
              <h2>{t('Return Flight')}</h2>
              {renderFlightInfo(true)}
            </div>
          )}

          <div className="flight-classes">
            <h2>{t('Available Classes')}</h2>
            <div className="class-details">
              {['economy', 'business', 'first'].map((classType) => (
                selectedFlight[`${classType}ClassTotalSeats`] > 0 && (
                  <div key={classType} className="class-item">
                    <h3>{t(`${classType} Class`)}</h3>
                    {/* <p>{t('Total Seats')}: {selectedFlight[`${classType}ClassTotalSeats`]}</p> */}
                    <p>{t('Available Seats')}: {selectedFlight[`${classType}ClassRemainingSeats`]}</p>
                    <p>{t('Price')}: ${selectedFlight[`${classType}ClassTicketCost`]}</p>
                  </div>
                )
              ))}
            </div>
          </div>

          <button className="continue-btn" onClick={handleContinue}>
            {t('Continue to Ticket Selection')}
          </button>
        </div>
      </div>
    </>
  );
};

export default FlightDetails;