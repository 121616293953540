import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../config/firebase'; // ייבוא auth
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { FaSearch } from 'react-icons/fa';
import '../../styles/ManageAirplaneTypes.css';

function ManageAirplaneTypes() {
    const { t } = useTranslation();
    const [airplaneTypes, setAirplaneTypes] = useState([]);
    const [filteredAirplaneTypes, setFilteredAirplaneTypes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newAirplaneType, setNewAirplaneType] = useState({
        name: '',
        firstClass: { rows: '', seatsPerRow: '' },
        businessClass: { rows: '', seatsPerRow: '' },
        economyClass: { rows: '', seatsPerRow: '' }
    });
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        const fetchAirplaneTypes = async () => {
            const querySnapshot = await getDocs(collection(db, "airplaneTypes"));
            const airplaneTypesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAirplaneTypes(airplaneTypesData);
            setFilteredAirplaneTypes(airplaneTypesData);
        };
        fetchAirplaneTypes();
    }, []);

    useEffect(() => {
        const filtered = airplaneTypes.filter(type => 
            type.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredAirplaneTypes(filtered);
    }, [searchTerm, airplaneTypes]);

    const handleAdd = async () => {
        const docRef = await addDoc(collection(db, "airplaneTypes"), newAirplaneType);
        const newType = { id: docRef.id, ...newAirplaneType };
        setAirplaneTypes([...airplaneTypes, newType]);
        setFilteredAirplaneTypes([...filteredAirplaneTypes, newType]);
        setNewAirplaneType({
            name: '',
            firstClass: { rows: '', seatsPerRow: '' },
            businessClass: { rows: '', seatsPerRow: '' },
            economyClass: { rows: '', seatsPerRow: '' }
        });
    };

    const handleEdit = async (id) => {
        const docRef = doc(db, "airplaneTypes", id);
        const updatedType = airplaneTypes.find(type => type.id === id);
        await updateDoc(docRef, updatedType);
        setEditing(null);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Yes, delete it!')
        });

        if (result.isConfirmed) {
            await deleteDoc(doc(db, "airplaneTypes", id));
            const updatedTypes = airplaneTypes.filter(type => type.id !== id);
            setAirplaneTypes(updatedTypes);
            setFilteredAirplaneTypes(updatedTypes.filter(type => 
                type.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
            Swal.fire(
                t('Deleted!'),
                t('The airplane type has been deleted.'),
                'success'
            );
        }
    };

    const handleChange = (e, field, subField, id) => {
        const setValue = (prevState) => ({
            ...prevState,
            [field]: subField
                ? { ...prevState[field], [subField]: e.target.value }
                : e.target.value
        });

        if (id) {
            const updatedTypes = airplaneTypes.map(type =>
                type.id === id ? setValue(type) : type
            );
            setAirplaneTypes(updatedTypes);
            setFilteredAirplaneTypes(updatedTypes.filter(type => 
                type.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setNewAirplaneType(setValue);
        }
    };

    const renderClassInputs = (classType, type, id = null) => (
        <div className="class-inputs">
            <input
                type="number"
                value={type[classType].rows}
                onChange={(e) => handleChange(e, classType, 'rows', id)}
                placeholder={t('Rows')}
            />
            <input
                type="number"
                value={type[classType].seatsPerRow}
                onChange={(e) => handleChange(e, classType, 'seatsPerRow', id)}
                placeholder={t('Seats per Row')}
            />
        </div>
    );

    return (
        <div className="manage-airplane-types-container">
            <h1>{t('Airplane Configuration Management')}</h1>
            <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                    type="text"
                    placeholder={t('Search airplane types...')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <table className="airplane-types-table">
                <thead>
                    <tr>
                        <th>{t('Aircraft Type')}</th>
                        <th>{t('First Class')}</th>
                        <th>{t('Business Class')}</th>
                        <th>{t('Economy Class')}</th>
                        <th>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAirplaneTypes.map((type) => (
                        <tr key={type.id}>
                            <td>
                                {editing === type.id ? (
                                    <input
                                        type="text"
                                        value={type.name}
                                        onChange={(e) => handleChange(e, 'name', null, type.id)}
                                        placeholder={t('Aircraft Type')}
                                    />
                                ) : (
                                    t(type.name)
                                )}
                            </td>
                            {['firstClass', 'businessClass', 'economyClass'].map(classType => (
                                <td key={classType}>
                                    {editing === type.id ? (
                                        renderClassInputs(classType, type, type.id)
                                    ) : (
                                        `${type[classType].rows} ${t('Rows')}, ${type[classType].seatsPerRow} ${t('Seats/Row')}`
                                    )}
                                </td>
                            ))}
                            <td>
                                {editing === type.id ? (
                                    <>
                                        <button className="edit-btn" onClick={() => handleEdit(type.id)}>{t('Save')}</button>
                                        <button className="cancel-btn" onClick={() => setEditing(null)}>{t('Cancel')}</button>
                                    </>
                                ) : (
                                    <>
                                        <button className="edit-btn" onClick={() => setEditing(type.id)}>{t('Edit')}</button>
                                        <button className="delete-btn" onClick={() => handleDelete(type.id)}>{t('Delete')}</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr className="add-new-type">
                        <td>
                            <input
                                type="text"
                                value={newAirplaneType.name}
                                onChange={(e) => handleChange(e, 'name')}
                                placeholder={t('New Aircraft Type')}
                            />
                        </td>
                        {['firstClass', 'businessClass', 'economyClass'].map(classType => (
                            <td key={classType}>
                                {renderClassInputs(classType, newAirplaneType)}
                            </td>
                        ))}
                        <td>
                            <button onClick={handleAdd}>{t('Add New Type')}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ManageAirplaneTypes;