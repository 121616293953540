import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../../config/firebase';
import { collection, doc, setDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signOut, reload } from 'firebase/auth';
import '../../styles/AuthPage.css';

const AuthPage = () => {
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState(''); // Added for double password input
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [canResendEmail, setCanResendEmail] = useState(false);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  

  const isPhoneNumberValid = (phone) => {
    const intlPhoneRegex = /^\+?[1-9]\d{1,14}$/;
    const israeliPhoneRegex = /^05\d{8}$/;
    return intlPhoneRegex.test(phone) || israeliPhoneRegex.test(phone);
  };  

  const handleEmailAuth = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    if (!isLogin && !isPhoneNumberValid(phoneNo)) {
      Swal.fire({
        icon: 'error',
        title: t('Invalid Phone Number'),
        text: t('Please enter a valid phone number.'),
      });
      setLoading(false);
      return;
    }
    
    if (!isLogin && password !== verifyPassword) {
      Swal.fire({
        icon: 'error',
        title: t('Error'),
        text: t('The passwords do not match.'),
      });
      setLoading(false);
      return;
    }
    
    try {
      // Show loading message
      const loadingAlert = Swal.fire({
        title: t('Please wait...'),
        text: t('Processing your request'),
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
  
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        if (!auth.currentUser.emailVerified) {
          loadingAlert.close();
          Swal.fire({
            icon: 'warning',
            title: t('Email Not Verified'),
            text: t('Please verify your email to log in.'),
          });
          signOut(auth);
        } else {
          loadingAlert.close();
          Swal.fire({
            icon: 'success',
            title: t('Welcome back!'),
            text: t('You have successfully logged in.'),
            timer: 3000,
            showConfirmButton: false
          }).then(() => {
            navigate('/');
            setLoading(false);
          });
        }
      } else {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(result.user);
        
        await setDoc(doc(collection(db, 'users'), result.user.uid), {
          name,
          email,
          phoneNumber: phoneNo,
          uid: result.user.uid,
          emailVerified: false,
        });
        
        loadingAlert.close();
        Swal.fire({
          icon: 'success',
          title: t('Welcome!'),
          text: t('Your account has been created successfully. Please check your email for verification.'),
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          navigate('/');
          setLoading(false);
        });
        
        setShowVerificationMessage(true);
        setCanResendEmail(false);
        setTimer(60);
        startTimer();
        signOut(auth);
      }
    } catch (error) {
      console.error('Email auth error:', error);
      Swal.fire({
        icon: 'error',
        title: t('Authentication Failed'),
        text: t('Please check your credentials and try again.'),
      });
      setLoading(false);
    }
  };

  
  
  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire({
        icon: 'success',
        title: t('Email Sent'),
        text: t('Password reset email sent successfully!'),
      });
      setIsResetPassword(false);
    } catch (error) {
      console.error('Password reset error:', error);
      Swal.fire({
        icon: 'error',
        title: t('Reset Failed'),
        text: t('Failed to send password reset email. Please check your email and try again.'),
      });
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(auth.currentUser);
      Swal.fire({
        icon: 'success',
        title: t('Email Sent'),
        text: t('A new verification email has been sent to your email address.'),
      });
      setCanResendEmail(false);
      setTimer(60);
      startTimer();
    } catch (error) {
      console.error('Resend email error:', error);
      Swal.fire({
        icon: 'error',
        title: t('Resend Failed'),
        text: t('Failed to resend the verification email. Please try again.'),
      });
    }
  };
  
  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setCanResendEmail(true);
          return 60;
        }
        return prev - 1;
      });
    }, 1000);
  };
  
  const letHimIn = () => {
    navigate ('/account');
  }
  
  useEffect(() => {
    const checkEmailVerification = async () => {
      if (auth.currentUser) {
        await reload(auth.currentUser);
        if (auth.currentUser.emailVerified) {
          await setDoc(doc(db, 'users', auth.currentUser.uid), {
            emailVerified: true,
          }, { merge: true });
          
          Swal.fire({
            icon: 'success',
            title: t('Your email has been verified'),
            text: t('You can now sign in with your new account.'),
          }).then(() => {
            navigate('/auth');
          });
          
          setShowVerificationMessage(false);
        } else {
          setTimeout(checkEmailVerification, 3000);
        }
      }
    };
    
    if (showVerificationMessage) {
      checkEmailVerification();
    }
  }, [showVerificationMessage, navigate]);

  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/account');
      } 
    });

    return () => unsubscribe();
  }, [navigate]);




  return (
    <div className="auth-page">
      <div className="auth-container">
        <h1>{isLogin ? t('Welcome Back') : t('Join Us')}</h1>
        <div className="auth-toggle">
          <button
            className={isLogin ? 'active' : ''}
            onClick={() => setIsLogin(true)}
            disabled={loading}
          >
            {t('Login')}
          </button>
          <button
            className={!isLogin ? 'active' : ''}
            onClick={() => setIsLogin(false)}
            disabled={loading}
          >
            {t('Sign Up')}
          </button>
        </div>

        {loading ? (
          <div className="loading-spinner">{t('Loading...')}</div>
        ) : showVerificationMessage ? (
          <>
            <p>{t('We sent you a confirmation email. Please check your email.')}</p>
            <p>{t('If you did not receive the email, you can resend it after the timer expires.')}</p>
            <button onClick={resendVerificationEmail} disabled={!canResendEmail}>
              {canResendEmail ? t('Resend Verification Email') : `${t('Resend available in')} ${timer} ${t('seconds')}`}
            </button>
            <button onClick={letHimIn}>
              confirmed? sign in!
            </button>
          </>
        ) : (
          <>
            {isResetPassword ? (
              <form onSubmit={handlePasswordReset}>
                <input
                  type="email"
                  placeholder={t('Enter your email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={loading}
                />
                <div className="reset-buttons-container">
                  <button type="submit" className="reset-btn" disabled={loading}>
                    {t('Reset Password')}
                  </button>
                  <button
                    type="button"
                    className="back-btn"
                    onClick={() => { setIsResetPassword(false); setEmail(''); }}
                    disabled={loading}
                  >
                    {t('Back to Login')}
                  </button>
                </div>
              </form>
            ) : (
            <form onSubmit={handleEmailAuth}>
              {!isLogin && (
                <input
                  type="text"
                  placeholder={t('Full Name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  disabled={loading}
                />
              )}
              <input
                type="email"
                placeholder={t('Email Address')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading}
              />
              <div className="password-wrapper">
                <input
                  type="password"
                  placeholder={t('Password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={loading}
                />
                {!isLogin && (
                  <input
                    type="password"
                    placeholder={t('Verify Password')}
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                    required
                    disabled={loading}
                  />
                )}
              </div>
              {!isLogin && (
                <input
                  type="tel"
                  placeholder={t('Phone Number')}
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  required
                  disabled={loading}
                />
              )}
              <button type="submit" disabled={loading}>
                {isLogin ? t('Login') : t('Create Account')}
              </button>
            </form>
            )}

            {isLogin && !isResetPassword && (
              <p
                className="forgot-password"
                onClick={() => setIsResetPassword(true)}
              >
                {t('Forgot Password?')}
              </p>
            )}

            <p className="switch-auth">
              {isLogin ? t("Don't have an account? ") : t("Already have an account? ")}
              <span onClick={() => setIsLogin(!isLogin)} disabled={loading}>
                {isLogin ? t('Sign Up') : t('Login')}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
