import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from '../config/firebase';
import '../styles/Header.css';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa';
import FloatingChatButton from '../Backend/FloatingChatButton';
import logo from '../../src/assets/logos/logo PleasuAir.png';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function Header() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null); // הוספנו את הסטייט לתפקיד
  const [activeLink, setActiveLink] = useState('/');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
        }
      } else {
        setUserRole(null);
      }
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      unsubscribe();
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
  };

  const handleImgClick = () => {
    navigate("/");
  };

  const mainLinks = [
    { path: '/', label: 'Home' },
    { path: '/book-flight', label: 'Book Flight' },
    { path: '/about-us', label: 'About Us' },
    { path: '/ChatWithAgent', label: 'ChatWithAgent' },
    // הצגת הקישור ל"עמוד מנהל" רק למנהלים או עובדים
    ...(userRole === 'admin' || userRole === 'worker' ? [{ path: '/admin', label: 'Admin' }] : []),
  ];

  const dropdownLinks = [
    { path: '/FAQ', label: 'FAQ' },
    { path: '/TermsOfUse', label: 'Terms of Use' },
    { path: '/gallery', label: 'Image Gallery' },
    { path: '/business-details', label: 'Business Details' },
    { path: '/offices', label: 'Our Offices' },
  ];

  const allLinks = [...mainLinks, ...dropdownLinks];

  return (
    <header className="header1">
      <div className="header-container">
        <div className="header-left" onClick={handleImgClick}>
          <img src={logo} alt="PleasuAir Logo" className="logo-image" />
        </div>
        <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          {mainLinks.map((link) => (
            <Link 
              key={link.path}
              to={link.path} 
              className={activeLink === link.path ? 'active' : ''}
              onClick={() => handleLinkClick(link.path)}
            >
              {t(link.label)}
            </Link>
          ))}
        </nav>
        <div className="header-right">
          <Link to={isAuthenticated ? '/account' : '/auth'} className="profile-link">
            <FaUser />
          </Link>
          <button 
            className="menu-toggle" 
            onClick={toggleDropdown} 
            aria-label={isDropdownOpen ? "Close menu" : "Open menu"}
          >
            {isDropdownOpen ? <FaTimes /> : <FaBars />}
          </button>
          <div ref={dropdownRef} className={`dropdown-menu ${isDropdownOpen ? 'active' : ''}`}>
            {(isMobile ? allLinks : dropdownLinks).map((link) => (
              <Link 
                key={link.path}
                to={link.path} 
                className={activeLink === link.path ? 'active' : ''}
                onClick={() => handleLinkClick(link.path)}
              >
                {t(link.label)}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <FloatingChatButton />
    </header>
  );
}
