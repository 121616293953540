import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


// i18n.use(initReactI18next).init({
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  resources: {
    en: {
      translation: {
        // user page
        "Quick Access": "Quick Access",
        "Saved Flights": "Saved Flights",
        "Order History": "Order History",
        "Account Settings": "Account Settings",
        "Personal Information": "Personal Information",
        "Security Settings": "Security Settings",
        "Account Activity": "Account Activity",
        "Manage Subscriptions": "Manage Subscriptions",
        "Connected Accounts": "Connected Accounts",
        "User Actions": "User Actions",
        "Sign Out": "Sign Out",
        "Delete Account": "Delete Account",
        "Settings": "Settings",
        "English": "English",
        "Hebrew": "Hebrew",
        "language": "Language",
        "Loading...": "Loading...",
        "Please log in to access your account.": "Please log in to access your account.",
        "Oops...": "Oops...",
        "Name and Email are required!": "Name and Email are required!",
        "Success": "Success",
        "Your profile has been updated!": "Your profile has been updated!",
        "Error": "Error",
        "Current Password": "Current Password",
        "New Password": "New Password",
        "Change Password": "Change Password",
        "Are you sure?": "Are you sure?",
        "You won't be able to revert this!": "You won't be able to revert this!",
        "Yes, delete it!": "Yes, delete it!",
        "No, dont delete.": "לא, אל תמחק.",
        "Your account has been successfully deleted.": "Your account has been successfully deleted.",
        "Failed to delete your account. Please try again later.": "Failed to delete your account. Please try again later.",
        "Error reauthenticating": "Error reauthenticating",
        "Failed to reauthenticate. Please check your current password.": "Failed to reauthenticate. Please check your current password.",
        "Language Settings": "Language Settings",
        "Welcome": "Welcome",
        "User": "User",
        "Flight Type": "סוג טיסה",
    
        // Home Page translations
        "Experience Luxury in the Skies": "Experience Luxury in the Skies",
        "Unforgettable Journeys Await": "Unforgettable Journeys Await",
        "Fly with Elegance and Style": "Fly with Elegance and Style",
        "Your comfort, Our Priority": "Your comfort, Our Priority",
        "Elevate Your Travel Experience": "Elevate Your Travel Experience",
        "Book Now": "Book Now",
        "Advanced Flight Features": "Advanced Flight Features",
        "Passenger Reservations": "Passenger Reservations",
        "Inventory Management": "Inventory Management",
        "Enjoy a range of advanced features like flight reservation, modification, flight booking quotation, API integration, and more.": "Enjoy a range of advanced features like flight reservation, modification, flight booking quotation, API integration, and more.",
        "Manage flight ticket bookings with dynamic packages, transfers, and other features to enhance customer experience.": "Manage flight ticket bookings with dynamic packages, transfers, and other features to enhance customer experience.",
        "Incorporate flight schedules, fares, seat availability, and other inventory management solutions for a seamless operation.": "Incorporate flight schedules, fares, seat availability, and other inventory management solutions for a seamless operation.",

        // Header Component
        "Home": "Home",
        "Book Flight": "Book Flight",
        "Profile": "Profile",
        "Admin": "Admin",
        "Add Flight": "Add Flight",
        "Manage Flights": "Manage Flights",
        "Manage Airplane Types": "Manage Airplane Types",
        "Manage Bookings": "Manage Bookings",
        "Manage Users": "Manage Users",
        "Manage Coupons" : "Manage Coupons",
        "About Us": "About Us",
        "ChatWithAgent": "Chat With Agent",

        // OrderHistory Component
        "Your Order History": "Your Order History",
        "Booking ID": "Booking ID",
        "Flight ID": "Flight ID",
        "Total Cost": "Total Cost",
        "Tickets": "Tickets",
        "Seat": "Seat",
        "You have no orders yet.": "You have no orders yet.",

        // FlightBooking
          "Round Trip": "Round Trip",
          "One Way Trip": "One Way Trip",
          "Choose Origin": "Choose Origin",
          "Choose Destination": "Choose Destination",
          "Book Your Flight": "Book Your Flight",
          "Trip Type": "Trip Type",
          "Flying from": "Flying from",
          "Flying to": "Flying to",
          "Departing": "Departing",
          "Returning": "Returning",
          "Adults (18+)": "Adults (18+)",
          "Children (0-17)": "Children (0-17)",
          "Travel class": "Travel class",
          "Any": "Any",
          "Economy Class": "Economy Class",
          "Business Class": "Business Class",
          "First Class": "First Class",
          "Show flights": "Show flights",
          "Searching for flights...": "Searching for flights...",
          "All departments": "All departments",
          "Find Flights": "Find Flights",



        // FlightDetails
          "From": "From",
          "To": "To",
          "Date": "Date",
          "Time": "Time",
          "Total Seats": "Total Seats",
          "Available Seats": "Available Seats",
          "Flight Classes": "Flight Classes",
          "Economy Class": "Economy Class",
          "Business Class": "Business Class",
          "First Class": "First Class",
          "Price": "Price",
          "Available": "Available",
          "Book This Flight": "Book This Flight",


        // FlightsPage
          "Specific Date Flights": "Specific Date Flights",
          "No flights available for the selected date and criteria": "No flights available for the selected date and criteria",
          "From": "From",
          "To": "To",
          "Date": "Date",
          "Time": "Time",
          "Details": "Details",
          "Save Flight": "Save Flight",
          "Remove from Saved": "Remove from Saved",
          "Other Flights (After Departure Date)": "Other Flights (After Departure Date)",
          "No upcoming flights available": "No upcoming flights available",
          "try again": "try again",
          "Flight Tickets": "Flight Tickets",
          "(Exact Matches)" :"(Exact Matches)",
          "Round Trip Flights" :"Round Trip Flights ",
          "One Way Flights" : "One Way Flights",


        // FlightTickets
          "Anonymous": "Anonymous",
          "You have to select at least one class before booking the flight": "You have to select at least one class before booking the flight",
          "Exceeded Available Seats": "Exceeded Available Seats",
          "You cannot book more than {{remainingSeats}} tickets for Economy Class.": "You cannot book more than {{remainingSeats}} tickets for Economy Class.",
          "You cannot book more than {{remainingSeats}} tickets for Business Class.": "You cannot book more than {{remainingSeats}} tickets for Business Class.",
          "You cannot book more than {{remainingSeats}} tickets for First Class.": "You cannot book more than {{remainingSeats}} tickets for First Class.",
          "Seats updated successfully in Firebase": "Seats updated successfully in Firebase",
          "Error updating seats:": "Error updating seats:",
          "Error updating available seats. Please try again.": "Error updating available seats. Please try again.",
          "Book Your Flight": "Book Your Flight",
          "to": "to",
          "Select Your Class": "Select Your Class",
          "Economy": "Economy",
          "Business": "Business",
          "First Class": "First Class",
          "Standard seat": "Standard seat",
          "1 carry-on bag": "1 carry-on bag",
          "In-flight meal": "In-flight meal",
          "Spacious seat": "Spacious seat",
          "2 checked bags": "2 checked bags",
          "Priority boarding": "Priority boarding",
          "Luxury suite": "Luxury suite",
          "3 checked bags": "3 checked bags",
          "Personal concierge": "Personal concierge",
          "Passenger Details": "Passenger Details",
          "Economy Class Passengers": "Economy Class Passengers",
          "Business Class Passengers": "Business Class Passengers",
          "First Class Passengers": "First Class Passengers",
          "Total": "Total",
          "Calculate Total": "Calculate Total",
          "Book Now": "Book Now",


        // PassengerDetails
          "Passenger": "Passenger",
          "First Name": "First Name",
          "Enter first name": "Enter first name",
          "Last Name": "Last Name",
          "Enter last name": "Enter last name",
          "Date of Birth": "Date of Birth",
          "Gender": "Gender",
          "Select gender": "Select gender",
          "Male": "Male",
          "Female": "Female",
          "Passport Number": "Passport Number",
          "Enter passport number": "Enter passport number",
          "Passport Expiry Date": "Passport Expiry Date",
          "Nationality": "Nationality",
          "Select nationality": "Select nationality",
          "Israeli ID Number": "Israeli ID Number",
          "Enter Israeli ID number": "Enter Israeli ID number",
          "Proceed to Seat Selection": "Proceed to Seat Selection",
          "Invalid Date": "Invalid Date",
          "Date of birth cannot be in the future.": "Date of birth cannot be in the future.",
          "Expired Passport": "Expired Passport",
          "Passport has already expired.": "Passport has already expired.",
          "Passport Expiry Warning": "Passport Expiry Warning",
          "Your passport is expiring soon. Please renew it if it will expire within the next 3 months.": "Your passport is expiring soon. Please renew it if it will expire within the next 3 months.",
          "Missing Details": "Missing Details",
          "Please fill in all details for Passenger {{number}}.": "Please fill in all details for Passenger {{number}}.",
          "Missing ID Number": "Missing ID Number",
          "Please provide an Israeli ID number for Passenger {{number}}.": "Please provide an Israeli ID number for Passenger {{number}}.",
          "Invalid ID Number": "Invalid ID Number",
          "The Israeli ID number for Passenger {{number}} is invalid.": "The Israeli ID number for Passenger {{number}} is invalid.",
          "Invalid Passport Number": "Invalid Passport Number",
          "The passport number for Passenger {{number}} is invalid.": "The passport number for Passenger {{number}} is invalid.",
          "Israeli": "Israeli",
          "Italy": "Italian",
          "Round Trip Flight": "Round Trip Flight",
          "Passenger {{number}}": "Passenger {{number}}",
          "seats in {{className}}": "seats in {{className}} class",


        // paymentpage
          "Secure Payment": "Secure Payment",
          "Enter your credit card details to complete your purchase.": "Enter your credit card details to complete your purchase.",
          "Card Number": "Card Number",
          "Cardholder Name": "Cardholder Name",
          "John Doe": "John Doe",
          "Expiry Date": "Expiry Date",
          "CVV": "CVV",
          "Processing...": "Processing...",
          "Complete Payment": "Complete Payment",
          "Save Order for 60 Minutes": "Save Order for 60 Minutes",
          "Your payment is secured with 256-bit encryption": "Your payment is secured with 256-bit encryption",
          "Payment successful!": "Payment successful!",
          "Payment failed. Please try again.": "Payment failed. Please try again.",
          "Error processing payment. Please try again.": "Error processing payment. Please try again.",
          "Please log in to save your order.": "Please log in to save your order.",
          "Your order has been saved for 60 minutes.": "Your order has been saved for 60 minutes.",
          "Failed to save your order. Please try again.": "Failed to save your order. Please try again.",
          "Remove Saved Flight": "Remove Saved Flight",
          "Your saved order has been removed.": "Your saved order has been removed.",
          "Booking Summary": "Booking Summary",
          "Seats": "Seats",
          "Insurance Waiver": "Insurance Waiver",
          "By waiving travel insurance, you acknowledge that you understand the risks...": "By waiving travel insurance, you acknowledge that you understand the risks...",
          "I Waive Insurance": "I Waive Insurance",
          "Insurance Choice Required": "Insurance Choice Required",
          "Please choose to include travel insurance or waive it.": "Please choose to include travel insurance or waive it.",   
          "Please enter a coupon code": "Please enter a coupon code", 
          "Invalid or already used coupon" : "Invalid or already used coupon",
          "Coupon value is insufficient for the total cost" : "Coupon value is insufficient for the total cost",
          "Include Travel Insurance" : "Include Travel Insurance",
          "I accept the ": "I accept the ",
          "terms and conditions": "terms and conditions",
          "Coupon" : "Coupon",
          "Credit Card": "Credit Card",
          "Hide Booking Details": "Hide Booking Details",
          "Show Booking Details": "Show Booking Details",    
          "ISRAEL ISRAELI": "ISRAEL ISRAELI",
          "Coupon Code": "Coupon Code",
          "Enter coupon code": "Enter coupon code",
          "Apply Coupon": "Apply Coupon",
          "Travel insurance covers trip cancellation, medical emergencies, lost luggage, and travel delays. Terms and conditions apply.": "Travel insurance covers trip cancellation, medical emergencies, lost luggage, and travel delays. Terms and conditions apply.",
          "Order saved for 60 minutes": "Order saved for 60 minutes",
          "Error saving order:": "Error saving order:",
          "Error saving order. Please try again.": "Error saving order. Please try again.",
          "Order removed from saved flights.": "Order removed from saved flights.",
          "Please log in to save your order.": "Please log in to save your order.",
          "or": "or",
          "i choose Waive Insurance": "i choose Waive Insurance",
          "I Accept": "I Accept",
          "Close": "Close",
          "Price Breakdown": "Price Breakdown",
          "Credit card payment involves a 4% commission.": "Credit card payment involves a 4% commission.",
          "Credit Card Commission (4%)": "Credit Card Commission (4%)",
          "Your card details will be securely processed through Visa Checkout.": "Your card details will be securely processed through Visa Checkout.",
          "Enter your credit card details or coupon to complete your purchase.": "Enter your credit card details or coupon to complete your purchase.",
          "Travel Insurance": "Travel Insurance",
          "Bus Service": "Bus Service ",
  


        // InvoiceComponent
        "No booking data found, redirecting to flight booking page.": "No booking data found, redirecting to flight booking page.",
        "Flight Booking Receipt": "Flight Booking Receipt",
        "Export as PDF": "Export as PDF",
        "Passenger Username": "Passenger Username",
        "Invoice Details": "Invoice Details",
        "Flight ID": "Flight ID",
        "Booking ID": "Booking ID",
        "N/A": "N/A",
        "Flight Class": "Flight Class",
        "Ticket Number": "Ticket Number",
        "Seat Number": "Seat Number",
        "Price": "Price",
        "No tickets found": "No tickets found",
        "TOTAL": "TOTAL",
        "THANK YOU FOR BOOKING": "THANK YOU FOR BOOKING",
        "Economy": "Economy",
        "Business": "Business",
        "First": "First",
        "Booking Details": "Booking Details",
        "Class": "Class",
        "Ticket No": "Ticket No",
        "Passport": "Passport",
        "Male": "Male",
        "Female": "Female",
        "Download PDF": "Download PDF",
        "Flight Booking Receipt": "Flight Booking Receipt",
        "Insurance": "Insurance",
        "Insurance Cost": "Insurance Cost",
        "Included": "Included",
        "flight booker name": "flight booker name",
        "Not Included": "Not Included",


        // SeatSelection
          "Airplane configuration not found for type:": "Airplane configuration not found for type:",
          "Airplane configuration not found. Please select a valid airplane type.": "Airplane configuration not found. Please select a valid airplane type.",
          "Error fetching airplane configuration:": "Error fetching airplane configuration:",
          "Error fetching airplane configuration. Please try again.": "Error fetching airplane configuration. Please try again.",
          "First": "First",
          "Business": "Business",
          "Economy": "Economy",
          "Please select: ": "Please select: ",
          "seats in First Class": "seats in First Class",
          "seats in Business Class": "seats in Business Class",
          "seats in Economy Class": "seats in Economy Class",
          "Selected flight or flight ID is not defined": "Selected flight or flight ID is not defined",
          "Anonymous": "Anonymous",
          "Seats updated successfully in Firebase": "Seats updated successfully in Firebase",
          "Error updating seats or saving booking data:": "Error updating seats or saving booking data:",
          "Error saving data. Please try again.": "Error saving data. Please try again.",
          "Please select exactly {{totalTickets}} seats.": "Please select exactly {{totalTickets}} seats.",
          "Loading seat configuration...": "Loading seat configuration...",
          "Seat Selection Instructions": "Seat Selection Instructions",
          "First Class": "First Class",
          "Business Class": "Business Class",
          "Economy Class": "Economy Class",
          "Confirm Seats and continue": "Confirm Seats and continue",
          "Search Again": "Search Again",
          "Selected seats will apply to both outbound and return flights.": "Selected seats will apply to both outbound and return flights.",
          "seats in {{className}}": "seats in {{className}} class",
          "Flight": "Flight",
          "Yes": "Yes",
          "no": "No",
          "seats selection warning": "Seats selection warning",


        // admin page
          "Incorrect Password": "Incorrect Password",
          "Please try again.": "Please try again.",
          "Enter Password": "Enter Password",
          "Flight Type": "Flight Type",
          "Manage Chat Rooms": "Manage Chat Rooms",
          "Admin Login": "Admin Login",  
          "Manage Roles": "Manage Roles",
          "Payment Links": "Payment Links",

        

        // AddFlight
          "Warning": "Warning",
          "Origin and destination cannot be the same": "Origin and destination cannot be the same",
          "OK": "OK",
          "Added!": "Added!",
          "Flight added successfully.": "Flight added successfully.",
          "Error": "Error",
          "Error adding document:": "Error adding document:",
          "Error adding flight": "Error adding flight",
          "Add New Flight": "Add New Flight",
          "General Details": "General Details",
          "Flight Name": "Flight Name",
          "Origin": "Origin",
          "Choose Origin": "Choose Origin",
          "Israel": "Israel",
          "United States": "United States",
          "Canada": "Canada",
          "Australia": "Australia",
          "United Kingdom": "United Kingdom",
          "Germany": "Germany",
          "France": "France",
          "Japan": "Japan",
          "Brazil": "Brazil",
          "South Africa": "South Africa",
          "India": "India",
          "China": "China",
          "Destination": "Destination",
          "Choose Destination": "Choose Destination",
          "Duration (in Hrs)": "Duration (in Hrs)",
          "Departure Date": "Departure Date",
          "Departure Time": "Departure Time",
          "Return Date": "Return Date",
          "Return Time": "Return Time",
          "Airplane Type": "Airplane Type",
          "Select Airplane Type": "Select Airplane Type",
          "Class Details": "Class Details",
          "Economy Class": "Economy Class",
          "Rows": "Rows",
          "Seats per Row": "Seats per Row",
          "Economy Class Ticket Price": "Economy Class Ticket Price",
          "Business Class": "Business Class",
          "Business Class Ticket Price": "Business Class Ticket Price",
          "First Class": "First Class",
          "First Class Ticket Price": "First Class Ticket Price",
          "Add Flight": "Add Flight",
          "Countries" : "Countries",
          "Adding...": "Adding...",



        // ManageAirplaneTypes
          "Manage Airplane Types": "Manage Airplane Types",
          "Name": "Name",
          "First Class": "First Class",
          "Business Class": "Business Class",
          "Economy Class": "Economy Class",
          "Actions": "Actions",
          "Enter airplane type name": "Enter airplane type name",
          "Rows": "Rows",
          "Seats per Row": "Seats per Row",
          "Save": "Save",
          "Edit": "Edit",
          "Delete": "Delete",
          "Add": "Add",

        // ManageBookings
          "Manage Bookings": "Manage Bookings",
          "Flight ID": "Flight ID",
          "Route": "Route",
          "Date": "Date",
          "Actions": "Actions",
          "to": "to",
          "Show Seat Map": "Show Seat Map",
          "Hide Seat Map": "Hide Seat Map",
          "Show Bookings": "Show Bookings",
          "Hide Bookings": "Hide Bookings",
          "Loading": "Loading",
          "Seat Map for": "Seat Map for",
          "Booking ID": "Booking ID",
          "Passenger": "Passenger",
          "Email": "Email",
          "Phone": "Phone",
          "Tickets": "Tickets",
          "Seat": "Seat",
          "N/A": "N/A",
          "No bookings yet": "No bookings yet",
          "No flights available": "No flights available",
          "Error fetching bookings:": "Error fetching bookings:",
          "Error fetching airplane configuration:": "Error fetching airplane configuration:",
          "Airplane configuration not found": "Airplane configuration not found",
          "Seat configuration not available for this flight. Please check the airplane type configuration.": "Seat configuration not available for this flight. Please check the airplane type configuration.",
          "Search passengers...": "Search passengers...",
          "Hide Passengers": "Hide Passengers",
          "Show Passengers": "Show Passengers",
          "Search all flights...": "Search all flights...",
          "Passenger List": "Passenger List",
          "The booking has been deleted and seats have been freed.": "The booking has been deleted and seats have been freed.",
          "Search all flights and bookings...": "Search all flights and bookings...",
          "Delete Ticket": "Delete Ticket",
          "Delete Booking": "Delete Booking",
          "Print Ticket": "Print Ticket",


        // ManageFlights
          "Manage Flights": "Manage Flights",
          "Flight Name": "Flight Name",
          "Route": "Route",
          "Duration": "Duration",
          "Departure": "Departure",
          "Return": "Return",
          "Economy Class": "Economy Class",
          "Business Class": "Business Class",
          "First Class": "First Class",
          "Actions": "Actions",
          "Total Seats": "Total Seats",
          "Price": "Price",
          "Remaining Seats": "Remaining Seats",
          "Save": "Save",
          "Edit": "Edit",
          "Delete": "Delete",
          "N/A": "N/A",
          "You won't be able to revert this!": "You won't be able to revert this!",
          "Yes, delete it!": "Yes, delete it!",
          "Deleted!": "Deleted!",
          "Your flight has been deleted.": "Your flight has been deleted.",
          "Flight": "Flight",
          "Yes": "Yes",


        // ManageUsers
          "Manage Users": "Manage Users",
          "Name": "Name",
          "Email": "Email",
          "Phone Number": "Phone Number",
          "Actions": "Actions",
          "Action for user": "Action for user",
          "Reset Password": "Reset Password",
          "Disable Account": "Disable Account",
          "Delete Account": "Delete Account",
          "Search by name, email or phone number": "Search by name, email or phone number",
          "User": "User",
          "You won't be able to revert this!": "You won't be able to revert this!",
          "Yes, delete it!": "Yes, delete it!",
          "Deleted!": "Deleted!",
          "The user has been deleted.": "The user has been deleted.",
          "Error!": "Error!",
          "There was an issue deleting the user. Please try again later.": "There was an issue deleting the user. Please try again later.",
          "Success!": "Success!",
          "Password reset email has been sent to": "Password reset email has been sent to",
          "You're about to disable this user account. They won't be able to log in until the account is re-enabled.": "You're about to disable this user account. They won't be able to log in until the account is re-enabled.",
          "Yes, disable it!": "Yes, disable it!",
          "Disabled!": "Disabled!",
          "The user account has been disabled.": "The user account has been disabled.",
          "There was an issue disabling the user account. Please try again later.": "There was an issue disabling the user account. Please try again later.",
      

        // CouponManagement
          "Coupon Management": "Coupon Management",
          "Coupon Amount": "Coupon Amount",
          "Enter coupon amount": "Enter coupon amount",
          "Create Coupon": "Create Coupon",
          "Update Coupon": "Update Coupon",
          "Cancel Edit": "Cancel Edit",
          "Error creating coupon. Please try again.": "Error creating coupon. Please try again.",
          "Cannot edit used coupons": "Cannot edit used coupons",
          "Error updating coupon. Please try again.": "Error updating coupon. Please try again.",
          "Error deleting coupon. Please try again.": "Error deleting coupon. Please try again.",
          "Existing Coupons": "Existing Coupons",
          "Code": "Code",
          "Amount": "Amount",
          "Used": "Used",
          "Unused": "Unused",
          "Edit": "Edit",
          "Delete": "Delete",
          "Please enter a valid coupon amount": "Please enter a valid coupon amount",
          "The coupon has been deleted.": "The coupon has been deleted.",

    

        // AuthPage
          "Welcome Back": "Welcome Back",
          "Join Us": "Join Us",
          "Login": "Login",
          "Sign Up": "Sign Up",
          "Loading...": "Loading...",
          "Enter your email": "Enter your email",
          "Reset Password": "Reset Password",
          "Back to Login": "Back to Login",
          "Full Name": "Full Name",
          "Email Address": "Email Address",
          "Password": "Password",
          "Phone Number": "Phone Number",
          "Create Account": "Create Account",
          "Forgot Password?": "Forgot Password?",
          "Don't have an account? ": "Don't have an account? ",
          "Already have an account? ": "Already have an account? ",
          "Invalid Phone Number": "Invalid Phone Number",
          "Please enter a valid phone number.": "Please enter a valid phone number.",
          "Authentication Failed": "Authentication Failed",
          "Please check your credentials and try again.": "Please check your credentials and try again.",
          "Email Sent": "Email Sent",
          "Password reset email sent successfully!": "Password reset email sent successfully!",
          "Reset Failed": "Reset Failed",
          "Failed to send password reset email. Please check your email and try again.": "Failed to send password reset email. Please check your email and try again.",
    

        // SavedFlights
          "Do you really want to remove this flight from your saved flights?": "Do you really want to remove this flight from your saved flights?",
          "Yes, remove it!": "Yes, remove it!",
          "Removed!": "Removed!",
          "The flight has been removed from your saved flights.": "The flight has been removed from your saved flights.",
          "Error!": "Error!",
          "There was an issue removing the flight. Please try again later.": "There was an issue removing the flight. Please try again later.",
          "Saved Flights": "Saved Flights",
          "You have no saved flights": "You have no saved flights",
          "From": "From",
          "To": "To",
          "Date": "Date",
          "Time": "Time",
          "Details": "Details",
          "Remove Flight": "Remove Flight",

        // NotFound
          "Oops! It seems your flight has been delayed": "Oops! It seems your flight has been delayed",
          "The page you were looking for was not found, but don't worry - we have plenty of other destinations!": "The page you were looking for was not found, but don't worry - we have plenty of other destinations!",
          "Return to Homepage": "Return to Homepage",

      
        // ChatWithAgentComponent
          "PLEASUAIR Concierge": "PLEASUAIR Concierge",
          "Hi, Thank you for your message, Soon one of our agents will contact you": "Hi, Thank you for your message, Soon one of our agents will contact you",
          "Type your message here...": "Type your message here...",
          "Send": "Send",


        // TermsOfUse
          "Terms of Use": "Terms of Use",
          "PLEASUAIR - Luxury in Every Journey": "PLEASUAIR - Luxury in Every Journey",
          "1. Acceptance of Terms": "1. Acceptance of Terms",
          "By accessing and using PLEASUAIR services, you agree to be bound by these Terms of Use.": "By accessing and using PLEASUAIR services, you agree to be bound by these Terms of Use.",
          "2. Use of Services": "2. Use of Services",
          "Our services are designed for personal, non-commercial use. You agree not to misuse our services or help anyone else do so.": "Our services are designed for personal, non-commercial use. You agree not to misuse our services or help anyone else do so.",
          "3. Privacy": "3. Privacy",
          "Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect and use your information.": "Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect and use your information.",
          "4. Bookings and Payments": "4. Bookings and Payments",
          "All bookings are subject to availability. Payment is required at the time of booking unless otherwise specified.": "All bookings are subject to availability. Payment is required at the time of booking unless otherwise specified.",
          "5. Cancellations and Refunds": "5. Cancellations and Refunds",
          "Cancellation policies vary depending on the type of service booked. Please refer to the specific terms for each booking.": "Cancellation policies vary depending on the type of service booked. Please refer to the specific terms for each booking.",
          "6. Liability": "6. Liability",
          "PLEASUAIR is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.": "PLEASUAIR is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.",
          "7. Changes to Terms": "7. Changes to Terms",
          "We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the modified terms.": "We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the modified terms.",
          "Last updated: August 2024": "Last updated: August 2024",
          "© 2024 PLEASUAIR. All rights reserved.": "© 2024 PLEASUAIR. All rights reserved.",

        // FAQ
          "Frequently Asked Questions": "Frequently Asked Questions",
          "Find answers to common inquiries about PLEASUAIR LINES": "Find answers to common inquiries about PLEASUAIR LINES",
          "What sets PLEASUAIR LINES apart from other airlines?": "What sets PLEASUAIR LINES apart from other airlines?",
          "PLEASUAIR LINES redefines luxury air travel with our unparalleled service, state-of-the-art aircraft, gourmet dining, and exclusive amenities. Every aspect of your journey is curated to provide the utmost comfort and elegance.": "PLEASUAIR LINES redefines luxury air travel with our unparalleled service, state-of-the-art aircraft, gourmet dining, and exclusive amenities. Every aspect of your journey is curated to provide the utmost comfort and elegance.",
          "How can I book a flight with PLEASUAIR LINES?": "How can I book a flight with PLEASUAIR LINES?",
          "You can book a flight through our website, mobile app, or by contacting our dedicated concierge service. Our team is available 24/7 to assist you with your booking and any special requests.": "You can book a flight through our website, mobile app, or by contacting our dedicated concierge service. Our team is available 24/7 to assist you with your booking and any special requests.",
          "What is included in a first-class ticket?": "What is included in a first-class ticket?",
          "Our first-class experience includes priority check-in and boarding, access to our exclusive lounges, fully-reclining seats with massage function, personalized service, gourmet meals prepared by world-renowned chefs, premium wine selection, luxury amenity kits, and complimentary chauffeur service at select destinations.": "Our first-class experience includes priority check-in and boarding, access to our exclusive lounges, fully-reclining seats with massage function, personalized service, gourmet meals prepared by world-renowned chefs, premium wine selection, luxury amenity kits, and complimentary chauffeur service at select destinations.",
          "Do you offer loyalty programs?": "Do you offer loyalty programs?",
          "Yes, our PLEASUAIR Elite program offers exclusive benefits including priority upgrades, additional baggage allowance, and access to our network of partner luxury hotels and resorts worldwide.": "Yes, our PLEASUAIR Elite program offers exclusive benefits including priority upgrades, additional baggage allowance, and access to our network of partner luxury hotels and resorts worldwide.",
          "What is your baggage policy?": "What is your baggage policy?",
          "Our generous baggage policy allows first-class passengers two pieces of checked luggage up to 32kg each, plus two carry-on items. Business class passengers are allowed two pieces up to 28kg each. We also offer special arrangements for oversized items like sports equipment.": "Our generous baggage policy allows first-class passengers two pieces of checked luggage up to 32kg each, plus two carry-on items. Business class passengers are allowed two pieces up to 28kg each. We also offer special arrangements for oversized items like sports equipment.",
      
        
        // TermsAndConditionsContent
          "Terms and Conditions": "Terms and Conditions",
          "1. Acceptance of Terms": "1. Acceptance of Terms",
          "By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.": "By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.",
          "2. User Responsibilities": "2. User Responsibilities",
          "You agree to use the website responsibly and ensure that your actions comply with all applicable laws and regulations. Failure to do so may result in suspension or termination of your access to the site.": "You agree to use the website responsibly and ensure that your actions comply with all applicable laws and regulations. Failure to do so may result in suspension or termination of your access to the site.",
          "3. Liability Limitations": "3. Liability Limitations",
          "The company is not liable for any damages arising from the use of this website, including but not limited to direct, indirect, incidental, or consequential damages.": "The company is not liable for any damages arising from the use of this website, including but not limited to direct, indirect, incidental, or consequential damages.",
          "Terms Not Accepted": "Terms Not Accepted",
          "Please accept the terms and conditions before proceeding.": "Please accept the terms and conditions before proceeding.",
          "Insurance Waiver": "Insurance Waiver",
          "By waiving travel insurance, you acknowledge that you understand the risks...": "By waiving travel insurance, you acknowledge that you understand the risks...",
          "I Waive Insurance": "I Waive Insurance",
          "Waive Insurance": "Waive Insurance",


        // PaymentLinks
          "Payment Links Manager": "Payment Links Manager",
          "Close": "Close",
          "Add Link": "Add Link",
          "Search links...": "Search links...",
          "Description": "Description",
          "Amount": "Amount",
          "Payment URL": "Payment URL",
          "Save": "Save",
          "Cancel": "Cancel",
          "Pay Now": "Pay Now",
          "Edit link": "Edit link",
          "Delete link": "Delete link",
          "Are you sure?": "Are you sure?",
          "You won't be able to revert this!": "You won't be able to revert this!",
          "Yes, delete it!": "Yes, delete it!",
          "Cancel": "Cancel",
          "Deleted!": "Deleted!",
          "Your link has been deleted.": "Your link has been deleted.",


        // 404
          "404": "404",
          "Page Not Found": "Page Not Found",
          "Looks like this flight path is off the map!": "Looks like this flight path is off the map!",
          "Need help finding your destination?": "Need help finding your destination?",
          "Return to Home": "Return to Home",
          "Contact Support": "Contact Support",
          
    

        // ....
        "seats selection warning": "seats selection warning",
        "hours": "hours",
        "Coupon code copied!": "Coupon code copied!",
        "For cash payment talk to one of our agents": "For cash payment talk to one of our agents",
        "I choose to waive insurance": "I choose to waive insurance",
        "Search flights...": "Search flights...",
        "Include Bus Service": "Include Bus Service",
        "Saved for": "Saved for",
        "minutes": "minutes",
        "Coupon applied successfully": "Coupon applied successfully",
        "Male": "Male",
        "Outbound Flight": "Outbound Flight",
        "Return Flight": "Return Flight",
        "Already Used": "Already Used",
        "Used At": "Used At",
        "Used By": "Used By",
        "Search...": "Search...",
        "Validating...": "Validating...",
        "Coupon application failed: ": "Coupon application failed: ",
        "Selected Seats": "Selected Seats",
        "Plan Your Global Adventure": "Plan Your Global Adventure",



      
      }
    },










    











    he: {
      translation: {    

        // עמוד משתמש
        "Quick Access": "גישה מהירה",
        "Saved Flights": "טיסות שמורות",
        "Order History": "היסטוריית הזמנות",
        "Account Settings": "הגדרות חשבון",
        "Personal Information": "מידע אישי",
        "Security Settings": "הגדרות אבטחה",
        "Account Activity": "פעילות חשבון",
        "Manage Subscriptions": "ניהול מנויים",
        "Connected Accounts": "חשבונות מחוברים",
        "User Actions": "פעולות משתמש",
        "Sign Out": "התנתקות",
        "Delete Account": "מחק חשבון",
        "Settings": "הגדרות",
        "English": "אנגלית",
        "Hebrew": "עברית",
        "language": "שפות",
        "Loading...": "טוען...",
        "Please log in to access your account.": "אנא התחבר כדי לגשת לחשבון שלך.",
        "Oops...": "אופס...",
        "Name and Email are required!": "נדרשים שם ודואר אלקטרוני!",
        "Success": "הצלחה",
        "Your profile has been updated!": "הפרופיל שלך עודכן!",
        "Error": "שגיאה",
        "Current Password": "סיסמה נוכחית",
        "New Password": "סיסמה חדשה",
        "Change Password": "שנה סיסמה",
        "Are you sure?": "האם אתה בטוח?",
        "You won't be able to revert this!": "לא תוכל לשחזר את זה!",
        "Yes, delete it!": "כן, מחק את זה!",
        "Your account has been successfully deleted.": "החשבון שלך נמחק בהצלחה.",
        "Failed to delete your account. Please try again later.": "נכשל במחיקת החשבון שלך. אנא נסה שוב מאוחר יותר.",
        "Error reauthenticating": "שגיאה באימות מחדש",
        "Failed to reauthenticate. Please check your current password.": "נכשל באימות מחדש. אנא בדוק את הסיסמה הנוכחית שלך.",
        "Language Settings": "הגדרות שפה",
        "Welcome": "ברוך הבא",
        "User": "משתמש",
    

        // דף הבית
        "Experience Luxury in the Skies": "חוו את הפאר בשמיים",
        "Unforgettable Journeys Await": "מסעות בלתי נשכחים מחכים",
        "Fly with Elegance and Style": "טוסו באלגנטיות ובסטייל",
        "Your comfort, Our Priority": "הנוחות שלך, העדיפות שלנו",
        "Elevate Your Travel Experience": "שדרגו את חווית הנסיעה שלכם",
        "Book Now": "הזמן עכשיו",
        "Advanced Flight Features": "תכונות טיסה מתקדמות",
        "Passenger Reservations": "הזמנות נוסעים",
        "Inventory Management": "ניהול מלאי",
        "Enjoy a range of advanced features like flight reservation, modification, flight booking quotation, API integration, and more.": "תהנו ממגוון תכונות מתקדמות כמו הזמנת טיסות, שינוי, הצעת מחיר להזמנת טיסה, שילוב API ועוד.",
        "Manage flight ticket bookings with dynamic packages, transfers, and other features to enhance customer experience.": "ניהול הזמנות כרטיסי טיסה עם חבילות דינמיות, העברות ותכונות נוספות לשיפור חווית הלקוח.",
        "Incorporate flight schedules, fares, seat availability, and other inventory management solutions for a seamless operation.": "שלבו לוחות זמנים של טיסות, מחירים, זמינות מושבים ופתרונות ניהול מלאי נוספים להפעלה חלקה.",


        // Header Component
        "Home": "בית",
        "Book Flight": "הזמנת טיסה",
        "Profile": "פרופיל",
        "Admin": "ניהול",
        "Add Flight": "הוסף טיסה",
        "Manage Flights": "ניהול טיסות",
        "Manage Airplane Types": "ניהול סוגי מטוסים",
        "Manage Bookings": "ניהול הזמנות",
        "Manage Users": "ניהול משתמשים",
        "Manage Coupons" : "ניהול שוברים",
        "About Us": "על החברה",
        "ChatWithAgent": "צ'אט עם סוכן",


        "Home": "בית",
        "Book Flight": "הזמן טיסה",
        "About Us": "אודותינו",
        "ChatWithAgent": "צ'אט עם נציג",
        "Admin": "מנהל",
        "FAQ": "שאלות נפוצות",
        "Terms of Use": "תנאי שימוש",
        "Image Gallery": "גלריית תמונות",
        "Business Details": "פרטי עסק",
        "Our Offices": "המשרדים שלנו",


        // OrderHistory Component
        "Your Order History": "היסטוריית ההזמנות שלך",
        "Booking ID": "מזהה הזמנה",
        "Flight ID": "מזהה טיסה",
        "Total Cost": "עלות כוללת",
        "Tickets": "כרטיסים",
        "Seat": "מושב",
        "You have no orders yet.": "אין לך הזמנות עדיין.",


        //FlightBooking
        "Round Trip": "נסיעה הלוך ושוב",
        "One Way Trip": "נסיעה לכיוון אחד",
        "Choose Origin": "בחר מקור",
        "Choose Destination": "בחר יעד",
        "Book Your Flight": "הזמינו את טיסתכם",
        "Trip Type": "סוג נסיעה",
        "Flying from": "טסים מ",
        "Flying to": "טסים ל",
        "Departing": "יציאה",
        "Returning": "תאריך חזרה",
        "Adults (18+)": "מבוגרים (18+)",
        "Children (0-17)": "ילדים (0-17)",
        "Travel class": "מחלקת נסיעה",
        "Any": "כל מחלקה",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "Show flights": "הצג טיסות",
        "Searching for flights...": "מחפש טיסות...",
        "Find Flights" : "הצג טיסות",
        "Too many passengers" : "יותר מדי נוסעים",
        "The total number of passengers cannot exceed 10." : "מספר הנוסעים הכולל לא יכול לעלות על 10.",


        //FlightDetails
        "From": "מ",
        "To": "ל",
        "Date": "תאריך",
        "Time": "שעה",
        "Total Seats": "סך כל המושבים",
        "Available Seats": "מושבים זמינים",
        "Flight Classes": "מחלקות טיסה",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "Price": "מחיר",
        "Available": "זמינים",
        "Book This Flight": "הזמן טיסה זו",
        "Available Classes": "מחלקות זמינות ",
        "economy Class": "מחלקת תיירים",
        "business Class": "מחלקת עסקים",
        "first Class": "מחלקה ראשונה",
        "Select gender" : "בחר מגדר",
        "Male" : "זכר",
        "Female" : "נקבה",
        "Round Trip Flight Details": "פרטי טיסה הלוך ושוב",
        "Flight Details": "פרטי טיסה",
        "Outbound Flight": "טיסת הלוך",
        "Return Flight": "טיסת חזור",
        "Continue to Ticket Selection": "המשך לבחירת כרטיס",



        //FlightsPage
        "Specific Date Flights": "טיסות בתאריך ספציפי",
        "No flights available for the selected date and criteria": "אין טיסות זמינות לתאריך ולקריטריונים שנבחרו",
        "From": "מ",
        "To": "ל",
        "Date": "תאריך",
        "Time": "שעה",
        "Save Flight": "שמור טיסה",
        "Remove from Saved": "הסר מהשמורים",
        "Other Flights (After Departure Date)": "טיסות נוספות (לאחר תאריך היציאה)",
        "No upcoming flights available": "אין טיסות נוספות זמינות",
        "try again": "נסה שוב",
        "Flight Tickets": "כרטיסי טיסה",
        "(Exact Matches)" :"(התאמה מדויקת)",
        "Round Trip Flights" :"טיסות הלוך ושוב",
        "One Way Flights" : "טיסות צד אחד",
        "Flight Tickets": "כרטיס טיסה",



        // FlightTickets
        "Anonymous": "אנונימי",
        "You have to select at least one class before booking the flight": "עליך לבחור לפחות מחלקה אחת לפני הזמנת הטיסה",
        "Exceeded Available Seats": "חריגה מהמושבים הזמינים",
        "You cannot book more than {{remainingSeats}} tickets for Economy Class.": "לא ניתן להזמין יותר מ-{{remainingSeats}} כרטיסים למחלקת תיירים.",
        "You cannot book more than {{remainingSeats}} tickets for Business Class.": "לא ניתן להזמין יותר מ-{{remainingSeats}} כרטיסים למחלקת עסקים.",
        "You cannot book more than {{remainingSeats}} tickets for First Class.": "לא ניתן להזמין יותר מ-{{remainingSeats}} כרטיסים למחלקה ראשונה.",
        "Seats updated successfully in Firebase": "המושבים עודכנו בהצלחה בפיירבייס",
        "Error updating seats:": "שגיאה בעדכון המושבים:",
        "Error updating available seats. Please try again.": "שגיאה בעדכון המושבים הזמינים. נא לנסות שוב.",
        "Book Your Flight": "הזמן את טיסתך",
        "to": "ל",
        "Select Your Class": "בחר את מחלקתך",
        "Economy": "תיירים",
        "Business": "עסקים",
        "First Class": "מחלקה ראשונה",
        "Standard seat": "מושב רגיל",
        "1 carry-on bag": "תיק יד אחד",
        "In-flight meal": "ארוחה בטיסה",
        "Spacious seat": "מושב מרווח",
        "2 checked bags": "שני תיקים רשומים",
        "Priority boarding": "עלייה למטוס עדיפות",
        "Luxury suite": "סוויטת יוקרה",
        "3 checked bags": "שלושה תיקים רשומים",
        "Personal concierge": "שירות אישי",
        "Passenger Details": "פרטי הנוסעים",
        "Economy Class Passengers": "נוסעי מחלקת תיירים",
        "Business Class Passengers": "נוסעי מחלקת עסקים",
        "First Class Passengers": "נוסעי מחלקה ראשונה",
        "Total": "סה''כ",
        "Calculate Total": "חשב סה''כ",
        "Book Now": "הזמן עכשיו",
        "Number of Passengers" : "מספר הנוסעים",
        "Children": "ילדים",
        "Adults": "מבוגרים",


        // InvoiceComponent
        "No booking data found, redirecting to flight booking page.": "לא נמצאו נתוני הזמנה, מועבר לדף הזמנת טיסה.",
        "Flight Booking Receipt": "קבלה להזמנת טיסה",
        "Export as PDF": "ייצא כ-PDF",
        "Passenger Username": "שם משתמש של הנוסע",
        "Invoice Details": "פרטי חשבונית",
        "Flight ID": "מזהה טיסה",
        "Booking ID": "מזהה הזמנה",
        "N/A": "לא זמין",
        "Flight Class": "מחלקת טיסה",
        "Ticket Number": "מספר כרטיס",
        "Seat Number": "מספר מושב",
        "Price": "מחיר",
        "No tickets found": "לא נמצאו כרטיסים",
        "TOTAL": "סה''כ",
        "THANK YOU FOR BOOKING": "תודה על ההזמנה שלך",
        "Economy": "תיירים",
        "Business": "עסקים",
        "First": "ראשונה",
        "Booking Details": "פרטי ההזמנה",
        "Class": "מחלקה",
        "Ticket No": "מספר כרטיס",
        "Passport": "מספר דרכון",
        "Male": "זכר",
        "Female": "נקבה",
        "Download PDF": "הורד PDF",
        "Flight Booking Receipt": "קבלה על הזמנת טיסה",
        "Insurance": "ביטוח",
        "Insurance Cost": " דמי ביטוח",
        "Included": "כלול",
        "Not Included": "אינו כלול",
        "flight booker name": "שם מזמין הטיסה",

        // SeatSelection
        "Airplane configuration not found for type:": "תצורת המטוס לא נמצאה עבור סוג:",
        "Airplane configuration not found. Please select a valid airplane type.": "תצורת המטוס לא נמצאה. אנא בחר סוג מטוס תקף.",
        "Error fetching airplane configuration:": "שגיאה באחזור תצורת המטוס:",
        "Error fetching airplane configuration. Please try again.": "שגיאה באחזור תצורת המטוס. אנא נסה שוב.",
        "First": "ראשונה",
        "Business": "עסקים",
        "Economy": "תיירים",
        "Please select: ": "אנא בחר: ",
        "seats in First Class": "מושבים במחלקה ראשונה",
        "seats in Business Class": "מושבים במחלקת עסקים",
        "seats in Economy Class": "מושבים במחלקת תיירים",
        "Selected flight or flight ID is not defined": "הטיסה שנבחרה או מזהה הטיסה אינם מוגדרים",
        "Anonymous": "אנונימי",
        "Seats updated successfully in Firebase": "המושבים עודכנו בהצלחה בפיירבייס",
        "Error updating seats or saving booking data:": "שגיאה בעדכון מושבים או שמירת נתוני הזמנה:",
        "Error saving data. Please try again.": "שגיאה בשמירת הנתונים. אנא נסה שוב.",
        "Please select exactly {{totalTickets}} seats.": "אנא בחר בדיוק {{totalTickets}} מושבים.",
        "Loading seat configuration...": "טוען תצורת מושבים...",
        "Seat Selection Instructions": "הוראות בחירת מושבים",
        "First Class": "מחלקה ראשונה",
        "Business Class": "מחלקת עסקים",
        "Economy Class": "מחלקת תיירים",
        "Confirm Seats and continue": "אשר מושבים והמשך",
        "Search Again": "חפש שוב",
        "Selected seats will apply to both outbound and return flights.": "המושבים שנבחרו יחולו הן על טיסת היציאה והן על טיסת החזרה.",
        "seats in {{className}}": "מושבים במחלקת {{className}}",
        "Flight": "טיסה",
        "Yes": "כן",
        "no": "לא",
        "seats selection warning": "אזהרת בחירת מושבים",


        // AddFlight
        "Warning": "אזהרה",
        "Origin and destination cannot be the same": "המוצא והיעד לא יכולים להיות זהים",
        "OK": "אישור",
        "Added!": "נוסף!",
        "Flight added successfully.": "הטיסה נוספה בהצלחה.",
        "Error": "שגיאה",
        "Error adding document:": "שגיאה בהוספת המסמך:",
        "Error adding flight": "שגיאה בהוספת הטיסה",
        "Add New Flight": "הוסף טיסה חדשה",
        "General Details": "פרטים כלליים",
        "Flight Name": "שם הטיסה",
        "Origin": "מוצא",
        "Choose Origin": "בחר מוצא",
        "Israel": "ישראל",
        "United States": "ארצות הברית",
        "Canada": "קנדה",
        "Australia": "אוסטרליה",
        "United Kingdom": "הממלכה המאוחדת",
        "Germany": "גרמניה",
        "France": "צרפת",
        "Japan": "יפן",
        "Brazil": "ברזיל",
        "South Africa": "דרום אפריקה",
        "India": "הודו",
        "China": "סין",
        "Other (specify)" : "אחר (ציין)",
        "Countries" : "מדינות",
        "Enter custom origin": "הזן מקור מותאם אישית",
        "Enter custom destination": "הזן יעד מותאם אישית",
        "Destination": "יעד",
        "Choose Destination": "בחר יעד",
        "Duration (in Hrs)": "משך זמן (בשעות)",
        "Departure Date": "תאריך יציאה",
        "Departure Time": "שעת יציאה",
        "Return Date": "תאריך חזרה",
        "Return Time": "שעת חזרה",
        "Airplane Type": "סוג מטוס",
        "Select Airplane Type": "בחר סוג מטוס",
        "Class Details": "פרטי מחלקה",
        "Economy Class": "מחלקת תיירים",
        "Rows": "שורות",
        "Seats per Row": "מושבים בשורה",
        "Economy Class Ticket Price": "מחיר כרטיס מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "Business Class Ticket Price": "מחיר כרטיס מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "First Class Ticket Price": "מחיר כרטיס מחלקה ראשונה",
        "Add Flight": "הוסף טיסה",
        "Country has been deleted.": "המדינה נמחקה",
        "Adding...": "מוסיף...",


        // ManageAirplaneTypes
        "Airplane Configuration Management": "ניהול תצורת מטוסים",
        "Aircraft Type": "סוג מטוס",
        "First Class": "מחלקה ראשונה",
        "Business Class": "מחלקת עסקים",
        "Economy Class": "מחלקת תיירים",
        "Actions": "פעולות",
        "Rows": "שורות",
        "Seats per Row": "מושבים בשורה",
        "Seats/Row": "מושבים/שורה",
        "You won't be able to revert this!": "לא תוכל לבטל פעולה זו!",
        "Yes, delete it!": "כן, מחק את זה!",
        "Deleted!": "נמחק!",
        "The airplane type has been deleted.": "סוג המטוס נמחק.",
        "New Aircraft Type": "סוג מטוס חדש",
        "Add New Type": "הוסף סוג חדש",
        "Save": "שמור",
        "Cancel": "בטל",
        "Edit": "ערוך",
        "Delete": "מחק",
        // "Small Airplane": "מטוס קטן",
        // "Larg plane": "מטוס גדול",
        // "Medium Airplane": "מטוס בינוני",


        // ManageBookings
        "Manage Bookings": "ניהול הזמנות",
        "Flight ID": "מזהה טיסה",
        "Route": "מסלול",
        "Date": "תאריך",
        "Actions": "פעולות",
        "to": "אל",
        "Show Seat Map": "הצג מפת מושבים",
        "Hide Seat Map": "הסתר מפת מושבים",
        "Show Bookings": "הצג הזמנות",
        "Hide Bookings": "הסתר הזמנות",
        "Loading": "טוען",
        "Seat Map for": "מפת מושבים עבור",
        "Booking ID": "מזהה הזמנה",
        "Passenger": "נוסע",
        "Email": "אימייל",
        "Phone": "טלפון",
        "Tickets": "כרטיסים",
        "Seat": "מושב",
        "N/A": "לא זמין",
        "No bookings yet": "אין הזמנות עדיין",
        "No flights available": "אין טיסות זמינות",
        "Error fetching bookings:": "שגיאה בעת שליפת ההזמנות:",
        "Error fetching airplane configuration:": "שגיאה בעת שליפת תצורת המטוס:",
        "Airplane configuration not found": "תצורת המטוס לא נמצאה",
        "Seat configuration not available for this flight. Please check the airplane type configuration.": "תצורת המושבים אינה זמינה לטיסה זו. אנא בדוק את תצורת המטוס.",
        "Search passengers...": "חפש נוסעים...",
        "Hide Passengers": "הסתר נוסעים",
        "Show Passengers": "הצג נוסעים",
        "Search all flights...": "חפש בכל הטיסות...",
        "Passenger List": "רשימת נוסעים",
        "The booking has been deleted and seats have been freed.": "ההזמנה נמחקה והמושבים התפנו.",
        "Search all flights and bookings...": "חפש בכל הטיסות וההזמנות...",
        "Delete Ticket": "מחק כרטיס זה",
        "Delete Booking": "מחק הזמנה זו",
        "Print Ticket": "הדפס כרטיס",



        // ManageFlights
        "Flight Management": "ניהול טיסות",
        "You won't be able to revert this!": "לא תוכל לבטל פעולה זו!",
        "Yes, delete it!": "כן, מחק את זה!",
        "Deleted!": "נמחק!",
        "The flight has been deleted.": "הטיסה נמחקה.",
        "Flight Name": "שם הטיסה",
        "Route": "מסלול",
        "Departure": "המראה",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "Actions": "פעולות",
        "Total": "סה\"כ",
        "Remaining": "נשארו",
        "Save": "שמור",
        "Cancel": "בטל",
        "Edit": "ערוך",
        "Delete": "מחק",


        // ManageUsers
        "Manage Users": "ניהול משתמשים",
        "Name": "שם",
        "Email": "אימייל",
        "Phone Number": "מספר טלפון",
        "Actions": "פעולות",
        "Action for user": "פעולה למשתמש",
        "Reset Password": "איפוס סיסמה",
        "Disable Account": "השבת חשבון",
        "Delete Account": "מחק חשבון",
        "Search by name, email or phone number": "חיפוש לפי שם, אימייל או מספר טלפון",
        "User": "משתמש",
        "You won't be able to revert this!": "לא תוכל לבטל פעולה זו!",
        "Yes, delete it!": "כן, מחק!",
        "Deleted!": "נמחק!",
        "The user has been deleted.": "המשתמש נמחק.",
        "Error!": "שגיאה!",
        "There was an issue deleting the user. Please try again later.": "הייתה בעיה במחיקת המשתמש. אנא נסה שוב מאוחר יותר.",
        "Successfully sent!": "נשלח בהצלחה!",
        "Password reset email has been sent to": "אימייל לאיפוס סיסמה נשלח ל",
        "You're about to disable this user account. They won't be able to log in until the account is re-enabled.": "אתה עומד להשבית את החשבון של משתמש זה. הם לא יוכלו להתחבר עד שהחשבון יופעל מחדש.",
        "Yes, disable it!": "כן, השבת אותו!",
        "Disabled!": "מושבת!",
        "The user account has been disabled.": "החשבון של המשתמש הושבת.",
        "There was an issue disabling the user account. Please try again later.": "הייתה בעיה בהשבתת החשבון של המשתמש. אנא נסה שוב מאוחר יותר.",
        "User Management": "ניהול משתמשים",
        "Send Password Reset Email": "שלח אימייל לאיפוס סיסמה",
        "Confirm Password Reset": "אשר את איפוס הסיסמה",
        "Are you sure you want to send a password reset email to {{email}}?": "האם אתה בטוח שברצונך לשלוח דוא\"ל לאיפוס סיסמה לכתובת {{email}}?",
        "Yes, send the mail!": "כן, שלח את המייל!",
        "Password reset email has been sent to {{email}}.": "אימייל לאיפוס סיסמה נשלח לכתובת {{email}}.",
        "Search": "חיפוש",

        // CouponManagement
        "Coupon Management": "ניהול שוברים",
        "Coupon Amount": "סכום שובר",
        "Enter coupon amount": "הזן את סכום השובר",
        "Create Coupon": "צור שובר",
        "Update Coupon": "עדכן שובר",
        "Cancel Edit": "ביטול עריכה",
        "Error creating coupon. Please try again.": "שגיאה ביצירת השובר. אנא נסה שוב.",
        "Cannot edit used coupons": "לא ניתן לערוך שוברים שנעשה בהם שימוש",
        "Error updating coupon. Please try again.": "שגיאה בעדכון השובר. אנא נסה שוב.",
        "Error deleting coupon. Please try again.": "שגיאה בהסרת השובר. אנא נסה שוב.",
        "Existing Coupons": "שוברים קיימים",
        "Code": "קוד",
        "Amount": "סכום",
        "Used": "שובר זה מומש",
        "Unused": "עדיין לא שומש",
        "Edit": "ערוך",
        "Delete": "מחק",
        "Please enter a valid coupon amount": "אנא הזן סכום שובר תקף",    
        "The coupon has been deleted.": "שובר זה נמחק בהצלחה.",
        


        // AuthPage
        "Welcome Back": "ברוך שובך",
        "Join Us": "הצטרף אלינו",
        "Login": "התחברות",
        "Sign Up": "הרשמה",
        "Loading...": "טוען...",
        "Enter your email": "הכנס את האימייל שלך",
        "Reset Password": "איפוס סיסמה",
        "Back to Login": "חזרה להתחברות",
        "Full Name": "שם מלא",
        "Email Address": "כתובת אימייל",
        "Password": "סיסמה",
        "Phone Number": "מספר טלפון",
        "Create Account": "צור חשבון",
        "Forgot Password?": "שכחת סיסמה?",
        "Don't have an account? ": "אין לך חשבון?",
        "Already have an account? ": "כבר יש לך חשבון?",
        "Invalid Phone Number": "מספר טלפון לא תקין",
        "Please enter a valid phone number.": "אנא הכנס מספר טלפון תקין.",
        "Authentication Failed": "האימות נכשל",
        "Please check your credentials and try again.": "אנא בדוק את הפרטים שלך ונסה שוב.",
        "Email Sent": "אימייל נשלח",
        "Password reset email sent successfully!": "אימייל לאיפוס סיסמה נשלח בהצלחה!",
        "Reset Failed": "איפוס נכשל",
        "Failed to send password reset email. Please check your email and try again.": "שליחת אימייל לאיפוס סיסמה נכשלה. אנא בדוק את האימייל שלך ונסה שוב.",
        "Please wait...": "אנא המתן...",
        "Processing your request": "מעבד את הבקשה שלך",
        "Welcome back!": "ברוך הבא!",
        "You have successfully logged in." : "התחברת בהצלחה.",
        "English Characters Only": "תווים באנגלית בלבד",
        "Please use only English characters for names.": "אנא השתמש רק בתווים באנגלית עבור שמות.",
        "Verify Password": "אמת סיסמה",
        "Saved Orders": "הזמנות שמורות",
        "Language": "שפות",
        "You have no saved orders.": "אין לך הזמנות שמורות.",
        "Update Profile": "עדכון פרופיל",
        "Display Name": "שם פרטי ומשפחה",
        "Recent logins, devices, etc.": "כניסות אחרונות, מכשירים וכו'.",
        "Subscription status and payment methods": "מצב מנוי ואמצעי תשלום",


        // SavedFlights
        "Do you really want to remove this flight from your saved flights?": "האם אתה באמת רוצה להסיר את הטיסה הזו מהרשימות השמורות שלך?",
        "Yes, remove it!": "כן, הסר אותה!",
        "Removed!": "הוסר!",
        "The flight has been removed from your saved flights.": "הטיסה הוסרה מהרשימות השמורות שלך.",
        "Error!": "שגיאה!",
        "There was an issue removing the flight. Please try again later.": "הייתה בעיה בהסרת הטיסה. אנא נסה שוב מאוחר יותר.",
        "Saved Flights": "טיסות שמורות",
        "You have no saved flights": "אין לך טיסות שמורות",
        "From": "מ",
        "To": "אל",
        "Date": "תאריך",
        "Time": "זמן",
        "Details": "פרטים",
        "Remove Flight": "הסר טיסה",
        "Remove Saved": "הסר משמורים",
        "Flight Details": "פרטי הטיסה",
        "Book this flight": "הזמן טיסה",


        // NotFound
        "Oops! It seems your flight has been delayed": "אופס! נראה שהטיסה שלך התעכבה",
        "The page you were looking for was not found, but don't worry - we have plenty of other destinations!": "העמוד שחיפשת לא נמצא, אבל אל דאגה - יש לנו המון יעדים אחרים!",
        "Return to Homepage": "חזרה לדף הבית",


        // ChatWithAgentComponent
        "PLEASUAIR Concierge": "PLEASUAIR שֵׁרוּת",
        "Experience luxury at every altitude": "חווה יוקרה בכל גובה",
        "Hi, Thank you for your message, Soon one of our agents will contact you": "שלום, תודה על ההודעה שלך, בקרוב אחד מהנציגים שלנו ייצור איתך קשר",
        "Type your message here...": "הקלד את ההודעה שלך כאן...",
        "Send": "שלח",


        // TermsOfUse
        "Terms of Use": "תנאי שימוש",
        "PLEASUAIR - Luxury in Every Journey": "PLEASUAIR - יוקרה בכל מסע",
        "1. Acceptance of Terms": "1. קבלת תנאים",
        "By accessing and using PLEASUAIR services, you agree to be bound by these Terms of Use.": "על ידי גישה ושימוש בשירותי PLEASUAIR, אתה מסכים להיות כפוף לתנאי השימוש הללו.",
        "2. Use of Services": "2. שימוש בשירותים",
        "Our services are designed for personal, non-commercial use. You agree not to misuse our services or help anyone else do so.": "השירותים שלנו מיועדים לשימוש אישי ולא מסחרי. אתה מסכים לא להשתמש בשירותינו לרעה ולא לסייע לאחרים לעשות כן.",
        "3. Privacy": "3. פרטיות",
        "Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect and use your information.": "הפרטיות שלך חשובה לנו. אנא עיין במדיניות הפרטיות שלנו כדי להבין כיצד אנו אוספים ומשתמשים במידע שלך.",
        "4. Bookings and Payments": "4. הזמנות ותשלומים",
        "All bookings are subject to availability. Payment is required at the time of booking unless otherwise specified.": "כל ההזמנות כפופות לזמינות. התשלום נדרש בזמן ההזמנה אלא אם כן צוין אחרת.",
        "5. Cancellations and Refunds": "5. ביטולים והחזרים",
        "Cancellation policies vary depending on the type of service booked. Please refer to the specific terms for each booking.": "מדיניות הביטולים משתנה בהתאם לסוג השירות שהוזמן. אנא עיין בתנאים הספציפיים לכל הזמנה.",
        "6. Liability": "6. אחריות",
        "PLEASUAIR is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.": "PLEASUAIR אינה אחראית לכל נזק ישיר, עקיף, מקרי או תוצאתי הנובע מהשימוש בשירותים שלנו.",
        "7. Changes to Terms": "7. שינויים בתנאים",
        "We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the modified terms.": "אנו שומרים לעצמנו את הזכות לשנות תנאים אלה בכל עת. השימוש המתמשך בשירותינו מהווה הסכמה לתנאים המתוקנים.",
        "Last updated: August 2024": "עדכון אחרון: אוגוסט 2024",
        "© 2024 PLEASUAIR. All rights reserved.": "© 2024 PLEASUAIR. כל הזכויות שמורות.",

        // FAQ
        "Frequently Asked Questions": "שאלות נפוצות",
        "Find answers to common inquiries about PLEASUAIR LINES": "מצא תשובות לשאלות נפוצות אודות PLEASUAIR LINES",
        "What sets PLEASUAIR LINES apart from other airlines?": "מה מבדיל את PLEASUAIR LINES משאר חברות התעופה?",
        "PLEASUAIR LINES redefines luxury air travel with our unparalleled service, state-of-the-art aircraft, gourmet dining, and exclusive amenities. Every aspect of your journey is curated to provide the utmost comfort and elegance.": "PLEASUAIR LINES מגדירה מחדש את הנסיעות האוויריות היוקרתיות עם השירות הייחודי שלנו, מטוסים מתקדמים, אוכל גורמה ואביזרים ייחודיים. כל היבט במסע שלך מתוכנן לספק את הנוחות והאלגנטיות הגבוהים ביותר.",
        "How can I book a flight with PLEASUAIR LINES?": "איך אני יכול להזמין טיסה עם PLEASUAIR LINES?",
        "You can book a flight through our website, mobile app, or by contacting our dedicated concierge service. Our team is available 24/7 to assist you with your booking and any special requests.": "אתה יכול להזמין טיסה דרך האתר שלנו, אפליקציית המובייל שלנו או על ידי יצירת קשר עם שירות הקונסיירז' המיוחד שלנו. הצוות שלנו זמין 24/7 כדי לסייע לך בהזמנה ובבקשות מיוחדות.",
        "What is included in a first-class ticket?": "מה כלול בכרטיס מחלקה ראשונה?",
        "Our first-class experience includes priority check-in and boarding, access to our exclusive lounges, fully-reclining seats with massage function, personalized service, gourmet meals prepared by world-renowned chefs, premium wine selection, luxury amenity kits, and complimentary chauffeur service at select destinations.": "החוויה במחלקה הראשונה שלנו כוללת צ'ק-אין ועלייה למטוס בעדיפות, גישה לטרקלינים יוקרתיים, מושבים ניתנים להטיה מלאה עם פונקציית עיסוי, שירות מותאם אישית, ארוחות גורמה שהוכנו על ידי שפים בעלי שם עולמי, מבחר יינות פרימיום, ערכות אביזרי יוקרה ושירות נהג חינם ביעדים נבחרים.",
        "Do you offer loyalty programs?": "האם אתם מציעים תוכניות נאמנות?",
        "Yes, our PLEASUAIR Elite program offers exclusive benefits including priority upgrades, additional baggage allowance, and access to our network of partner luxury hotels and resorts worldwide.": "כן, תוכנית PLEASUAIR Elite שלנו מציעה הטבות ייחודיות כולל שדרוגים בעדיפות, זכויות נוספות למטען וגישה לרשת בתי המלון והנופש היוקרתיים שלנו ברחבי העולם.",
        "What is your baggage policy?": "מהי מדיניות המטען שלכם?",
        "Our generous baggage policy allows first-class passengers two pieces of checked luggage up to 32kg each, plus two carry-on items. Business class passengers are allowed two pieces up to 28kg each. We also offer special arrangements for oversized items like sports equipment.": "מדיניות המטען האדיבה שלנו מאפשרת לנוסעי המחלקה הראשונה שני פריטי מטען מסומן עד 32 ק\"ג כל אחד, ועוד שני פריטים לנשיאה. נוסעי מחלקת העסקים מורשים לשני פריטים עד 28 ק\"ג כל אחד. אנו מציעים גם סידורים מיוחדים לפריטים בגודל גדול כמו ציוד ספורט.",

        // home page
        "PleasuAir Logo": "לוגו פלזו-אייר",
        "Discover the World with PleasuAir": "גלה את העולם עם PleasuAir",
        "Luxurious Flight Experience to Every Destination": "חווית טיסה יוקרתית לכל יעד",
         "Luxurious flight experiences to every destination": "חווית טיסה יוקרתית לכל יעד",
        "Where would you like to fly?": "לאן תרצה לטוס?",
        "Search Flights": "חפש טיסות",
        "Destinations": "יעדים",
        "Happy Customers": "לקוחות מרוצים",
        "Customer Rating": "דירוג לקוחות",
        "Top Destinations": "היעדים המובילים",
        "Articles and Guides": "מאמרים ומדריכים",
        "Weather Information": "מידע על מזג האוויר",
        "Destination Overview": "סקירת היעד",
        "Our Premium Services": "שירותי הפרימיום שלנו",
        "Luxury Flights": "טיסות יוקרה",
        "Experience unparalleled comfort with our spacious first-class cabins and gourmet dining.": "חוו נוחות בלתי מתפשרת עם תאי מחלקה ראשונה מרווחים ואוכל גורמה.",
        "Personal Concierge": "קונסיירז' אישי",
        "Enjoy personalized assistance from booking to arrival, ensuring a seamless travel experience.": "הנאה מעזרה אישית מהזמנה ועד הגעה, תוך הבטחת חווית נסיעה חלקה.",
        "Global Lounge Access": "גישה לטרקליני פרימיום ברחבי העולם",
        "Relax in exclusive airport lounges worldwide, featuring premium amenities and services.": "התרגעו בטרקלינים יוקרתיים בלעדיים ברחבי העולם, המציעים שירותים ואביזרים פרימיום.",
        "What Our Customers Say": "מה הלקוחות שלנו אומרים",
        "\"PleasuAir made my dream vacation a reality. The service was impeccable from start to finish!\"": "\"פלזו-אייר הגשימה את חופשת החלומות שלי. השירות היה ללא רבב מההתחלה ועד הסוף!\"",
        "Sarah J.": "שרה ג'",
        "\"I've never experienced such luxury in air travel. PleasuAir sets a new standard for flying!\"": "\"מעולם לא חוויתי כזה יוקרה בנסיעה אווירית. פלזו-אייר מגדירה מחדש את הסטנדרט לטיסות!\"",
        "Michael R.": "מיכאל ר'",
        "Ready for Your Next Adventure?": "מוכן להרפתקה הבאה שלך?",
        "Book your luxurious flight experience with PleasuAir today and embark on a journey of a lifetime.": "הזמינו את חוויית הטיסה היוקרתית שלכם עם פלזו-אייר היום וצאו למסע של פעם בחיים.",
        "Book Now": "הזמן עכשיו",
        "Phone": "טלפון",
        "Email": "דואר אלקטרוני",
        "Developed by:": "פותח על ידי:",
        "Yehuda Collins": "יהודה קולינס",
        "Meni Viner": "מני וינר",
        "Follow Us": "עקבו אחרינו",
        "Follow us on Instagram": "עקבו אחרינו באינסטגרם",
        "Follow us on Facebook": "עקבו אחרינו בפייסבוק",
        "All rights reserved.": "כל הזכויות שמורות.",
        "Paris": "פריז",
        "Dubai": "דובאי",


        // ImageGallery
        "Experience Luxury in the Air": "חווה יוקרה באוויר",
        "Luxury airplane interior": "פנים מטוס יוקרתי",
        "First-class seating": "מושבי מחלקה ראשונה",
        "Gourmet in-flight meal": "ארוחת גורמה בטיסה",
        "Scenic aerial view": "נוף אווירי מרהיב",
        "VIP lounge": "טרקלין VIP",
        "Sunset view from airplane window": "מבט על שקיעה מחלון המטוס",
        "Close image": "סגור תמונה",
        "Our Trusted Partners": "השותפים המהימנים שלנו",
        "SkyHigh Airlines": "סקייהיי איירליינס",
        "Premium airline partner for international flights": "שותף חברת תעופה פרימיום לטיסות בינלאומיות",
        "LuxeStay Hotels": "מלונות לוקססטיי",
        "Luxury accommodations for our esteemed passengers": "אירוח יוקרתי עבור הנוסעים המוערכים שלנו",
        "SwiftJet Services": "שירותי סוויפטג'ט",
        "Efficient ground handling and logistics support": "תמיכה לוגיסטית וטיפול יעיל בקרקע",
        "GourmetSky Catering": "קייטרינג גורמה סקיי",
        "Exquisite in-flight dining experiences": "חוויות סעודה יוצאות דופן בטיסה",
        "Our Global Offices": "המשרדים הגלובליים שלנו",
        "New York": "ניו יורק",
        "123 Sky Tower, Manhattan, NY 10001": "123 מגדל שמיים, מנהטן, ניו יורק 10001",
        "London": "לונדון",
        "456 Cloud Street, Westminster, London SW1A 1AA": "456 רחוב ענן, ווסטמינסטר, לונדון SW1A 1AA",
        "Tokyo": "טוקיו",
        "789 Air Avenue, Minato City, Tokyo 105-0001": "789 שדרת אוויר, מינאטו סיטי, טוקיו 105-0001",

        // FlightBooking
        "World Map": "מפת העולם",
        "Plan Your Global Adventure": "תכנן את ההרפתקה הגלובלית שלך",
        "Flying from": "טיסה מ",
        "Flying to": "טיסה ל",
        "Choose Origin": "בחר מקור",
        "Choose Destination": "בחר יעד",
        "Departing": "תאריך יציאה",
        "Adults (18+)": "מבוגרים (18+)",
        "Children (0-17)": "ילדים (0-17)",
        "Travel class": "מחלקת נסיעה",
        "All departments": "כל המחלקות",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "Find Flights": "מצא טיסות",
        "Searching the globe for your perfect flight...": "מחפשים את הטיסה המושלמת שלך ברחבי העולם...",
        "No flights available with enough seats for the selected passengers in the chosen class.": "אין טיסות זמינות עם מספיק מקומות לנוסעים הנבחרים במחלקה שנבחרה.",
      
        
        // FlightTickets
        "Book Your Flight": "הזמן את הטיסה שלך",
        "No Tickets Selected": "לא נבחרו כרטיסים",
        "Please select at least one ticket before booking.": "אנא בחר לפחות כרטיס אחד לפני ההזמנה.",
        "Anonymous": "אנונימי",
        "Departure": "המראה",
        "Arrival": "נחיתה",
        "Duration": "משך זמן",
        "Flight Number": "מספר טיסה",
        "Date": "תאריך",
        "Baggage Allowance": "משקל מותר למטען",
        "Standard": "סטנדרטי",
        "Select Your Class": "בחר את מחלקת הנסיעה שלך",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "Tickets": "כרטיסים",
        "Total Tickets": "סך כל הכרטיסים",
        "Total Price": "סה''כ המחיר",
        "Continue to Booking": "המשך להזמנה",
            "Continue to Selection": "המשך לבחירה",


        // PassengerDetails
        "Passenger": "נוסע",
        "First Name": "שם פרטי",
        "Enter first name": "הכנס שם פרטי",
        "Last Name": "שם משפחה",
        "Enter last name": "הכנס שם משפחה",
        "Date of Birth": "תאריך לידה",
        "Gender": "מין",
        "Select gender": "בחר מין",
        "Male": "זכר",
        "Female": "נקבה",
        "Passport Number": "מספר דרכון",
        "Enter passport number": "הכנס מספר דרכון",
        "Passport Expiry Date": "תאריך תפוגת הדרכון",
        "Nationality": "לאום",
        "Select nationality": "בחר לאום",
        "Israeli ID Number": "מספר זהות ישראלי",
        "Enter Israeli ID number": "הכנס מספר זהות ישראלי",
        "Proceed to Seat Selection": "המשך לבחירת מושבים",
        "Invalid Date": "תאריך לא חוקי",
        "Date of birth cannot be in the future.": "תאריך הלידה לא יכול להיות בעתיד.",
        "Expired Passport": "דרכון שפג תוקפו",
        "Passport has already expired.": "תוקף הדרכון פג.",
        "Passport Expiry Warning": "אזהרת תפוגת דרכון",
        "Your passport is expiring soon. Please renew it if it will expire within the next 3 months.": "תוקף הדרכון שלך עומד לפוג בקרוב. אנא חידשו אותו אם הוא יפוג תוך 3 חודשים הקרובים.",
        "Missing Details": "פרטים חסרים",
        "Please fill in all details for Passenger {{number}}.": "אנא מלא את כל הפרטים עבור נוסע {{number}}.",
        "Missing ID Number": "חסר מספר תעודת זהות",
        "Please provide an Israeli ID number for Passenger {{number}}.": "אנא ספק מספר תעודת זהות עבור נוסע {{number}}.",
        "Invalid ID Number": "מספר תעודת זהות לא חוקי",
        "The Israeli ID number for Passenger {{number}} is invalid.": "מספר תעודת הזהות של הנוסע מספר {{number}} אינו חוקי.",
        "Invalid Passport Number": "מספר דרכון לא חוקי",
        "The passport number for Passenger {{number}} is invalid.": "מספר הדרכון של הנוסע מספר {{number}} אינו חוקי.",
        "Israeli": "ישראלי",
        "Italy": "איטלקי",
        "Round Trip Flight": "טיסת הלוך ושוב",
        "Passenger {{number}}": "נוסע {{number}}",
        "seats in {{className}}": "מושבים במחלקת {{className}}",



        // Paymentpage
        "Secure Payment": "תשלום מאובטח",
        "Enter your credit card details to complete your purchase.": "הזן את פרטי כרטיס האשראי שלך כדי להשלים את הרכישה.",
        "Card Number": "מספר כרטיס",
        "Cardholder Name": "שם בעל הכרטיס",
        "John Doe": "ישראל ישראלי",
        "Expiry Date": "תאריך תפוגה",
        "CVV": "CVV",
        "Processing...": "מעבד...",
        "Complete Payment": "תשלום מאובטח",
        "Save Order for 60 Minutes": "שמור הזמנה ל-60 דקות",
        "Your payment is secured with 256-bit encryption": "התשלום שלך מאובטח עם הצפנת 256 ביט",
        "Payment successful!": "התשלום הצליח!",
        "Payment failed. Please try again.": "התשלום נכשל. אנא נסה שוב.",
        "Error processing payment. Please try again.": "שגיאה בעיבוד התשלום. אנא נסה שוב.",
        "Please log in to save your order.": "אנא התחבר כדי לשמור את ההזמנה שלך.",
        "Your order has been saved for 60 minutes.": "ההזמנה שלך נשמרה ל-60 דקות.",
        "Failed to save your order. Please try again.": "שמירת ההזמנה נכשלה. אנא נסה שוב.",
        "Remove Saved Flight" : "הסר את שמירת ההזמנה",
        "Your saved order has been removed." : "ההזמנה שלך הוסרה",
        "Booking Summary": "סיכום הזמנה",
        "Seats" : "מושבים",
        "Insurance Waiver": "ויתור על ביטוח",
        "By waiving travel insurance, you acknowledge that you understand the risks...": "על ידי ויתור על ביטוח נסיעות, אתה מאשר שאתה מבין את הסיכונים...",
        "I Waive Insurance": "אני מוותר על ביטוח",
        "Insurance Choice Required": "נדרשת בחירת ביטוח",
        "Please choose to include travel insurance or waive it.": "אנא בחר לכלול ביטוח נסיעות או לוותר עליו.",
        "Please enter a coupon code": "נא להזין קוד שובר",
        "Invalid or already used coupon" : "שובר לא תקף או לא מספיק",
        "Coupon value is insufficient for the total cost": "ערך השובר אינו מספיק עבור העלות הכוללת",
        "I accept the ": " אני מקבל את",
        "terms and conditions": "התנאים וההגבלות",
        "Include Travel Insurance": "כולל ביטוח נסיעות",
        "Coupon" : "שובר",
        "Credit Card": "כרטיס אשראי",
        "Waive Insurance": "וותרו על ביטוח",
        "Hide Booking Details": "הסתר פרטי הזמנה",
        "Show Booking Details": "הצג פרטי הזמנה",
        "ISRAEL ISRAELI": "ישראל ישראלי",
        "Coupon Code": "קוד שובר",
        "Enter coupon code": "הזן את קוד השובר",
        "Apply Coupon": "החל שובר",
        "Travel insurance covers trip cancellation, medical emergencies, lost luggage, and travel delays. Terms and conditions apply.": "ביטוח הנסיעות מכסה ביטול נסיעה, מקרי חירום רפואיים, אובדן כבודה ועיכובים בטיול. חלים תנאים והגבלות.",
        "Order saved for 60 minutes.": "ההזמנה נשמרה למשך 60 דקות",
        "Error saving order:": "שגיאה בשמירת הזמנה:",
        "Error saving order. Please try again.": "שגיאה בשמירת ההזמנה. אנא נסה שוב.",
        "Order removed from saved flights.": "ההזמנה הוסרה מטיסות שמורות.",
        "Please log in to save your order.": "נא התחבר כדי לשמור את ההזמנה שלך.",
        "or": "או",
        "i choose Waive Insurance": "אני בוחר לוותר על ביטוח",
        "I Accept": "אני מאשר",
        "Close": "סגור",
        "Price Breakdown": "פירוט מחירים",
        "Credit card payment involves a 4% commission.": "תשלום באמצעות כרטיס אשראי כרוך בעמלה של 4%.",
        "Credit Card Commission (4%)": "עמלת כרטיסי אשראי (4%)",
        "Your card details will be securely processed through Visa Checkout.": "פרטי הכרטיס שלך יעובדו באופן מאובטח באמצעות Visa Checkout.",
        "Enter your credit card details or coupon to complete your purchase.": "הזן את פרטי כרטיס האשראי או השובר שלך כדי להשלים את הרכישה.",


        //about-us
        // "PLEASUAIR LINES": "PLEASUAIR ליינס",
        "Redefining Luxury in the Skies Since 1995": "מגדירים מחדש את היוקרה בשמים מאז 1995",
        "Our Story": "הסיפור שלנו",
        "Founded in 1995 by aviation visionary John Doe, PLEASUAIR LINES...": "נוסדה בשנת 1995 על ידי החזון התעופתי ג'ון דו, PLEASUAIR ליינס...",
        "Message from Our Founder": "מסר מהמייסד שלנו",
        "When I founded PLEASUAIR LINES...": "כשהקמתי את PLEASUAIR ליינס...",
        "- John Doe, Founder and CEO": "- ג'ון דו, מייסד ומנכ\"ל",
        "The PLEASUAIR Experience": "חוויית PLEASUAIR",
        "Unmatched Comfort": "נוחות בלתי מתפשרת",
        "Our cabins are designed to be your home in the sky...": "הקבינות שלנו מעוצבות להיות הבית שלך בשמים...",
        "First Class Redefined": "מחלקה ראשונה מוגדרת מחדש",
        "Experience our signature fully-reclining seats...": "חווה את הכיסאות הנוטים לחלוטין...",
        "Gourmet Dining at 40,000 Feet": "ארוחות גורמה בגובה 40,000 רגל",
        "Savor exquisite meals prepared by world-renowned chefs...": "תיהנו מארוחות משובחות שהוכנו על ידי שפים בעלי שם עולמי...",
        "Our Core Values": "ערכי הליבה שלנו",
        "Luxury Beyond Compare": "יוקרה שאין לה תחליף",
        "Uncompromising Safety": "בטיחות בלתי מתפשרת",
        "Innovative Spirit": "רוח חדשנית",
        "Environmental Responsibility": "אחריות סביבתית",
        "Our People": "האנשים שלנו",
        "At the heart of PLEASUAIR LINES is our exceptional team...": "בלב PLEASUAIR ליינס עומד הצוות המדהים שלנו...",
        "Our Achievements": "ההישגים שלנו",
        "Skytrax 5-Star Airline Rating for 10 consecutive years...": "דירוג חברת תעופה 5 כוכבים של Skytrax במשך 10 שנים רצופות...",
        "Looking to the Future": "מבט לעתיד",
        "As we look ahead, PLEASUAIR LINES remains committed to pushing...": "כשאנחנו מביטים קדימה, PLEASUAIR LINES נשארים מחויבים לדחוף...",
        "Travel Insurance": "ביטוח נסיעות ",
        "Bus Service": "שירות אוטובוסים",


        // admin page
          "Flight Type": "סוג טיסה",
          "Manage Chat Rooms": "נהל חדרי צ'אט",
          "Admin Login": "כניסה כמנהל מערכת",  
          "Incorrect Password": "סיסמה שגויה",
          "Please try again.": "אנא נסה שוב.",
          "Enter Password": "הכנס סיסמה",
          "Manage Roles": "ניהול הרשאות",
          "Payment Links": "קישורי תשלום",


        
        // AdminChatRooms
        "Loading...": "טוען...",
        "Admin Chat Rooms": "חדרי צ'אט ניהוליים",
        "User": "משתמש",
        "Unknown User": "משתמש לא ידוע",
        "Chat with User": "שוחח עם המשתמש",
        "Type your message here...": "הקלד את ההודעה שלך כאן...",
        "Send": "שלח",
        "Error fetching users:": "שגיאה בקבלת משתמשים:",
        "Error fetching rooms:": "שגיאה בקבלת חדרים:",
        "Error selecting room:": "שגיאה בבחירת חדר:",
        "Error sending message:": "שגיאה בשליחת הודעה:",


        //FlightTickets
        "Round Trip Flight Details": "פרטי טיסה הלוך ושוב",
        "Flight Details": "פרטי טיסה",
        "Outbound Flight": "טיסת הלוך",
        "Return Flight": "טיסת חזור",
        "Continue to Ticket Selection": "המשך לבחירת כרטיס",
        "Book Your Round Trip Flight": "הזמן את טיסתך הלוך ושוב",
        "Book Your Flight": "הזמן את טיסתך",
        "Outbound": "יציאה",
        "Return": "חזרה",
        "Departure": "המראה",
        "Arrival": "נחיתה",
        "Duration": "משך זמן",
        "Flight Number": "מספר טיסה",
        "Date": "תאריך",
        "Baggage Allowance": "משקל מזוודות מותר",
        "Standard": "סטנדרט",
        "Select Your Class and Number of Passengers": "בחר מחלקה ומספר נוסעים",
        "The selected class and number of passengers will apply to both outbound and return flights.": "המחלקה ומספר הנוסעים שנבחרו יחולו הן על טיסת היציאה והן על טיסת החזרה.",
        "Economy Class": "מחלקת תיירים",
        "Business Class": "מחלקת עסקים",
        "First Class": "מחלקה ראשונה",
        "per person (round trip)": "לאדם (הלוך ושוב)",
        "per person": "לאדם",
        "Passengers": "נוסעים",
        "Total Passengers": "סך הכל נוסעים",
        "Total Price": "סך הכל מחיר",
        "Continue to Passenger Details": "המשך לפרטי הנוסעים",
        "No Tickets Selected": "לא נבחרו כרטיסים",
        "Please select at least one ticket before booking.": "אנא בחר לפחות כרטיס אחד לפני ההזמנה.",
        "Anonymous": "אנונימי",

        // TermsAndConditionsContent
          "Terms and Conditions": "תנאים והגבלות",
          "1. Acceptance of Terms": "1. קבלת התנאים",
          "By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.": "בכניסה ובשימוש באתר זה, אתה מסכים לקבל ולהיות מחויב לתנאים וההוראות של הסכם זה.",
          "2. User Responsibilities": "2. אחריות המשתמש",
          "You agree to use the website responsibly and ensure that your actions comply with all applicable laws and regulations. Failure to do so may result in suspension or termination of your access to the site.": "אתה מסכים להשתמש באתר באחריות ולוודא כי פעולותיך עומדות בכל החוקים והתקנות החלים. אי עמידה בכך עלולה לגרום להשעיה או לסיום הגישה שלך לאתר.",
          "3. Liability Limitations": "3. מגבלות האחריות",
          "The company is not liable for any damages arising from the use of this website, including but not limited to direct, indirect, incidental, or consequential damages.": "החברה אינה אחראית לכל נזק הנובע משימוש באתר זה, כולל אך לא מוגבל לנזקים ישירים, עקיפים, משניים או תוצאתיים.",
          "Terms Not Accepted": "התנאים לא אושרו",
          "Please accept the terms and conditions before proceeding.": "אנא אשר את התנאים וההגבלות לפני שתמשיך.",


        // PaymentLinks
          "Payment Links Manager": "ניהול קישורי תשלום",
          "Close": "סגור",
          "Add Link": "הוסף קישור",
          "Search links...": "חפש קישורים...",
          "Description": "תיאור",
          "Amount": "סכום",
          "Payment URL": "כתובת URL לתשלום",
          "Save": "שמור",
          "Cancel": "בטל",
          "Pay Now": "שלם עכשיו",
          "Edit link": "ערוך קישור",
          "Delete link": "מחק קישור",
          "Are you sure?": "האם אתה בטוח?",
          "You won't be able to revert this!": "לא תוכל לשחזר זאת!",
          "Yes, delete it!": "כן, מחק את זה!",
          "Cancel": "ביטול",
          "Deleted!": "נמחק!",
          "Your link has been deleted.": "הקישור שלך נמחק.",


        // 404
          "404": "404",
          "Page Not Found": "הדף לא נמצא",
          "Looks like this flight path is off the map!": "נראה שנתיב טיסה זה יצא מהמפה!",
          "Need help finding your destination?": "זקוק לעזרה במציאת היעד שלך?",
          "Return to Home": "חזרה לדף הבית",
          "Contact Support": "צור קשר עם התמיכה",


        // ....
        "hours": "שעות",
        "Coupon code copied!": "קוד קופון הועתק!",
        "For cash payment talk to one of our agents": "לתשלום במזומן דבר עם אחד הסוכנים שלנו",
        "I choose to waive insurance": "אני בוחר לוותר על הביטוח",
        "Search flights...": "חפש טיסות...",
        "Include Bus Service": "כלול שירות אוטובוס",
        "Saved for": "נשמר למשך",
        "minutes": "דקות",
        "Coupon applied successfully": "השובר הוחל בהצלחה",
        "Male": "זכר",
        "Already Used": "שומשו בעבר",
        "Used At": "הוחל בתאריך",
        "Used By": "על ידי המשתמש",
        "Search...": "חיפוש...",
        "Validating...": "מאמת...",
        "Coupon application failed: ": "יישום השובר נכשל: ",
        "Selected Seats": "מושבים שנבחרו",

    
      }
    }
    
  },
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false
//   }
// });

// export default i18n;

lng: "he", // Set Hebrew as the default language
fallbackLng: "he", // Set Hebrew as the fallback language
supportedLngs: ['en', 'he'], // List of supported languages
interpolation: {
  escapeValue: false
},
detection: {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
},
backend: {
  loadPath: '/locales/{{lng}}/{{ns}}.json' // Path to load language files
}
});

export default i18n;