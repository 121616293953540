import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';  

const TermsAndConditionsContent = () => {
    const { t } = useTranslation(); 

    return (
        <div style={{ height: '300px', overflowY: 'scroll', textAlign: 'center'}}>
            <h2>{t('Terms and Conditions')}</h2>
            <p>{t('1. Acceptance of Terms')}</p>
            <p>{t('By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.')}</p>
            <p>{t('2. User Responsibilities')}</p>
            <p>{t('You agree to use the website responsibly and ensure that your actions comply with all applicable laws and regulations. Failure to do so may result in suspension or termination of your access to the site.')}</p>
            <p>{t('3. Liability Limitations')}</p>
            <p>{t('The company is not liable for any damages arising from the use of this website, including but not limited to direct, indirect, incidental, or consequential damages.')}</p>
            {/* Add more terms and conditions content here */}
        </div>
    );
};

export const TermsAndConditionsHTML = ReactDOMServer.renderToString(<TermsAndConditionsContent />);

export default TermsAndConditionsContent;