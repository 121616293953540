
import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import { TbCopy, TbCopyCheckFilled } from 'react-icons/tb';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { RiCoupon3Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import '../../styles/CouponManagement.css';

const CouponManagement = () => {
  const { t } = useTranslation();
  const [couponAmount, setCouponAmount] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState('');
  const [editingCoupon, setEditingCoupon] = useState(null);
  const [copiedStates, setCopiedStates] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const couponsCollection = collection(db, 'coupons');
    const unsubscribe = onSnapshot(couponsCollection, (snapshot) => {
      const couponsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCoupons(couponsList);
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  const generateCouponCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const createCoupon = async (e) => {
    e.preventDefault();
    setError('');

    if (!couponAmount || isNaN(couponAmount) || couponAmount <= 0) {
      setError(t('Please enter a valid coupon amount'));
      return;
    }

    const couponCode = generateCouponCode();
    const couponData = {
      code: couponCode,
      amount: parseFloat(couponAmount),
      isUsed: false,
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(db, 'coupons'), couponData);
      setCouponAmount('');
    } catch (error) {
      setError(t('Error creating coupon. Please try again.'));
    }
  };

  const editCoupon = (coupon) => {
    if (coupon.isUsed) {
      setError(t('Cannot edit used coupons'));
      return;
    }
    setEditingCoupon(coupon);
    setCouponAmount(coupon.amount.toString());
  };

  const updateCoupon = async (e) => {
    e.preventDefault();
    setError('');

    if (!couponAmount || isNaN(couponAmount) || couponAmount <= 0) {
      setError(t('Please enter a valid coupon amount'));
      return;
    }

    try {
      const couponRef = doc(db, 'coupons', editingCoupon.id);
      await updateDoc(couponRef, {
        amount: parseFloat(couponAmount),
      });
      setEditingCoupon(null);
      setCouponAmount('');
    } catch (error) {
      setError(t('Error updating coupon. Please try again.'));
    }
  };

  const deleteCoupon = async (couponId) => {
    try {
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes, delete it!'),
        cancelButtonText: t('Cancel'),
      });

      if (result.isConfirmed) {
        await deleteDoc(doc(db, 'coupons', couponId));
        Swal.fire(t('Deleted!'), t('The coupon has been deleted.'), 'success');
      }
    } catch (error) {
      console.error('Error deleting coupon:', error);
      Swal.fire(
        t('Error'),
        t('Error deleting coupon. Please try again.'),
        'error'
      );
    }
  };

  const handleCopyCoupon = async (couponCode) => {
    try {
      await navigator.clipboard.writeText(couponCode);
      setCopiedStates((prev) => ({ ...prev, [couponCode]: true }));

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('Coupon code copied!'),
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });

      setTimeout(() => {
        setCopiedStates((prev) => ({ ...prev, [couponCode]: false }));
      }, 3000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const filteredCoupons = coupons.filter((coupon) => {
    const couponData = `${coupon.code} ${coupon.amount} ${coupon.usedByName || ''} ${coupon.bookingId || ''}`.toLowerCase();
    return couponData.includes(searchQuery.toLowerCase());
  });
  
    // Separate coupons into available and used
  const availableCoupons = filteredCoupons.filter((coupon) => !coupon.isUsed);
  const usedCoupons = filteredCoupons.filter((coupon) => coupon.isUsed);
  
  return (
    <div className="coupon-management">
      <div className="search-bar">
        <input
          type="text"
          placeholder={t('Search...')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <h2>{t('Coupon Management')}</h2>
      <form
        onSubmit={editingCoupon ? updateCoupon : createCoupon}
        className="coupon-form"
      >
        <div className="form-group">
          <label htmlFor="couponAmount">{t('Coupon Amount')}</label>
          <input
            type="number"
            id="couponAmount"
            value={couponAmount}
            onChange={(e) => setCouponAmount(e.target.value)}
            placeholder={t('Enter coupon amount')}
            required
          />
        </div>
        <button type="submit" className="create-coupon-btn">
          {editingCoupon ? t('Update Coupon') : t('Create Coupon')}
        </button>
        {editingCoupon && (
          <button
            type="button"
            className="cancel-edit-btn"
            onClick={() => setEditingCoupon(null)}
          >
            {t('Cancel Edit')}
          </button>
        )}
      </form>
      {error && <p className="error-message">{error}</p>}
      <div className="coupons-columns">
        <div className="coupons-list">
          <h3>{t('Available')}</h3>
          <ul>
            {availableCoupons.map((coupon) => (
              <li key={coupon.id} className="coupon-item">
                <div className="coupon-header">
                  <span className="icons">
                    <div className="icon not-used-coupon">
                      <RiCoupon3Fill />
                    </div>
                    <div
                      onClick={() => handleCopyCoupon(coupon.code)}
                      className="copy-icon icon"
                    >
                      {copiedStates[coupon.code] ? (
                        <TbCopyCheckFilled />
                      ) : (
                        <TbCopy />
                      )}
                    </div>
                  </span>
                </div>
                <div className="coupon-info">
                  <span className="coupon-code">
                    {t('Code')}: {coupon.code}
                  </span>
                  <span className="coupon-amount">
                    {t('Amount')}: ${coupon.amount}
                  </span>
                </div>
                <div className="coupon-actions">
                  <button
                    onClick={() => editCoupon(coupon)}
                    className="edit-btn"
                  >
                    <FaEdit /> {t('Edit')}
                  </button>
                  <button
                    onClick={() => deleteCoupon(coupon.id)}
                    className="delete-btn"
                  >
                    <FaTrash /> {t('Delete')}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="coupons-list">
          <h3>{t('Already Used')}</h3>
          <ul>
            {usedCoupons.map((coupon) => {
              const usedAtDate = coupon.usedAt
                ? coupon.usedAt.toDate()
                : null;
              return (
                <li key={coupon.id} className="coupon-item">
                  <div className="coupon-header">
                    <span className="icons">
                      <div className="icon used-coupon">
                        <RiCoupon3Fill />
                      </div>
                      <div
                        onClick={() => handleCopyCoupon(coupon.code)}
                        className="copy-icon icon"
                      >
                        {copiedStates[coupon.code] ? (
                          <TbCopyCheckFilled />
                        ) : (
                          <TbCopy />
                        )}
                      </div>
                    </span>
                  </div>
                  <div className="coupon-info">
                    <span>
                      {t('Code')}: {coupon.code}
                    </span>
                    <span>
                      {t('Amount')}: ${coupon.amount}
                    </span>
                  </div>
                  {coupon.isUsed && (
                    <div className="used-details">
                      <span className="coupon-date">
                        {t('Used At')}: {usedAtDate ? usedAtDate.toLocaleString() : t('N/A')}
                      </span>
                      <span>
                        {t('Used By')}: {coupon.usedByName || t('Unknown')}
                      </span>
                      <span>
                        {t('Booking ID')}: {coupon.bookingId || t('N/A')}
                      </span>
                    </div>
                  )}
                  <div className="coupon-actions">
                    <button
                      onClick={() => deleteCoupon(coupon.id)}
                      className="delete-btn"
                    >
                      <FaTrash /> {t('Delete')}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CouponManagement;



