import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import '../../styles/BookFlight.css';

const FlightBooking = () => {
  const { t } = useTranslation();
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [adultCount, setAdultCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(0);
  const [minDepartureDate, setMinDepartureDate] = useState('');
  const [minReturnDate, setMinReturnDate] = useState('');
  // const [flightClass, setFlightClass] = useState('Any');
  const [displayModal, setDisplayModal] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [flightClass, setFlightClass] = useState('All');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFlightData = async () => {
      const flightsCollection = collection(db, 'flights');
      const flightSnapshot = await getDocs(flightsCollection);
      const flightList = flightSnapshot.docs.map(doc => doc.data());

      const uniqueOrigins = [...new Set(flightList.map(flight => flight.routeSource))];
      const uniqueDestinations = [...new Set(flightList.map(flight => flight.routeDestination))];

      setOrigins(uniqueOrigins);
      setDestinations(uniqueDestinations);
    };

    fetchFlightData();
  }, []);

  useEffect(() => {
    // Set minimum departure date to today
    const today = new Date().toISOString().split('T')[0];
    setMinDepartureDate(today);
    setMinReturnDate(today);
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setDisplayModal(true);
  
    const searchParams = {
      origin,
      destination,
      departureDate,
      returnDate: isRoundTrip ? returnDate : null,
      adultCount,
      childrenCount,
      flightClass,
      isRoundTrip
    };
  
    const flightsCollection = collection(db, 'flights');
    const flightSnapshot = await getDocs(flightsCollection);
    let flightList = flightSnapshot.docs.map(doc => doc.data());
    
    navigate('/flights', { state: { searchParams, flightList } });
  };

  const handleDepartureDateChange = (e) => {
    const selectedDate = e.target.value;
    setDepartureDate(selectedDate);
    setMinReturnDate(selectedDate);
    
    // If return date is before new departure date, update it
    if (returnDate < selectedDate) {
      setReturnDate(selectedDate);
    }
  };

  const handlePassengerChange = (type, value) => {
    const newValue = parseInt(value);
    if (type === 'adult') {
      setAdultCount(newValue);
    } else {
      setChildrenCount(newValue);
    }

    const totalPassengers = (type === 'adult' ? newValue : adultCount) + (type === 'children' ? newValue : childrenCount);
    if (totalPassengers > 10) {
      Swal.fire({
        icon: 'warning',
        title: t('Too many passengers'),
        text: t('The total number of passengers cannot exceed 10.'),
      });
      if (type === 'adult') {
        setAdultCount(Math.max(1, 10 - childrenCount));
      } else {
        setChildrenCount(Math.max(0, 10 - adultCount));
      }
    }
  };



  return (
    <div className="flight-booking-page">
      <div className="flight-booking">
        <div className="world-map">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/A_large_blank_world_map_with_oceans_marked_in_blue.PNG/1599px-A_large_blank_world_map_with_oceans_marked_in_blue.PNG" alt={t("World Map")} className="map" />
          <div className="map-pin origin" style={{left: '15%', top: '30%'}}>
            <div className="pin"></div>
            <div className="pulse"></div>
          </div>
          <div className="map-pin destination" style={{left: '70%', top: '60%'}}>
            <div className="pin"></div>
            <div className="pulse"></div>
          </div>
          <div className="flight-paths">
            <svg width="100%" height="100%" viewBox="0 0 1000 500">
              <path d="M150 80 Q500 0 700 300" />
              <path d="M0 100 Q500 300 1200 100" />
              <path d="M0 400 Q500 100 1000 900" />
            </svg>
          </div>
          <div className="airplane">🛩️</div>
        </div>
        <div className="booking-panel">
          <h2 className="booking-title">{t('Plan Your Global Adventure')}</h2>
          <form onSubmit={handleFormSubmit} className="booking-form">
            <div className=" flight-type-toggle">
              <div className="radio-group">
                <input
                  type="radio"
                  id="roundTrip"
                  name="tripType"
                  value="roundTrip"
                  checked={isRoundTrip}
                  onChange={() => setIsRoundTrip(true)}
                />
                <label htmlFor="roundTrip" className={isRoundTrip ? 'active' : ''}>
                  {t('Round Trip')}
                </label>
                <input
                  type="radio"
                  id="oneWay"
                  name="tripType"
                  value="oneWay"
                  checked={!isRoundTrip}
                  onChange={() => setIsRoundTrip(false)}
                />
                <label htmlFor="oneWay" className={!isRoundTrip ? 'active' : ''}>
                  {t('One Way Trip')}
                </label>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="origin">{t('Flying from')}</label>
              <select
                id="origin"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                required
              >
                <option value="">{t('Choose Origin')}</option>
                {origins.map((origin, index) => (
                  <option key={index} value={origin}>{t(origin)}</option>
                ))}
              </select>
            </div>
            
            <div className="form-group">
              <label htmlFor="destination">{t('Flying to')}</label>
              <select
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                required
              >
                <option value="">{t('Choose Destination')}</option>
                {destinations.map((destination, index) => (
                  <option key={index} value={destination}>{t(destination)}</option>
                ))}
              </select>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="departureDate">{t('Departing')}</label>
                <input
                  id="departureDate"
                  type="date"
                  value={departureDate}
                  onChange={handleDepartureDateChange}
                  min={minReturnDate}
                  required
                />
              </div>
              {isRoundTrip && (
                <div className="form-group">
                  <label htmlFor="returnDate">{t('Returning')}</label>
                  <input
                    id="returnDate"
                    type="date"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                    min={minReturnDate}
                    required
                  />
                </div>
              )}
            </div>

            <div className="form-row passenger-count">
              <div className="form-group">
                <label htmlFor="adultCount">{t('Adults (18+)')}</label>
                <input
                  type="number"
                  id="adultCount"
                  value={adultCount}
                  onChange={(e) => handlePassengerChange('adult', e.target.value)}
                  min="1"
                  max="10"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="childrenCount">{t('Children (0-17)')}</label>
                <input
                  type="number"
                  id="childrenCount"
                  value={childrenCount}
                  onChange={(e) => handlePassengerChange('children', e.target.value)}
                  min="0"
                  max="9"
                  required
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="flightClass">{t('Travel class')}</label>
              <select
                id="flightClass"
                value={flightClass}
                onChange={(e) => setFlightClass(e.target.value)}
                required
              >
                <option value="All">{t('All departments')}</option>
                <option value="Economy Class">{t('Economy Class')}</option>
                <option value="Business Class">{t('Business Class')}</option>
                <option value="First Class">{t('First Class')}</option>
              </select>
            </div>
            
            <button type="submit" className="submit-btn">
              {t('Find Flights')}
            </button>
          </form>
        </div>
        
        {displayModal && (
          <div className="modal">
            <div className="modal-content">
              <div className="globe-loader"></div>
              <p>{t('Searching the globe for your perfect flight...')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlightBooking;