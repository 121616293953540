// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, getDocs, setDoc, updateDoc, deleteDoc, arrayUnion, serverTimestamp, collection, query, where, increment } from 'firebase/firestore';
// import { db, auth } from '../../config/firebase';
// import { useTranslation } from 'react-i18next';
// import ProgressTracker from './ProgressTracker';
// import { TermsAndConditionsHTML } from '../../Backend/TermsAndConditions';
// import Swal from 'sweetalert2';
// import { FaInfoCircle, FaCreditCard } from 'react-icons/fa';
// import '../../styles/payment.css';

// const Payment = () => {
//   const { t } = useTranslation();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { selectedFlight, selectedSeats, bookingData, passengers, isRoundTrip } = location.state || {};

//   const [cardNumber, setCardNumber] = useState('');
//   const [cardName, setCardName] = useState('');
//   const [expiryDate, setExpiryDate] = useState('');
//   const [cvv, setCvv] = useState('');
//   const [isFlipped, setIsFlipped] = useState(false);
//   const [paymentStatus, setPaymentStatus] = useState('pending');
//   const [isOrderSaved, setIsOrderSaved] = useState(false);
//   const [toast, setToast] = useState({ show: false, message: '', type: '' });
//   const [isBookingSummaryVisible, setIsBookingSummaryVisible] = useState(true);
//   const [includeInsurance, setIncludeInsurance] = useState(false);
//   const [includeBus, setIncludeBus] = useState(selectedFlight?.includeBus || false);
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [insuranceChoice, setInsuranceChoice] = useState(null);
//   const [isWaiverConfirmed, setIsWaiverConfirmed] = useState(false);
//   const [paymentMethod, setPaymentMethod] = useState('credit');
//   const [couponCode, setCouponCode] = useState('');
//   const [couponError, setCouponError] = useState('');
//   const [savedOrderExpiration, setSavedOrderExpiration] = useState(null);
//   const [timeLeft, setTimeLeft] = useState('');
//   const [creditCardCommission, setCreditCardCommission] = useState(0);
//   const [insuranceCost, setInsuranceCost] = useState(selectedFlight?.insurancePrice || 0);


//   useEffect(() => {
//     if (!selectedFlight || !bookingData) {
//       navigate('/');
//     }
//   }, [selectedFlight, bookingData, navigate]);

//   useEffect(() => {
//     let timer;
//     if (savedOrderExpiration) {
//       timer = setInterval(() => {
//         const now = new Date();
//         const timeLeft = savedOrderExpiration - now;
//         if (timeLeft <= 0) {
//           clearInterval(timer);
//           setIsOrderSaved(false);
//           setSavedOrderExpiration(null);
//           setTimeLeft('');
//         } else {
//           const minutes = Math.floor(timeLeft / 60000);
//           const seconds = Math.floor((timeLeft % 60000) / 1000);
//           setTimeLeft(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
//         }
//       }, 1000);
//     }
//     return () => clearInterval(timer);
//   }, [savedOrderExpiration]);

//   useEffect(() => {
//     if (paymentMethod === 'credit') {
//       const commission = bookingData?.totalCost * 0.04; // 4% commission
//       setCreditCardCommission(commission);
//       setIsFlipped(false);
//     } else if (paymentMethod === 'coupon') {
//       setIsFlipped(false);
//     } else {
//       setCreditCardCommission(0);
//     }
//   }, [paymentMethod, bookingData?.totalCost]);

//   const handleCardNumberChange = (e) => {
//     const value = e.target.value.replace(/\s/g, '');
//     const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
//     setCardNumber(formattedValue);
//   };
  
//   const handleExpiryDateChange = (e) => {
//     const value = e.target.value.replace(/\D/g, '');
//     if (value.length <= 2) {
//       setExpiryDate(value);
//     } else {
//       setExpiryDate(`${value.slice(0, 2)}/${value.slice(2, 4)}`);
//     }
//   };


//   const totalCostWithInsurance = bookingData?.totalCost + 
//   (includeInsurance ? insuranceCost : 0) + 
//   (includeBus ? selectedFlight?.busPrice : 0) + 
//   (paymentMethod === 'credit' ? creditCardCommission : 0);

//   const showToast = (message, type) => {
//     setToast({ show: true, message, type });
//     setTimeout(() => setToast({ show: false, message: '', type: '' }), 3000);
//   };

//   const handlePayment = async (e) => {
//     e.preventDefault();
  
//     if (!termsAccepted) {
//       Swal.fire({
//         icon: 'error',
//         title: t('Terms Not Accepted'),
//         text: t('Please accept the terms and conditions before proceeding.')
//       });
//       return;
//     }
  
//     if (insuranceChoice === null) {
//       Swal.fire({
//         icon: 'error',
//         title: t('Insurance Choice Required'),
//         text: t('Please choose to include travel insurance or waive it.')
//       });
//       return;
//     }
  
//     if (paymentMethod === 'coupon' && !couponCode) {
//       setCouponError(t('Please enter a coupon code'));
//       return;
//     }
  
//     try {
//       setPaymentStatus('processing');
      
//       if (!selectedFlight || !selectedFlight.id || !bookingData || !bookingData.tickets) {
//         throw new Error('Missing required booking data');
//       }
  
//       const flightRef = doc(db, 'flights', selectedFlight.id);
  
//       // Create a separate booking document
//       const bookingRef = doc(collection(db, 'bookings'));
//       const bookingId = bookingRef.id;
  
//       const updatedBookingData = {
//         id: bookingId,
//         flightId: selectedFlight.id,
//         selectedFlight: selectedFlight,
//         totalCost: totalCostWithInsurance,
//         includeInsurance,
//         insuranceCost: includeInsurance ? insuranceCost : 0,
//         includeBus,
//         busPrice: includeBus ? selectedFlight.busPrice : 0,
//         email: auth.currentUser?.email || '',
//         phoneNumber: '',
//         address: '',
//         selectedSeats: selectedSeats || [],
//         tickets: bookingData.tickets,
//         isRoundTrip: isRoundTrip,
//         paymentMethod: paymentMethod,
//         createdAt: serverTimestamp()
//       };
  
//       // Remove undefined values
//       Object.keys(updatedBookingData).forEach(key => 
//         updatedBookingData[key] === undefined && delete updatedBookingData[key]
//       );
  
//       // Process payment (coupon or credit card)
//       if (paymentMethod === 'coupon') {
//         const couponResult = await validateCoupon(couponCode);
//         if (!couponResult.isValid) {
//           setCouponError(couponResult.message);
//           setPaymentStatus('pending');
//           return;
//         }
  
//         const couponRef = doc(db, 'coupons', couponResult.coupon.id);
//         await updateDoc(couponRef, { 
//           isUsed: true,
//           usedAt: serverTimestamp(),
//           usedBy: auth.currentUser?.uid || 'anonymous',
//           bookingId: bookingId
//         });
  
//         updatedBookingData.couponUsed = couponResult.coupon.code;
//       } else {
//         // Simulate credit card payment processing
//         await new Promise(resolve => setTimeout(resolve, 2000));
//       }
  
//       // Update flight document
//       await updateDoc(flightRef, {
//         reservedSeats: arrayUnion(...(selectedSeats || [])),
//         [`${bookingData.tickets[0].className.toLowerCase()}ClassRemainingSeats`]: increment(-bookingData.tickets.length)
//       });

//       // Update flight document with the booking
//       await updateDoc(flightRef, {
//         bookings: arrayUnion(updatedBookingData),
//       });

  
//       // Save booking document
//       await setDoc(bookingRef, updatedBookingData);
  
//       // Save order to user's orders
//       if (auth.currentUser) {
//         const userOrderRef = doc(db, 'users', auth.currentUser.uid, 'orders', bookingId);
//         await setDoc(userOrderRef, updatedBookingData);
  
//         // Delete saved order if exists
//         const savedOrderRef = doc(db, 'users', auth.currentUser.uid, 'savedOrders', bookingId);
//         await deleteDoc(savedOrderRef);
//       }
  
//       setPaymentStatus('success');
  
//       navigate('/flight-receipt', {
//         state: {
//           bookingId,
//           selectedFlight,
//           selectedSeats,
//           bookingData: { ...bookingData, ...updatedBookingData },
//           passengers,
//           isRoundTrip,
//           paymentMethod,
//           couponUsed: paymentMethod === 'coupon' ? couponCode : null,
//           totalCostWithInsurance,
//           includeInsurance,
//           insuranceCost: includeInsurance ? insuranceCost : 0,
//           includeBus,
//           busPrice: includeBus ? selectedFlight.busPrice : 0
//         },
//         replace: true
//       });
//     } catch (error) {
//       console.error('Error processing payment:', error);
//       setPaymentStatus('failed');
//       showToast(t('Error processing payment. Please try again.'), 'error');
//     }
//   };

//   const validateCoupon = async (code) => {
//     const couponsRef = collection(db, 'coupons');
//     const q = query(couponsRef, where('code', '==', code), where('isUsed', '==', false));
//     const querySnapshot = await getDocs(q);

//     if (querySnapshot.empty) {
//       return { isValid: false, message: t('Invalid or already used coupon') };
//     }

//     const coupon = querySnapshot.docs[0].data();
//     if (coupon.amount < totalCostWithInsurance) {
//       return { isValid: false, message: t('Coupon value is insufficient for the total cost') };
//     }

//     return { isValid: true, coupon: { id: querySnapshot.docs[0].id, ...coupon } };
//   };

//   const handleCouponSubmit = async () => {
//     setCouponError('');
//     if (!couponCode) {
//       setCouponError(t('Please enter a coupon code'));
//       return;
//     }

//     const result = await validateCoupon(couponCode);
//     if (result.isValid) {
//       setPaymentMethod('coupon');
//       showToast(t('Coupon applied successfully'), 'success');
//     } else {
//       setCouponError(result.message);
//     }
//   };

//   const handleSaveOrder = async () => {
//     try {
//       if (!auth.currentUser) {
//         showToast(t('Please log in to save your order.'), 'error');
//         return;
//       }
  
//       const savedOrderRef = doc(db, 'users', auth.currentUser.uid, 'savedOrders', bookingData.bookingId);
  
//       if (isOrderSaved) {
//         await deleteDoc(savedOrderRef);
//         setIsOrderSaved(false);
//         setSavedOrderExpiration(null);
//         showToast(t('Order removed from saved flights.'), 'success');
//       } else {
//         const expirationTime = new Date(Date.now() + 60 * 60 * 1000); // 60 minutes from now
//         const savedOrderData = {
//           selectedFlight: {
//             id: selectedFlight.id,
//             name: selectedFlight.name,
//             routeSource: selectedFlight.routeSource,
//             routeDestination: selectedFlight.routeDestination,
//             departureDate: selectedFlight.departureDate,
//             departureTime: selectedFlight.departureTime,
//           },
//           bookingData: {
//             ...bookingData,
//             totalCost: totalCostWithInsurance,
//             includeInsurance,
//           },
//           selectedSeats,
//           passengers,
//           isRoundTrip,
//           savedAt: serverTimestamp(),
//           expiresAt: expirationTime
//         };
  
//         await setDoc(savedOrderRef, savedOrderData);
//         setIsOrderSaved(true);
//         setSavedOrderExpiration(expirationTime);
//         showToast(t('Order saved for 60 minutes.'), 'success');
//       }
//     } catch (error) {
//       console.error(t('Error saving order:'), error);
//       showToast(t('Error saving order. Please try again.'), 'error');
//     }
//   };

//   const openTermsModal = () => {
//     Swal.fire({
//       title: t('Terms and Conditions'),
//       html: TermsAndConditionsHTML,
//       showCancelButton: true,
//       confirmButtonText: t('I Accept'),
//       cancelButtonText: t('Close'),
//       reverseButtons: true,
//       didOpen: () => {
//         const content = Swal.getHtmlContainer();
//         content.scrollTop = 0;
//       },
//     }).then((result) => {
//       if (result.isConfirmed) {
//         setTermsAccepted(true);
//       } else {
//         setTermsAccepted(false);
//       }
//     });
//   };

//   const handleTermsCheckbox = (e) => {
//     if (e.target.checked) {
//       openTermsModal();
//     } else {
//       setTermsAccepted(false);
//     }
//   };

//   const openWaiverModal = () => {
//     Swal.fire({
//       title: t('Insurance Waiver'),
//       html: t('By waiving travel insurance, you acknowledge that you understand the risks...'),
//       showCancelButton: true,
//       confirmButtonText: t('I Waive Insurance'),
//       cancelButtonText: t('Cancel'),
//       reverseButtons: true,
//     }).then((result) => {
//       if (result.isConfirmed) {
//         setInsuranceChoice(false);
//         setIncludeInsurance(false);
//         setIsWaiverConfirmed(true);
//       }
//     });
//   };

//   const toggleBookingSummary = () => {
//     setIsBookingSummaryVisible(!isBookingSummaryVisible);
//   };

//   return (
//     <>
//       <ProgressTracker currentStep={4} />
//       <div className="payment-container">
//         <button onClick={toggleBookingSummary} className="toggle-summary-button">
//           {isBookingSummaryVisible ? t('Hide Booking Details') : t('Show Booking Details')}
//         </button>

//         {isBookingSummaryVisible && (
//           <div className="booking-summary">
//             <h2>{t('Booking Summary')}</h2>
//             <div className="summary-details">
//               <div className="summary-item">
//                 <span className="summary-label">{t('Flight')}:</span>
//                 <span className="summary-value">{selectedFlight.name}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('From')}:</span>
//                 <span className="summary-value">{selectedFlight.routeSource}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('To')}:</span>
//                 <span className="summary-value">{selectedFlight.routeDestination}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('Departure Date')}:</span>
//                 <span className="summary-value">{selectedFlight.departureDate}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('Departure Time')}:</span>
//                 <span className="summary-value">{selectedFlight.departureTime}</span>
//               </div>
//               {isRoundTrip && (
//                 <>
//                   <div className="summary-item">
//                     <span className="summary-label">{t('Return Date')}:</span>
//                     <span className="summary-value">{selectedFlight.returnDate}</span>
//                   </div>
//                   <div className="summary-item">
//                     <span className="summary-label">{t('Return Time')}:</span>
//                     <span className="summary-value">{selectedFlight.returnTime}</span>
//                   </div>
//                 </>
//               )}
//               <div className="summary-item">
//                 <span className="summary-label">{t('Trip Type')}:</span>
//                 <span className="summary-value">{isRoundTrip ? t('Round Trip') : t('One Way')}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('Seats')}:</span>
//                 <span className="summary-value">{selectedSeats.join(', ')}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('Passengers')}:</span>
//                 <span className="summary-value">{passengers.length}</span>
//               </div>
//               <div className="summary-item">
//                 <span className="summary-label">{t('Base Ticket Price')}:</span>
//                 <span className="summary-value">${bookingData.totalCost.toFixed(2)}</span>
//               </div>
//               {includeInsurance && (
//                 <div className="summary-item">
//                   <span className="summary-label">{t('Travel Insurance')}:</span>
//                   <span className="summary-value">${insuranceCost.toFixed(2)}</span>
//                 </div>
//               )}
//               {includeBus && (
//                 <div className="summary-item">
//                   <span className="summary-label">{t('Bus Service')}:</span>
//                   <span className="summary-value">${selectedFlight.busPrice.toFixed(2)}</span>
//                 </div>
//               )}

//               <div className="summary-item total-price">
//                 <div className="price-breakdown">
//                   <span className="summary-label">{t('Price Breakdown')}:</span>
//                   <ul className="breakdown-list">
//                     <li>
//                       <span className="flight-info">
//                         <span className="flight-type">{t('Outbound Flight')} </span>
//                         {/* <span className="flight-icon">✈️</span> */}
//                       </span>
//                       <span className="price">${(bookingData.isRoundTrip ? bookingData.totalCost / 2 : bookingData.totalCost).toFixed(2)}</span>
//                     </li>
//                     {bookingData.isRoundTrip && (
//                       <li>
//                         <span className="flight-info">
//                           <span className="flight-type">{t('Return Flight')} </span>
//                           {/* <span className="flight-icon">🛬</span> */}
//                         </span>
//                         <span className="price">${(bookingData.totalCost / 2).toFixed(2)}</span>
//                       </li>
//                     )}
//                     {includeInsurance && (
//                       <li>
//                         <span className="service-info">
//                           <span className="service-type">{t('Travel Insurance')}</span>
//                           <span className="service-icon">🛡️</span>
//                         </span>
//                         <span className="price">${insuranceCost.toFixed(2)}</span>
//                       </li>
//                     )}
//                     {includeBus && (
//                       <li>
//                         <span className="service-info">
//                           <span className="service-type">{t('Bus Service')}</span>
//                           <span className="service-icon">🚌</span>
//                         </span>
//                         <span className="price">${selectedFlight.busPrice.toFixed(2)}</span>
//                       </li>
//                     )}
//                     {paymentMethod === 'credit' && (
//                       <li>
//                         <span className="service-info">
//                           <span className="service-type">{t('Credit Card Commission (4%)')}</span>
//                           {/* <span className="service-icon">💳</span> */}
//                         </span>
//                         <span className="price">${creditCardCommission.toFixed(2)}</span>
//                       </li>
//                     )}
//                   </ul>
//                 </div>
//                 <div className="total-cost">
//                   <span className="summary-label">{t('Total Cost')}:</span>
//                   <span className="summary-value">${totalCostWithInsurance.toFixed(2)}</span>
//                 </div>
//               </div>

//             </div>
//           </div>
//         )}
//         {toast.show && (
//           <div className={`toast ${toast.type}`}>
//             {toast.message}
//           </div>
//         )}
//         <div className="payment-card">
//           <div className="card-info">
//             <h2>{t('Secure Payment')}</h2>
//             <p>{t('Enter your credit card details or coupon to complete your purchase.')}</p>
//             <div className={`payment-widget ${isFlipped ? 'flipped' : ''} ${paymentMethod === 'coupon' ? 'coupon-mode' : ''}`}>
//               <div className="credit-card-front">
//                 <div className="card-chip"></div>
//                 <div className="card-number">{cardNumber || '•••• •••• •••• ••••'}</div>
//                 <div className="card-name">{cardName || t('ISRAEL ISRAELI')}</div>
//                 <div className="card-expiry">{expiryDate || 'MM/YY'}</div>
//               </div>
//               <div className="credit-card-back">
//                 <div className="card-stripe"></div>
//                 <div className="card-cvv">{cvv || 'CVV'}</div>
//               </div>
//               <div className="coupon-display">
//                 <div className="coupon-code">{couponCode || t('Enter Coupon Code')}</div>
//               </div>
//             </div>
//           </div>
//           <div className="payment-form">
//             <form onSubmit={handlePayment} className="payment-form">
//               <h3 id='cash-announcement'>{t("For cash payment talk to one of our agents")}</h3>
//               <div className="radio-group payment-method-selection">
//                 <div className="radio-item">
//                   <input
//                     type="radio"
//                     id="credit"
//                     value="credit"
//                     checked={paymentMethod === 'credit'}
//                     onChange={() => setPaymentMethod('credit')}
//                   />
//                   <label htmlFor="credit">{t('Credit Card')}</label>
//                 </div>
//                 <div className="radio-item">
//                   <input
//                     type="radio"
//                     id="coupon"
//                     value="coupon"
//                     checked={paymentMethod === 'coupon'}
//                     onChange={() => setPaymentMethod('coupon')}
//                   />
//                   <label htmlFor="coupon">{t('Coupon')}</label>
//                 </div>
//               </div>

//               {paymentMethod === 'credit' ? (
//                 <>
//                   <p className="credit-card-commission-note">{t('Credit card payment involves a 4% commission.')}</p>
//                   <div className="form-grid card-details">
//                     <div className="form-group">
//                       <label htmlFor="cardNumber">{t('Card Number')}</label>
//                       <input
//                         id="cardNumber"
//                         type="text"
//                         placeholder="1234 5678 9012 3456"
//                         value={cardNumber}
//                         onChange={handleCardNumberChange}
//                         maxLength={19}
//                       />
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="cardName">{t('Cardholder Name')}</label>
//                       <input
//                         id="cardName"
//                         type="text"
//                         placeholder={t('John Doe')}
//                         value={cardName}
//                         onChange={(e) => setCardName(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-grid">
//                     <div className="form-group">
//                       <label htmlFor="expiryDate">{t('Expiry Date')}</label>
//                       <input
//                         id="expiryDate"
//                         type="text"
//                         placeholder="MM/YY"
//                         value={expiryDate}
//                         onChange={handleExpiryDateChange}
//                         maxLength={5}
//                       />
//                     </div>
//                     <div className="form-group incvv">
//                       <label htmlFor="cvv">{t('CVV')}</label>
//                       <input
//                         id="cvv"
//                         type="text"
//                         placeholder="123"
//                         value={cvv}
//                         onChange={(e) => setCvv(e.target.value)}
//                         maxLength={3}
//                         onFocus={() => setIsFlipped(true)}
//                         onBlur={() => setIsFlipped(false)}
//                       />
//                     </div>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="coupon-section">
//                     <div className="form-group">
//                       <label htmlFor="couponCode">{t('Coupon Code')}</label>
//                       <input
//                         id="couponCode"
//                         type="text"
//                         value={couponCode}
//                         onChange={(e) => setCouponCode(e.target.value)}
//                         placeholder={t('Enter coupon code')}
//                       />
//                     </div>
//                     {couponError && <p className="error-message">{couponError}</p>}
//                   </div>
//                   <button type="button" onClick={handleCouponSubmit} className="apply-coupon-btn">
//                     {t('Apply Coupon')}
//                   </button>
//                 </>
//               )}

//               {selectedFlight?.includeBus && (
//                 <div className="checkbox-group">
//                   <input
//                     id="bus"
//                     type="checkbox"
//                     checked={includeBus}
//                     onChange={(e) => setIncludeBus(e.target.checked)}
//                   />
//                   <label htmlFor="bus" className="bus-label">
//                     {t('Include Bus Service')} (+${selectedFlight.busPrice})
//                   </label>
//                   <div className="bus-info insurance-info">
//                     <FaInfoCircle className="info-icon" />
//                     <span className="info-text">
//                       {t('Bus service provides transportation to and from the airport.')}
//                     </span>
//                   </div>
//                 </div>
//               )}

//               <div className="checkbox-group">
//                 <input
//                   id="insurance"
//                   type="checkbox"
//                   checked={includeInsurance}
//                   onChange={(e) => {
//                     setIncludeInsurance(e.target.checked);
//                     setInsuranceChoice(e.target.checked);
//                   }}
//                 />
//                 <label htmlFor="insurance" className="insurance-label">
//                   {t('Include Travel Insurance')} (+${insuranceCost})
//                 </label>
//                 <div className="insurance-info">
//                   <FaInfoCircle className="info-icon" />
//                   <span className="info-text">
//                     {t('Travel insurance covers trip cancellation, medical emergencies, lost luggage, and travel delays. Terms and conditions apply.')}
//                   </span>
//                 </div>
                

//                 {!includeInsurance && (
//                   <>
//                     <p>{t('or')}</p>
//                     <button 
//                       type="button" 
//                       onClick={openWaiverModal} 
//                       className={`waiver-button ${isWaiverConfirmed ? 'confirmed' : ''}`}
//                     >
//                       {isWaiverConfirmed ? t('I choose to waive insurance') : t('Waive Insurance')}
//                     </button>
//                   </>
//                 )}
//               </div>

//               <div className="checkbox-group">
//                 <input
//                   id="terms"
//                   type="checkbox"
//                   checked={termsAccepted}
//                   onChange={handleTermsCheckbox}
//                 />
//                 <label htmlFor="terms" className="terms-label">
//                   {t('I accept the ')} <span>&nbsp;</span> <span onClick={openTermsModal} className="terms-link"> {t('terms and conditions')}</span>
//                 </label>
//               </div>

//               <div className="form-footer">
//                 <span className="total-cost">
//                   {t('Total Cost')}: &nbsp;<span className="cost-value">₪{totalCostWithInsurance.toFixed(2)}</span>
//                 </span>

//                 <button 
//                   type="button"
//                   onClick={handleSaveOrder} 
//                   className={`save-order-button ${isOrderSaved ? 'saved' : ''}`}
//                 >
//                   {/* 🕛 {isOrderSaved ? t('Remove Saved Flight') : t('Save Order for 60 Minutes')} */}
//                   🕛 {isOrderSaved 
//                     ? timeLeft 
//                       ? `${t('Saved for')} ${timeLeft} ${t('minutes')}`
//                       : t('Save Order for 60 Minutes')
//                     : t('Save Order for 60 Minutes')}
//                 </button>
//               </div>

//               <button type="submit" className="submit-button" disabled={paymentStatus === 'processing'}>
//                 <FaCreditCard className="button-icon" />
//                 {paymentStatus === 'processing' ? t('Processing...') : t('Complete Payment')}
//               </button>

//             </form>


//             <div className="security-note">
//               <FaCreditCard className="card-icon" />
//               {t('Your payment is secured with 256-bit encryption')}
//             </div>
//           </div>

//           {paymentStatus === 'success' && ( 
//             <div className="payment-status success">
//               <p>{t('Payment successful!')}</p>
//             </div>
//           )}
//           {paymentStatus === 'failed' && (
//             <div className="payment-status failed">
//               <p>{t('Payment failed. Please try again.')}</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Payment;


























































import React, { useState, useEffect, useMemo } from 'react';
import VisaPayment, { processVisaPayment } from '../../Backend/VisaPayment';
import { useLocation, useNavigate } from 'react-router-dom';
// import { arrayUnion, increment } from 'firebase/firestore';
import { doc, getDocs, updateDoc, setDoc, deleteDoc, serverTimestamp, runTransaction, collection, query, where, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import ProgressTracker from './ProgressTracker';
import { TermsAndConditionsHTML } from '../../Backend/TermsAndConditions';
import Swal from 'sweetalert2';
import { FaInfoCircle, FaCreditCard } from 'react-icons/fa';
import '../../styles/payment.css';


const Payment = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedFlight, selectedSeats, bookingData, passengers, isRoundTrip } = location.state || {};

  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [isFlipped, setIsFlipped] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('idle');
  const [isOrderSaved, setIsOrderSaved] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [isBookingSummaryVisible, setIsBookingSummaryVisible] = useState(true);
  const [includeInsurance, setIncludeInsurance] = useState(false);
  const [includeBus, setIncludeBus] = useState(selectedFlight?.includeBus || false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [insuranceChoice, setInsuranceChoice] = useState(null);
  const [isWaiverConfirmed, setIsWaiverConfirmed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [isCouponValidating, setIsCouponValidating] = useState(false);
  const [savedOrderExpiration, setSavedOrderExpiration] = useState(null);
  const [timeLeft, setTimeLeft] = useState('');
  const [creditCardCommission, setCreditCardCommission] = useState(0);
  const [insuranceCost, setInsuranceCost] = useState(selectedFlight?.insurancePrice || 0);
  
  const createCompleteBookingData = (baseData) => {
    const completeData = {
      ...baseData,
      flightDetails: {
        outbound: {
          flightNumber: selectedFlight.name || '',
          from: selectedFlight.routeSource || '',
          to: selectedFlight.routeDestination || '',
          date: selectedFlight.departureDate || '',
          time: selectedFlight.departureTime || '',
        },
        return: isRoundTrip ? {
          flightNumber: selectedFlight.returnName || selectedFlight.name || '',
          from: selectedFlight.routeDestination || '',
          to: selectedFlight.routeSource || '',
          date: selectedFlight.returnDate || '',
          time: selectedFlight.returnTime || '',
        } : null,
      },
      // Include tickets with seat information
      tickets: baseData.tickets.map((ticket, index) => ({
        ...ticket,
        seat: selectedSeats[index] || '',
        seatReturn: isRoundTrip ? selectedSeats[index + passengers.length] || '' : null,
        passengerDetails: {
          ...passengers[index],
        },
      })),
    };
  
    // Remove any properties with undefined values
    return JSON.parse(JSON.stringify(completeData));
  };
  
  const updateFlightReservedSeats = async () => {
    try {
      const flightRef = doc(db, 'flights', selectedFlight.id);
      await updateDoc(flightRef, {
        reservedSeats: arrayUnion(...selectedSeats)
      });
      console.log('Updated reserved seats:', selectedSeats);
    } catch (error) {
      console.error('Error updating reserved seats:', error);
      throw error; // Rethrow the error to be caught by the calling function
    }
  };

  
  const handleVisaPaymentSuccess = async (payment) => {
    try {
      const result = await processVisaPayment(payment);
      if (result.success) {

        const updatedBookingData = createCompleteBookingData({
          ...bookingData,
          tickets: bookingData.tickets, // Ensure tickets are included
          paymentMethod: 'credit',
          visaPaymentDetails: result,
          totalCost: totalAmount,
        });

        await updateBookingInDatabase(updatedBookingData);
        await updateFlightReservedSeats(); // Add this line

        navigate('/flight-receipt', {
          state: {
            bookingId: bookingData.bookingId,
            selectedFlight,
            selectedSeats,
            bookingData: updatedBookingData,
            passengers,
            isRoundTrip,
            paymentMethod: 'credit',
            totalAmount,
            includeInsurance,
            insuranceCost: includeInsurance ? insuranceCost : 0,
            includeBus,
            busPrice: includeBus ? selectedFlight.busPrice : 0
          },
          replace: true
        });

        setPaymentStatus('success');
      } else {
        throw new Error('Visa payment processing failed');
      }
    } catch (error) {
      handleVisaPaymentError(error);
    }
  };

  const [hasAttemptedPayment, setHasAttemptedPayment] = useState(false);
  const handleVisaPaymentError = (error) => {
    if (hasAttemptedPayment) {
      setPaymentStatus('failed');
      showToast(t('Payment failed: ') + (error.message || t('An unknown error occurred')), 'error');
    }
  };


  const totalAmount = useMemo(() => {
    let total = bookingData?.totalCost || 0;
    if (includeInsurance) total += insuranceCost;
    if (includeBus) total += selectedFlight?.busPrice || 0;
    if (paymentMethod === 'credit') total += creditCardCommission;
    return total;
  }, [bookingData?.totalCost, includeInsurance, insuranceCost, includeBus, selectedFlight?.busPrice, paymentMethod, creditCardCommission]);
  
  useEffect(() => {
    if (!selectedFlight || !bookingData) {
      navigate('/');
    }
  }, [selectedFlight, bookingData, navigate]);

  useEffect(() => {
    let timer;
    if (savedOrderExpiration) {
      timer = setInterval(() => {
        const now = new Date();
        const timeLeft = savedOrderExpiration - now;
        if (timeLeft <= 0) {
          clearInterval(timer);
          setIsOrderSaved(false);
          setSavedOrderExpiration(null);
          setTimeLeft('');
        } else {
          const minutes = Math.floor(timeLeft / 60000);
          const seconds = Math.floor((timeLeft % 60000) / 1000);
          setTimeLeft(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [savedOrderExpiration]);

  useEffect(() => {
    if (paymentMethod === 'credit') {
      const commission = bookingData?.totalCost * 0.04; // 4% commission
      setCreditCardCommission(commission);
      setIsFlipped(false);
    } else if (paymentMethod === 'coupon') {
      setIsFlipped(false);
    } else {
      setCreditCardCommission(0);
    }
  }, [paymentMethod, bookingData?.totalCost]);

  useEffect(() => {
    initializeSDK();
  }, []);


  const { openVisaCheckout, isSDKLoaded, initializeSDK } = VisaPayment({
    amount: totalAmount,
    onPaymentSuccess: handleVisaPaymentSuccess,
    onPaymentError: handleVisaPaymentError,
    autoInitialize: false // Add this option to prevent automatic initialization
  });

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
    setCardNumber(formattedValue);
  };
  
  const handleExpiryDateChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 2) {
      setExpiryDate(value);
    } else {
      setExpiryDate(`${value.slice(0, 2)}/${value.slice(2, 4)}`);
    }
  };


  const totalCostWithInsurance = bookingData?.totalCost + 
  (includeInsurance ? insuranceCost : 0) + 
  (includeBus ? selectedFlight?.busPrice : 0) + 
  (paymentMethod === 'credit' ? creditCardCommission : 0);

  const showToast = (message, type) => {
    setToast({ show: false, message: '', type: '' }); // Clear existing toast
    setTimeout(() => {
      setToast({ show: true, message, type });
      setTimeout(() => setToast({ show: false, message: '', type: '' }), 3000);
    }, 100); // Short delay before showing new toast
  };
  

  const handleCouponPayment = async (couponResult) => {
    return runTransaction(db, async (transaction) => {
      try {
        const bookingRef = doc(db, 'bookings', bookingData.bookingId);
        const bookingDoc = await transaction.get(bookingRef);
    
        let updatedBookingData;
    
        const baseData = {
          paymentMethod: 'coupon',
          couponUsed: couponResult.coupon.code,
          totalCost: totalAmount,
          status: 'pending'
        };
    
        if (!bookingDoc.exists()) {
          console.log('Booking document does not exist, creating it now');
          updatedBookingData = createCompleteBookingData({
            ...bookingData,
            ...baseData,
            createdAt: serverTimestamp(),
          });
          // Use setDoc instead of transaction.set to avoid undefined values
          // await setDoc(bookingRef, updatedBookingData);
          transaction.set(bookingRef, updatedBookingData);
        } else {
          console.log('Booking document exists, updating it');
          const existingData = bookingDoc.data();
          updatedBookingData = createCompleteBookingData({
            ...existingData,
            ...baseData,
            updatedAt: serverTimestamp(),
          });
          // Only update fields that are not undefined
          const updateData = Object.entries(updatedBookingData).reduce((acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          }, {});
          transaction.update(bookingRef, updateData);
        }
    
        // Update coupon as used
        const couponRef = doc(db, 'coupons', couponResult.coupon.id);
        transaction.update(couponRef, {
          isUsed: true,
          usedAt: serverTimestamp(),
          usedBy: auth.currentUser?.uid || 'anonymous',
          usedByName: auth.currentUser?.displayName || auth.currentUser?.email || 'Unknown',
          bookingId: bookingData.bookingId
        });

        // Update reserved seats
        const flightRef = doc(db, 'flights', selectedFlight.id);
        transaction.update(flightRef, {
          reservedSeats: arrayUnion(...selectedSeats)
        });
        
    
        // If the user is logged in, update their orders
        if (auth.currentUser) {
          const userOrderRef = doc(db, 'users', auth.currentUser.uid, 'orders', bookingData.bookingId);
          transaction.set(userOrderRef, updatedBookingData);
        }
    
        return updatedBookingData;
      } catch (error) {
        console.error('Error in handleCouponPayment transaction:', error);
        throw error;
      }
    });
  };
  
  const handlePayment = async (e) => {
    e.preventDefault();
    setHasAttemptedPayment(true);
    setPaymentStatus('processing');

    if (!termsAccepted) {
      setPaymentStatus('idle');
      Swal.fire({
        icon: 'error',
        title: t('Terms Not Accepted'),
        text: t('Please accept the terms and conditions before proceeding.')
      });
      return;
    }

    if (insuranceChoice === null) {
      setPaymentStatus('idle');
      Swal.fire({
        icon: 'error',
        title: t('Insurance Choice Required'),
        text: t('Please choose to include travel insurance or waive it.')
      });
      return;
    }

    if (paymentMethod === 'coupon' && !couponCode) {
      setCouponError(t('Please enter a coupon code'));
      setPaymentStatus('idle');
      return;
    }

    try {
      if (paymentMethod === 'credit') {
        if (isSDKLoaded) {
          console.log('Opening Visa Checkout...');
          openVisaCheckout();
          // The actual payment processing and navigation will be handled in handleVisaPaymentSuccess
        } else {
          console.error('Visa SDK not loaded');
          throw new Error('Visa Checkout is not ready. Please try again.');
        }
      } else if (paymentMethod === 'coupon') {
        console.log('Processing coupon payment...');
        const couponResult = await validateCoupon(couponCode);
        console.log('Coupon validation result:', couponResult);
        if (!couponResult.isValid) {
          setCouponError(couponResult.message);
          throw new Error(couponResult.message);
        }

        // Process coupon payment
        const updatedBookingData = await handleCouponPayment(couponResult);

        // Navigate to receipt page
        navigate('/flight-receipt', {
          state: {
            bookingId: bookingData.bookingId,
            selectedFlight,
            selectedSeats,
            bookingData: updatedBookingData,
            passengers,
            isRoundTrip,
            paymentMethod: 'coupon',
            couponUsed: couponCode,
            totalAmount,
            includeInsurance,
            insuranceCost: includeInsurance ? insuranceCost : 0,
            includeBus,
            busPrice: includeBus ? selectedFlight.busPrice : 0
          },
          replace: true
        });
      }

      setPaymentStatus('success');
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('failed');
      showToast(t('Error processing payment: ') + error.message, 'error');
    }
  };

  const updateBookingInDatabase = async (updatedBookingData) => {
    const bookingRef = doc(db, 'bookings', bookingData.bookingId);
    await updateDoc(bookingRef, updatedBookingData);

    if (auth.currentUser) {
      const userOrderRef = doc(db, 'users', auth.currentUser.uid, 'orders', bookingData.bookingId);
      await setDoc(userOrderRef, updatedBookingData);
    }
  };
  

  const validateCoupon = async (code) => {
    try {
      console.log('Validating coupon:', code);
      const couponsRef = collection(db, 'coupons');
      const q = query(couponsRef, where('code', '==', code), where('isUsed', '==', false));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('Coupon not found or already used');
        return { isValid: false, message: t('Invalid or already used coupon') };
      }

      const coupon = querySnapshot.docs[0].data();
      console.log('Coupon data:', coupon);

      if (coupon.amount < totalAmount) {
        console.log('Coupon value insufficient');
        return { isValid: false, message: t('Coupon value is insufficient for the total cost') };
      }

      console.log('Coupon is valid');
      return { isValid: true, coupon: { id: querySnapshot.docs[0].id, ...coupon } };
    } catch (error) {
      console.error('Error validating coupon:', error);
      throw new Error(t('Error validating coupon. Please try again.'));
    }
  };


  const handleCouponSubmit = async () => {
    setCouponError('');
    setIsCouponValidating(true);
    if (!couponCode) {
      setCouponError(t('Please enter a coupon code'));
      return;
    }

    try {
      const result = await validateCoupon(couponCode);
      if (result.isValid) {
        setPaymentMethod('coupon');
        showToast(t('Coupon applied successfully'), 'success');
      } else {
        setCouponError(result.message);
        showToast(t('Coupon application failed: ') + result.message, 'error');
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      setCouponError(t('An error occurred while applying the coupon'));
      showToast(t('Error applying coupon. Please try again.'), 'error');
    } finally {
      setIsCouponValidating(false);
    }
  };

  const handleSaveOrder = async () => {
    try {
      if (!auth.currentUser) {
        showToast(t('Please log in to save your order.'), 'error');
        return;
      }
  
      const savedOrderRef = doc(db, 'users', auth.currentUser.uid, 'savedOrders', bookingData.bookingId);
  
      if (isOrderSaved) {
        await deleteDoc(savedOrderRef);
        setIsOrderSaved(false);
        setSavedOrderExpiration(null);
        showToast(t('Order removed from saved flights.'), 'success');
      } else {
        const expirationTime = new Date(Date.now() + 60 * 60 * 1000); // 60 minutes from now
        const savedOrderData = {
          selectedFlight: {
            id: selectedFlight.id,
            name: selectedFlight.name,
            routeSource: selectedFlight.routeSource,
            routeDestination: selectedFlight.routeDestination,
            departureDate: selectedFlight.departureDate,
            departureTime: selectedFlight.departureTime,
          },
          bookingData: {
            ...bookingData,
            totalCost: totalAmount,
            includeInsurance,
          },
          selectedSeats,
          passengers,
          isRoundTrip,
          savedAt: serverTimestamp(),
          expiresAt: expirationTime
        };
  
        await setDoc(savedOrderRef, savedOrderData);
        setIsOrderSaved(true);
        setSavedOrderExpiration(expirationTime);
        showToast(t('Order saved for 60 minutes.'), 'success');
      }
    } catch (error) {
      console.error(t('Error saving order:'), error);
      showToast(t('Error saving order. Please try again.'), 'error');
    }
  };

  const openTermsModal = () => {
    Swal.fire({
      title: t('Terms and Conditions'),
      html: TermsAndConditionsHTML,
      showCancelButton: true,
      confirmButtonText: t('I Accept'),
      cancelButtonText: t('Close'),
      reverseButtons: true,
      didOpen: () => {
        const content = Swal.getHtmlContainer();
        content.scrollTop = 0;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setTermsAccepted(true);
      } else {
        setTermsAccepted(false);
      }
    });
  };

  const handleTermsCheckbox = (e) => {
    if (e.target.checked) {
      openTermsModal();
    } else {
      setTermsAccepted(false);
    }
  };

  const openWaiverModal = () => {
    Swal.fire({
      title: t('Insurance Waiver'),
      html: t('By waiving travel insurance, you acknowledge that you understand the risks...'),
      showCancelButton: true,
      confirmButtonText: t('I Waive Insurance'),
      cancelButtonText: t('Cancel'),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setInsuranceChoice(false);
        setIncludeInsurance(false);
        setIsWaiverConfirmed(true);
      }
    });
  };

  const toggleBookingSummary = () => {
    setIsBookingSummaryVisible(!isBookingSummaryVisible);
  };

  return (
    <>
      <ProgressTracker currentStep={4} />
      <div className="payment-container">
        <button onClick={toggleBookingSummary} className="toggle-summary-button">
          {isBookingSummaryVisible ? t('Hide Booking Details') : t('Show Booking Details')}
        </button>

        {isBookingSummaryVisible && (
          <div className="booking-summary">
            <h2>{t('Booking Summary')}</h2>
            <div className="summary-details">
              <div className="summary-item">
                <span className="summary-label">{t('Flight')}:</span>
                <span className="summary-value">{selectedFlight.name}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('From')}:</span>
                <span className="summary-value">{selectedFlight.routeSource}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('To')}:</span>
                <span className="summary-value">{selectedFlight.routeDestination}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('Departure Date')}:</span>
                <span className="summary-value">{selectedFlight.departureDate}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('Departure Time')}:</span>
                <span className="summary-value">{selectedFlight.departureTime}</span>
              </div>
              {isRoundTrip && (
                <>
                  <div className="summary-item">
                    <span className="summary-label">{t('Return Date')}:</span>
                    <span className="summary-value">{selectedFlight.returnDate}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">{t('Return Time')}:</span>
                    <span className="summary-value">{selectedFlight.returnTime}</span>
                  </div>
                </>
              )}
              <div className="summary-item">
                <span className="summary-label">{t('Trip Type')}:</span>
                <span className="summary-value">{isRoundTrip ? t('Round Trip') : t('One Way')}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('Seats')}:</span>
                <span className="summary-value">{selectedSeats.join(', ')}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('Passengers')}:</span>
                <span className="summary-value">{passengers.length}</span>
              </div>
              <div className="summary-item">
                <span className="summary-label">{t('Base Ticket Price')}:</span>
                <span className="summary-value">${bookingData.totalCost.toFixed(2)}</span>
              </div>
              {includeInsurance && (
                <div className="summary-item">
                  <span className="summary-label">{t('Travel Insurance')}:</span>
                  <span className="summary-value">${insuranceCost.toFixed(2)}</span>
                </div>
              )}
              {includeBus && (
                <div className="summary-item">
                  <span className="summary-label">{t('Bus Service')}: </span>
                  <span className="summary-value">${selectedFlight.busPrice.toFixed(2)}</span>
                </div>
              )}

              <div className="summary-item total-price">
                <div className="price-breakdown">
                  <span className="summary-label">{t('Price Breakdown')}:</span>
                  <ul className="breakdown-list">
                    <li>
                      <span className="flight-info">
                        <span className="flight-type">{t('Outbound Flight')} </span>
                        {/* <span className="flight-icon">✈️</span> */}
                      </span>
                      <span className="price">${(bookingData.isRoundTrip ? bookingData.totalCost / 2 : bookingData.totalCost).toFixed(2)}</span>
                    </li>
                    {bookingData.isRoundTrip && (
                      <li>
                        <span className="flight-info">
                          <span className="flight-type">{t('Return Flight')} </span>
                          {/* <span className="flight-icon">🛬</span> */}
                        </span>
                        <span className="price">${(bookingData.totalCost / 2).toFixed(2)}</span>
                      </li>
                    )}
                    {includeInsurance && (
                      <li>
                        <span className="service-info">
                          <span className="service-type">{t('Travel Insurance')}</span>
                          {/* <span className="service-icon">🛡️</span> */}
                        </span>
                        <span className="price">${insuranceCost.toFixed(2)}</span>
                      </li>
                    )}
                    {includeBus && (
                      <li>
                        <span className="service-info">
                          <span className="service-type">{t('Bus Service')}</span>
                          {/* <span className="service-icon">🚌</span> */}
                        </span>
                        <span className="price">${selectedFlight.busPrice.toFixed(2)}</span>
                      </li>
                    )}
                    {paymentMethod === 'credit' && (
                      <li>
                        <span className="service-info">
                          <span className="service-type">{t('Credit Card Commission (4%)')}</span>
                          {/* <span className="service-icon">💳</span> */}
                        </span>
                        <span className="price">${creditCardCommission.toFixed(2)}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="total-cost">
                  <span className="summary-label">{t('Total Cost')}:</span>
                  <span className="summary-value">${totalAmount.toFixed(2)}</span>
                </div>
              </div>

            </div>
          </div>
        )}
        {toast.show && (
          <div className={`toast ${toast.type}`}>
            {toast.message}
          </div>
        )}
        <div className="payment-card">
          <div className="card-info">
            <h2>{t('Secure Payment')}</h2>
            <p>{t('Enter your credit card details or coupon to complete your purchase.')}</p>
            <div className={`payment-widget ${isFlipped ? 'flipped' : ''} ${paymentMethod === 'coupon' ? 'coupon-mode' : ''}`}>
              <div className="credit-card-front">
                <div className="card-chip"></div>
                <div className="card-number">{cardNumber || '•••• •••• •••• ••••'}</div>
                <div className="card-name">{cardName || t('ISRAEL ISRAELI')}</div>
                <div className="card-expiry">{expiryDate || 'MM/YY'}</div>
              </div>
              <div className="credit-card-back">
                <div className="card-stripe"></div>
                <div className="card-cvv">{cvv || 'CVV'}</div>
              </div>
              <div className="coupon-display">
                <div className="coupon-code">{couponCode || t('Enter Coupon Code')}</div>
              </div>
            </div>
          </div>
          <div className="payment-form">
            <form onSubmit={handlePayment} className="payment-form">
              <h3 id='cash-announcement'>{t("For cash payment talk to one of our agents")}</h3>
              <div className="radio-group payment-method-selection">
                <div className="radio-item">
                  <input
                    type="radio"
                    id="credit"
                    value="credit"
                    checked={paymentMethod === 'credit'}
                    onChange={() => setPaymentMethod('credit')}
                  />
                  <label htmlFor="credit">{t('Credit Card')}</label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    id="coupon"
                    value="coupon"
                    checked={paymentMethod === 'coupon'}
                    onChange={() => setPaymentMethod('coupon')}
                  />
                  <label htmlFor="coupon">{t('Coupon')}</label>
                </div>
              </div>

              {paymentMethod === 'credit' && (
                <>
                  <p className="credit-card-commission-note">{t('Credit card payment involves a 4% commission.')}</p>
                  <div className="form-grid card-details">
                    <div className="form-group">
                      <label htmlFor="cardNumber">{t('Card Number')}</label>
                      <input
                        id="cardNumber"
                        type="text"
                        placeholder="1234 5678 9012 3456"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        maxLength={19}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="cardName">{t('Cardholder Name')}</label>
                      <input
                        id="cardName"
                        type="text"
                        placeholder={t('John Doe')}
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-grid">
                    <div className="form-group">
                      <label htmlFor="expiryDate">{t('Expiry Date')}</label>
                      <input
                        id="expiryDate"
                        type="text"
                        placeholder="MM/YY"
                        value={expiryDate}
                        onChange={handleExpiryDateChange}
                        maxLength={5}
                      />
                    </div>
                    <div className="form-group incvv">
                      <label htmlFor="cvv">{t('CVV')}</label>
                      <input
                        id="cvv"
                        type="text"
                        placeholder="123"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                        maxLength={3}
                        onFocus={() => setIsFlipped(true)}
                        onBlur={() => setIsFlipped(false)}
                      />
                    </div>
                  </div>
                  <p>{t('Your card details will be securely processed through Visa Checkout.')}</p>
                </>
              )}
              {paymentMethod === 'coupon' && (
                <>
                  <div className="coupon-section">
                    <div className="form-group">
                      <label htmlFor="couponCode">{t('Coupon Code')}</label>
                      <input
                        id="couponCode"
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder={t('Enter coupon code')}
                      />
                    </div>
                    {couponError && <p className="error-message">{couponError}</p>}
                  </div>
                  <button 
                    type="button" 
                    onClick={handleCouponSubmit} 
                    className="apply-coupon-btn"
                    disabled={isCouponValidating}
                  >
                    {isCouponValidating ? t('Validating...') : t('Apply Coupon')}
                  </button>
                </>
              )}

              {selectedFlight?.includeBus && (
                <div className="checkbox-group">
                  <input
                    id="bus"
                    type="checkbox"
                    checked={includeBus}
                    onChange={(e) => setIncludeBus(e.target.checked)}
                  />
                  <label htmlFor="bus" className="bus-label">
                    {t('Include Bus Service')} (+${selectedFlight.busPrice})
                  </label>
                  <div className="bus-info insurance-info">
                    <FaInfoCircle className="info-icon" />
                    <span className="info-text">
                      {t('Bus service provides transportation to and from the airport.')}
                    </span>
                  </div>
                </div>
              )}

              <div className="checkbox-group">
                <input
                  id="insurance"
                  type="checkbox"
                  checked={includeInsurance}
                  onChange={(e) => {
                    setIncludeInsurance(e.target.checked);
                    setInsuranceChoice(e.target.checked);
                  }}
                />
                <label htmlFor="insurance" className="insurance-label">
                  {t('Include Travel Insurance')} (+${insuranceCost})
                </label>
                <div className="insurance-info">
                  <FaInfoCircle className="info-icon" />
                  <span className="info-text">
                    {t('Travel insurance covers trip cancellation, medical emergencies, lost luggage, and travel delays. Terms and conditions apply.')}
                  </span>
                </div>
                

                {!includeInsurance && (
                  <>
                    <p>{t('or')}</p>
                    <button 
                      type="button" 
                      onClick={openWaiverModal} 
                      className={`waiver-button ${isWaiverConfirmed ? 'confirmed' : ''}`}
                    >
                      {isWaiverConfirmed ? t('I choose to waive insurance') : t('Waive Insurance')}
                    </button>
                  </>
                )}
              </div>

              <div className="checkbox-group">
                <input
                  id="terms"
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={handleTermsCheckbox}
                />
                <label htmlFor="terms" className="terms-label">
                  {t('I accept the ')} <span>&nbsp;</span> <span onClick={openTermsModal} className="terms-link"> {t('terms and conditions')}</span>
                </label>
              </div>

              <div className="form-footer">
                <span className="total-cost">
                  {t('Total Cost')}: &nbsp;<span className="cost-value">${totalAmount.toFixed(2)}</span>
                </span>

                <button 
                  type="button"
                  onClick={handleSaveOrder} 
                  className={`save-order-button ${isOrderSaved ? 'saved' : ''}`}
                >
                  {/* 🕛 {isOrderSaved ? t('Remove Saved Flight') : t('Save Order for 60 Minutes')} */}
                  🕛 {isOrderSaved 
                    ? timeLeft 
                      ? `${t('Saved for')} ${timeLeft} ${t('minutes')}`
                      : t('Save Order for 60 Minutes')
                    : t('Save Order for 60 Minutes')}
                </button>
              </div>

              <button type="submit" className="submit-button" disabled={paymentStatus === 'processing'}>
                <FaCreditCard className="button-icon" />
                {paymentStatus === 'processing' ? t('Processing...') : t('Complete Payment')}
              </button>

            </form>


            <div className="security-note">
              <FaCreditCard className="card-icon" />
              {t('Your payment is secured with 256-bit encryption')}
            </div>
          </div>

          {hasAttemptedPayment && paymentStatus === 'success' && (
            <div className="payment-status success">
              <p>{t('Payment successful!')}</p>
            </div>
          )}
          {hasAttemptedPayment && paymentStatus === 'failed' && (
            <div className="payment-status failed">
              <p>{t('Payment failed. Please try again.')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
