import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';
import '../../styles/FAQ.css';

const FAQ = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: t('What sets PLEASUAIR LINES apart from other airlines?'),
      answer: t('PLEASUAIR LINES redefines luxury air travel with our unparalleled service, state-of-the-art aircraft, gourmet dining, and exclusive amenities. Every aspect of your journey is curated to provide the utmost comfort and elegance.')
    },
    {
      question: t('How can I book a flight with PLEASUAIR LINES?'),
      answer: t('You can book a flight through our website, mobile app, or by contacting our dedicated concierge service. Our team is available 24/7 to assist you with your booking and any special requests.')
    },
    {
      question: t('What is included in a first-class ticket?'),
      answer: t('Our first-class experience includes priority check-in and boarding, access to our exclusive lounges, fully-reclining seats with massage function, personalized service, gourmet meals prepared by world-renowned chefs, premium wine selection, luxury amenity kits, and complimentary chauffeur service at select destinations.')
    },
    {
      question: t('Do you offer loyalty programs?'),
      answer: t('Yes, our PLEASUAIR Elite program offers exclusive benefits including priority upgrades, additional baggage allowance, and access to our network of partner luxury hotels and resorts worldwide.')
    },
    {
      question: t('What is your baggage policy?'),
      answer: t('Our generous baggage policy allows first-class passengers two pieces of checked luggage up to 32kg each, plus two carry-on items. Business class passengers are allowed two pieces up to 28kg each. We also offer special arrangements for oversized items like sports equipment.')
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-page">
      <div className="faq-hero">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {t('Frequently Asked Questions')}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {t('Find answers to common inquiries about PLEASUAIR LINES')}
        </motion.p>
      </div>
      <div className="faq-content">
        {faqData.map((item, index) => (
          <motion.div
            className="faq-item"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div 
              className={`faq-question ${activeIndex === index ? 'active' : ''}`} 
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <FaChevronDown className={`faq-icon ${activeIndex === index ? 'active' : ''}`} />
            </div>
            <AnimatePresence>
              {activeIndex === index && (
                <motion.div
                  className="faq-answer"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {item.answer}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;