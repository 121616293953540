
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import ProgressTracker from './ProgressTracker';
import Swal from 'sweetalert2';
import { FaPlaneDeparture, FaPlaneArrival, FaClock, FaSuitcase, FaCalendarAlt, FaPlane } from 'react-icons/fa';
import '../../styles/FlightTickets.css';

const FlightTickets = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [adultCount, setAdultCount] = useState(location.state?.searchParams?.adultCount || 1);
  const [childrenCount, setChildrenCount] = useState(location.state?.searchParams?.childrenCount || 0);
  const [selectedFlight, setSelectedFlight] = useState(location.state?.selectedFlight || {});
  const [isRoundTrip, setIsRoundTrip] = useState(location.state?.isRoundTrip || false);
  const [searchParams, setSearchParams] = useState(location.state?.searchParams || {});
  const [selectedClass, setSelectedClass] = useState(location.state?.searchParams?.flightClass || 'All');

  const [classSelection, setClassSelection] = useState({
    economy: { tickets: adultCount + childrenCount },
    business: { tickets: 0 },
    first: { tickets: 0 },
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTickets, setTotalTickets] = useState(adultCount + childrenCount);

  useEffect(() => {
    if (!selectedFlight || Object.keys(selectedFlight).length === 0) {
      navigate('/book-flight', { replace: true });
    }

    // Initialize class selection based on the selected class from FlightBooking
    if (selectedClass !== 'All') {
      const classType = selectedClass.toLowerCase().replace(' class', '');
      setClassSelection({
        economy: { tickets: classType === 'economy' ? totalTickets : 0 },
        business: { tickets: classType === 'business' ? totalTickets : 0 },
        first: { tickets: classType === 'first' ? totalTickets : 0 },
      });
    }
  }, [selectedFlight, navigate, selectedClass, totalTickets]);

  useEffect(() => {
    calculateTotal();
  }, [classSelection, selectedFlight, isRoundTrip, adultCount, childrenCount]);

  const handlePassengerChange = (type, value) => {
    let newValue = parseInt(value);
    const otherType = type === 'adult' ? 'children' : 'adult';
    const otherCount = type === 'adult' ? childrenCount : adultCount;
  
    // בדיקה אם הערך הוא NaN או שלילי
    if (isNaN(newValue) || newValue < 0) {
      newValue = type === 'adult' ? 1 : 0; // מבוגרים תמיד לפחות 1, ילדים יכול להיות 0
    }
  
    // הגבלת המספר המקסימלי של נוסעים
    if (newValue + otherCount > 10) {
    Swal.fire({
      icon: 'warning',
      title: t('Too many passengers'),
      text: t('The total number of passengers cannot exceed 10.'),
    });

      newValue = 10 - otherCount;
    }
  
    // וידוא שתמיד יש לפחות מבוגר אחד
    // if (type === 'adult' && newValue === 0) {
    //   newValue = 1;
    // }
  
    if (type === 'adult') {
      setAdultCount(newValue);
    } else {
      setChildrenCount(newValue);
    }
  
    const totalPassengers = newValue + otherCount;
    setTotalTickets(totalPassengers);
  
    // עדכון בחירת המחלקות
    setClassSelection(prev => {
      const newSelection = { ...prev };
      let remainingPassengers = totalPassengers;
      for (const classType in newSelection) {
        if (remainingPassengers > 0) {
          const availableSeats = selectedFlight[`${classType}ClassRemainingSeats`];
          const ticketsForClass = Math.min(remainingPassengers, availableSeats);
          newSelection[classType].tickets = ticketsForClass;
          remainingPassengers -= ticketsForClass;
        } else {
          newSelection[classType].tickets = 0;
        }
      }
      return newSelection;
    });
  };

  const calculateTotal = () => {
    let totalPrice = 0;
    Object.entries(classSelection).forEach(([classType, { tickets }]) => {
      const adultPrice = Math.min(tickets, adultCount) * selectedFlight[`${classType}ClassTicketCost`];
      const childPrice = Math.max(0, tickets - adultCount) * (selectedFlight[`${classType}ClassTicketCost`] * 0.8);
      totalPrice += adultPrice + childPrice;
    });

    if (isRoundTrip) {
      totalPrice *= 2;
    }

    setTotalPrice(totalPrice);
  };

  const bookFlight = async () => {
    if (totalTickets === 0) {
      Swal.fire({
        icon: 'error',
        title: t('No Tickets Selected'),
        text: t('Please select at least one ticket before booking.'),
      });
      return;
    }

    const currentUser = auth.currentUser;
    let username = t('Anonymous');
    
    if (currentUser) {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        username = userDoc.data().name || currentUser.displayName || t('Anonymous');
      }
    }

    const tickets = Object.entries(classSelection).flatMap(([classType, { tickets }]) => 
      tickets > 0 
        ? Array(tickets).fill().map((_, i) => ({ 
            className: t(classType.charAt(0).toUpperCase() + classType.slice(1)), 
            ticketNo: `${classType.toUpperCase()}-${i + 1}`,
            username: username,
            price: selectedFlight[`${classType}ClassTicketCost`],
          }))
        : []
    );

    const bookingData = {
      flightId: selectedFlight.id,
      bookingId: 'BOOK-' + Math.random().toString(36).substr(2, 9).toUpperCase(),
      totalCost: totalPrice,
      economyClassTicketCost: selectedFlight.economyClassTicketCost,
      businessClassTicketCost: selectedFlight.businessClassTicketCost,
      firstClassTicketCost: selectedFlight.firstClassTicketCost,
      tickets,
      username: username,
      isRoundTrip,
    };

    navigate('/passenger-details', {
      state: {
        selectedFlight,
        totalTickets,
        tickets,
        bookingData,
        isRoundTrip,
        searchParams,
      },
      replace: true,
    });
  };

  return (
    <>
      <ProgressTracker currentStep={1} />
      <div className="flight-tickets-page">
        <div className="main-content">
          <header className="flight-header">
            <h1>{isRoundTrip ? t('Book Your Round Trip Flight') : t('Book Your Flight')}</h1>
            <div className="flight-route">
              <span>{selectedFlight.routeSource}</span>
              <FaPlaneDeparture className="f-t-plane-icon" />
              <span>{selectedFlight.routeDestination}</span>
              {isRoundTrip && (
                <>
                  <FaPlaneArrival className="f-t-plane-icon" />
                  <span>{selectedFlight.routeSource}</span>
                </>
              )}
            </div>
            <p className="flight-date">
              {t('Outbound')}: {selectedFlight.departureDate}
              {isRoundTrip && ` | ${t('Return')}: ${selectedFlight.returnDate}`}
            </p>
          </header>

          {/* <section className="flight-summary">
            <div className="flight-info">
              <div className="info-column">
                <div className="info-item">
                  <FaPlaneDeparture className="f-t-plane-icon" />
                  <div>
                    <span className="info-label">{t('Departure')}</span>
                    <span>{selectedFlight.routeSource}</span>
                    <span>{selectedFlight.departureTime}</span>
                  </div>
                </div>
                <div className="info-item">
                  <FaPlaneArrival className="f-t-plane-icon" />
                  <div>
                    <span className="info-label">{t('Arrival')}</span>
                    <span>{selectedFlight.routeDestination}</span>
                    <span>{selectedFlight.arrivalTime}</span>
                  </div>
                </div>
              </div>
              <div className="info-column">
                <div className="info-item">
                  <FaClock />
                  <div>
                    <span className="info-label">{t('Duration')}</span>
                    <span>{selectedFlight.flightDuration} {t('hours')}</span>
                  </div>
                </div>
                <div className="info-item">
                  <FaPlane className="f-t-plane-icon" />
                  <div>
                    <span className="info-label">{t('Flight Number')}</span>
                    <span>{selectedFlight.name}</span>
                  </div>
                </div>
              </div>
              <div className="info-column">
                <div className="info-item">
                  <FaCalendarAlt />
                  <div>
                    <span className="info-label">{t('Date')}</span>
                    <span>{selectedFlight.departureDate}</span>
                  </div>
                </div>
                <div className="info-item">
                  <FaSuitcase />
                  <div>
                    <span className="info-label">{t('Baggage Allowance')}</span>
                    <span>{selectedFlight.baggageAllowance || t('Standard')}</span>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="flight-summary">
            <h2>{t('Flight Details')}</h2>
            <div className="flight-info outbound">
              <h3>{t('Outbound Flight')}</h3>
              <div className="info-columns">
                <div className="info-column">
                  <div className="info-item">
                    <FaPlaneDeparture className="f-t-plane-icon" />
                    <div>
                      <span className="info-label">{t('Departure')}</span>
                      <span>{selectedFlight.routeSource}</span>
                      <span>{selectedFlight.departureTime}</span>
                    </div>
                  </div>
                  <div className="info-item">
                    <FaPlaneArrival className="f-t-plane-icon" />
                    <div>
                      <span className="info-label">{t('Arrival')}</span>
                      <span>{selectedFlight.routeDestination}</span>
                      <span>{selectedFlight.arrivalTime}</span>
                    </div>
                  </div>
                </div>
                <div className="info-column">
                  <div className="info-item">
                    <FaClock />
                    <div>
                      <span className="info-label">{t('Duration')}</span>
                      <span>{selectedFlight.flightDuration} {t('hours')}</span>
                    </div>
                  </div>
                  <div className="info-item">
                    <FaPlane className="f-t-plane-icon" />
                    <div>
                      <span className="info-label">{t('Flight Number')}</span>
                      <span>{selectedFlight.name}</span>
                    </div>
                  </div>
                </div>
                <div className="info-column">
                  <div className="info-item">
                    <FaCalendarAlt />
                    <div>
                      <span className="info-label">{t('Date')}</span>
                      <span>{selectedFlight.departureDate}</span>
                    </div>
                  </div>
                  <div className="info-item">
                    <FaSuitcase />
                    <div>
                      <span className="info-label">{t('Baggage Allowance')}</span>
                      <span>{selectedFlight.baggageAllowance || t('Standard')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {isRoundTrip && (
              <div className="flight-info return">
                <h3>{t('Return Flight')}</h3>
                <div className="info-columns">
                  <div className="info-column">
                    <div className="info-item">
                      <FaPlaneDeparture className="f-t-plane-icon" />
                      <div>
                        <span className="info-label">{t('Departure')}</span>
                        <span>{selectedFlight.routeDestination}</span>
                        <span>{selectedFlight.returnDepartureTime}</span>
                      </div>
                    </div>
                    <div className="info-item">
                      <FaPlaneArrival className="f-t-plane-icon" />
                      <div>
                        <span className="info-label">{t('Arrival')}</span>
                        <span>{selectedFlight.routeSource}</span>
                        <span>{selectedFlight.returnArrivalTime}</span>
                      </div>
                    </div>
                  </div>
                  <div className="info-column">
                    <div className="info-item">
                      <FaClock />
                      <div>
                        <span className="info-label">{t('Duration')}</span>
                        <span>{selectedFlight.returnFlightDuration} {t('hours')}</span>
                      </div>
                    </div>
                    <div className="info-item">
                      <FaPlane className="f-t-plane-icon" />
                      <div>
                        <span className="info-label">{t('Flight Number')}</span>
                        <span>{selectedFlight.returnFlightNumber}</span>
                      </div>
                    </div>
                  </div>
                  <div className="info-column">
                    <div className="info-item">
                      <FaCalendarAlt />
                      <div>
                        <span className="info-label">{t('Date')}</span>
                        <span>{selectedFlight.returnDate}</span>
                      </div>
                    </div>
                    <div className="info-item">
                      <FaSuitcase />
                      <div>
                        <span className="info-label">{t('Baggage Allowance')}</span>
                        <span>{selectedFlight.returnBaggageAllowance || t('Standard')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>

          <section className="passenger-selection">
            <h2>{t('Select Your Class and Number of Passengers')}</h2>
            <p>{isRoundTrip ? t('The selected class and number of passengers will apply to both outbound and return flights.') : ''}</p>
            <div className="class-options">
              {['economy', 'business', 'first'].map(classType => (
                selectedFlight[`${classType}ClassTotalSeats`] > 0 && (
                  <div 
                    key={classType}
                    className={`class-card ${classSelection[classType].tickets > 0 ? 'selected' : ''} ${selectedClass.toLowerCase().includes(classType) ? 'initially-selected' : ''}`}
                    onClick={() => {
                      const newClassSelection = {
                        economy: { tickets: 0 },
                        business: { tickets: 0 },
                        first: { tickets: 0 },
                      };
                      newClassSelection[classType].tickets = totalTickets;
                      setClassSelection(newClassSelection);
                      setSelectedClass(`${classType.charAt(0).toUpperCase() + classType.slice(1)} Class`);
                    }}
                  >
                    <h3>{t(`${classType.charAt(0).toUpperCase() + classType.slice(1)} Class`)}</h3>
                    <p className="price">${totalPrice.toFixed(2)} {isRoundTrip ? t('per person (round trip)') : t('per person')}</p>
                    <div className="passenger-count">
                      <p>{t('Adults')}: {adultCount}</p>
                      {/* <p>{t('Children')}: {childrenCount}</p> */}
                    </div>
                  </div>
                )
              ))}
            </div>
          </section>

          <section className="passenger-selection">
            <h2>{t('Number of Passengers')}</h2>
            <div className="passenger-inputs">
              <div className="form-group">
                <label htmlFor="adultCount">{t('Adults (18+)')}</label>
                <input
                  type="number"
                  id="adultCount"
                  value={adultCount}
                  onChange={(e) => handlePassengerChange('adult', e.target.value)}
                  min="1"
                  max="10"
                  required
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="childrenCount">{t('Children (0-17)')}</label>
                <input
                  type="number"
                  id="childrenCount"
                  value={childrenCount}
                  onChange={(e) => handlePassengerChange('children', e.target.value)}
                  min="0"
                  max="9"
                  required
                />
              </div> */}
            </div>
          </section>

          <footer className="booking-footer">
            <div className="total-summary">
              <div className="total-tickets">
                <span>{t('Total Passengers')}: </span>
                <span>{totalTickets}</span>
              </div>
              <div className="total-price">
                <span>{t('Total Price')}: </span>
                <span className="price">${totalPrice.toFixed(2)}</span>
              </div>
            </div>
            <button className="book-btn" onClick={bookFlight} disabled={totalTickets === 0}>
              {t('Continue to Passenger Details')}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default FlightTickets;
