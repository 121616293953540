import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/AboutUs.css';

// Import images (you'll need to add these to your assets folder)
import heroImage from '../../assets/flightImges/img3.png';
import luxuryInterior from '../../assets/carousel/img5.jpg';
import firstClassSeat from '../../assets/carousel/img4.jpg';
import gourmetMeal from '../../assets/Images/window1.jpg';
import staffImage from '../../assets/carousel/pepol.jpg';
import awardsImage from '../../assets/flightImges/img4.png';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-container">
      <header className="about-us-hero" style={{backgroundImage: `url(${heroImage})`}}>
        <h1>{t('PLEASUAIR LINES')}</h1>
        <p>{t('Redefining Luxury in the Skies Since 1995')}</p>
      </header>

      <section className="about-us-section about-us-intro">
        <h2>{t('Our Story')}</h2>
        <p>{t('Founded in 1995 by aviation visionary John Doe, PLEASUAIR LINES began with a simple yet ambitious goal: to transform air travel into an unparalleled luxury experience. What started as a fleet of two aircraft has now grown into a global aviation leader, setting new standards in comfort, service, and innovation.')}</p>
        <p>{t('For over two decades, we have been dedicated to pushing the boundaries of whats possible in the skies. Our journey has been marked by continuous innovation, unwavering commitment to passenger comfort, and a passion for excellence that permeates every aspect of our operations.')}</p>
      </section>

      <section className="about-us-section about-us-founder">
        <img src="https://media.newyorker.com/photos/64c03754ada160419b41fc93/master/w_2240,c_limit/Elon-X-Company.jpg" alt={t('John Doe, Founder and CEO')} className="founder-image" />
        <div className="founder-text">
          <h2>{t('Message from Our Founder')}</h2>
          <blockquote>
            {t('"When I founded PLEASUAIR LINES, I envisioned an airline that wouldnt just transport passengers, but would redefine the very concept of air travel. Today, Im proud to say that this vision has become a reality. We dont just fly planes; we curate experiences, create memories, and connect people with unparalleled style and comfort. As we look to the future, our commitment to innovation and luxury remains stronger than ever."')}
          </blockquote>
          <p>{t('- John Doe, Founder and CEO')}</p>
        </div>
      </section>

      <section className="about-us-section about-us-features">
        <h2>{t('The PLEASUAIR Experience')}</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <img src={luxuryInterior} alt={t('Luxury Cabin Interior')} />
            <h3>{t('Unmatched Comfort')}</h3>
            <p>{t('Our cabins are designed to be your home in the sky, with spacious seating, ambient lighting, and climate control for each passenger.')}</p>
          </div>
          <div className="feature-item">
            <img src={firstClassSeat} alt={t('First Class Seat')} />
            <h3>{t('First Class Redefined')}</h3>
            <p>{t('Experience our signature fully-reclining seats with massage function, personal entertainment systems, and privacy screens.')}</p>
          </div>
          <div className="feature-item">
            <img src={gourmetMeal} alt={t('Gourmet Inflight Meal')} />
            <h3>{t('Gourmet Dining at 40,000 Feet')}</h3>
            <p>{t('Savor exquisite meals prepared by world-renowned chefs, paired with a curated selection of fine wines and champagnes.')}</p>
          </div>
        </div>
      </section>

      <section className="about-us-section about-us-values">
        <h2>{t('Our Core Values')}</h2>
        <div className="values-grid">
          <div className="value-item">
            <h3>{t('Luxury Beyond Compare')}</h3>
            <p>{t('We believe in providing a truly luxurious experience that begins the moment you book your ticket and lasts long after youve reached your destination.')}</p>
          </div>
          <div className="value-item">
            <h3>{t('Uncompromising Safety')}</h3>
            <p>{t('Your safety is our utmost priority. We employ cutting-edge technology and rigorous training programs to ensure the highest safety standards in the industry.')}</p>
          </div>
          <div className="value-item">
            <h3>{t('Innovative Spirit')}</h3>
            <p>{t('We continuously push the boundaries of whats possible in air travel, from our state-of-the-art fleet to our innovative service offerings.')}</p>
          </div>
          <div className="value-item">
            <h3>{t('Environmental Responsibility')}</h3>
            <p>{t('Were committed to reducing our environmental impact through sustainable practices, fuel-efficient aircraft, and carbon offset programs.')}</p>
          </div>
        </div>
      </section>

      <section className="about-us-section about-us-staff">
        <img src={staffImage} alt={t('PLEASUAIR Staff')} className="staff-image" />
        <div className="staff-text">
          <h2>{t('Our People')}</h2>
          <p>{t('At the heart of PLEASUAIR LINES is our exceptional team. From our skilled pilots and attentive cabin crew to our meticulous ground staff and innovative management, every member of our team is dedicated to providing you with an unparalleled travel experience.')}</p>
          <p>{t('We invest heavily in training and development, ensuring that our staff are not just employees, but true ambassadors of luxury and excellence.')}</p>
        </div>
      </section>

      <section className="about-us-section about-us-achievements">
        <h2>{t('Our Achievements')}</h2>
        <img src={awardsImage} alt={t('PLEASUAIR Awards')} className="awards-image" />
        <ul className="achievements-list">
          <li>{t('Skytrax 5-Star Airline Rating for 10 consecutive years (2014-2023)')}</li>
          <li>{t('Worlds Best First Class Lounge - Skytrax World Airline Awards 2023')}</li>
          <li>{t('Best Airline in Europe - Business Traveller Awards 2022, 2023')}</li>
          <li>{t('Most Innovative Airline - Air Transport World Awards 2023')}</li>
          <li>{t('Best Inflight Dining Experience - Saveur Culinary Travel Awards 2023')}</li>
        </ul>
      </section>

      <section className="about-us-section about-us-future">
        <h2>{t('Looking to the Future')}</h2>
        <p>{t('As we look ahead, PLEASUAIR LINES remains committed to pushing the boundaries of luxury air travel. Our future plans include:')}</p>
        <ul>
          <li>{t('Expansion of our fleet with next-generation, environmentally friendly aircraft')}</li>
          <li>{t('Introduction of groundbreaking in-flight entertainment systems with virtual reality capabilities')}</li>
          <li>{t('Launch of exclusive, members-only airport lounges in key international hubs')}</li>
          <li>{t('Development of personalized travel experiences powered by artificial intelligence')}</li>
        </ul>
        <p>{t('At PLEASUAIR LINES, we believe that the journey should be as extraordinary as the destination. Join us as we continue to redefine luxury in the skies.')}</p>
      </section>
    </div>
  );
};

export default AboutUs; 