import '../styles/NotFound.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Plane, MapPin, MessageCircle  } from 'lucide-react';

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  }

  const handleNavToChat = () => {
    navigate('/ChatWithAgent');
  }


  return (
    <div className="not-found-container">
      <motion.div
        className="not-found-content"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="not-found-title">{t('404')}</h1>
        <h2 className="not-found-subtitle">{t('Page Not Found')}</h2>
        <p className="not-found-message">{t('Looks like this flight path is off the map!')}</p>
        
        <div className="not-found-animation">
          <motion.div
            className="plane-container"
            animate={{
              x: [0, 440, 0],
              y: [-30, 50, -30],
              rotate: [30, 60, 30],
            }}
            transition={{
              duration: 30,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <Plane size={48} />
          </motion.div>
          <div className="cloud cloud1"></div>
          <div className="cloud cloud2"></div>
          <div className="cloud cloud3"></div>
        </div>
        
        <p className="not-found-help">{t('Need help finding your destination?')}</p>
        <div className="not-found-actions">
          <div onClick={handleReturnHome} className="not-found-button">
            <MapPin size={20} />
            {t('Return to Home')}
          </div>
          <div onClick={handleNavToChat} className="not-found-button">
            <MessageCircle  size={20} />
            {t('Contact Support')}
          </div>
        </div>
      </motion.div>
    </div>
  );
}