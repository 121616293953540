import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import '../../styles/PaymentLinks.css';
import Swal from 'sweetalert2'; // Import SweetAlert2
import 'sweetalert2/dist/sweetalert2.min.css';
import { Trash2, Edit2, X, Plus, Search, ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function PaymentLinks() {
  const { t } = useTranslation();
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({ description: '', amount: '', url: '' });
  const [editingLinkId, setEditingLinkId] = useState(null);
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'paymentLinks'));
      const fetchedLinks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLinks(fetchedLinks);
    } catch (error) {
      console.error('Error fetching links: ', error);
    }
  };

  const handleInputChange = (e, linkId = null) => {
    const { name, value } = e.target;
    if (linkId) {
      setLinks(links.map(link => 
        link.id === linkId ? { ...link, [name]: value } : link
      ));
    } else {
      setNewLink(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'paymentLinks'), newLink);
      setNewLink({ description: '', amount: '', url: '' });
      fetchLinks();
      setIsAddSectionOpen(false);
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleUpdate = async (linkId) => {
    try {
      const linkToUpdate = links.find(link => link.id === linkId);
      await updateDoc(doc(db, 'paymentLinks', linkId), linkToUpdate);
      setEditingLinkId(null);
      fetchLinks();
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Show Swal fire warning before deleting
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Yes, delete it!'),
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6'
      });

      if (result.isConfirmed) {
        await deleteDoc(doc(db, 'paymentLinks', id));
        fetchLinks();
        Swal.fire(t('Deleted!'), t('Your link has been deleted.'), 'success');
      }

    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const filteredLinks = links.filter(link =>
    link.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    link.amount.toString().includes(searchTerm) ||
    link.url.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="payment-links-container">
      <h1 className="payment-links-container__title">{t('Payment Links Manager')}</h1>
      
      <div className="payment-links-container__controls">
        <button 
          className="payment-links-container__add-button"
          onClick={() => setIsAddSectionOpen(!isAddSectionOpen)}
        >
          {isAddSectionOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          {isAddSectionOpen ? t('Close') : t('Add Link')}
        </button>
        <div className="payment-links-container__search">
          <Search size={20} />
          <input
            type="text"
            placeholder={t('Search links...')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {isAddSectionOpen && (
        <form onSubmit={handleSubmit} className="payment-links-container__form">
          <input
            type="text"
            name="description"
            value={newLink.description}
            onChange={handleInputChange}
            placeholder={t('Description')}
            required
          />
          <input
            type="number"
            name="amount"
            value={newLink.amount}
            onChange={handleInputChange}
            placeholder={t('Amount')}
            required
          />
          <input
            type="url"
            name="url"
            value={newLink.url}
            onChange={handleInputChange}
            placeholder={t('Payment URL')}
            required
          />
          <button type="submit" className="payment-links-container__submit-button">
            {t('Add Link')}
          </button>
        </form>
      )}

      <div className="payment-links-container__list">
        {filteredLinks.map(link => (
          <div key={link.id} className="payment-links-container__item">
            {editingLinkId === link.id ? (
              <>
                <input
                  type="text"
                  name="description"
                  value={link.description}
                  onChange={(e) => handleInputChange(e, link.id)}
                  className="payment-links-container__edit-input"
                />
                <div className="payment-links-container__edit-row">
                  <input
                    type="number"
                    name="amount"
                    value={link.amount}
                    onChange={(e) => handleInputChange(e, link.id)}
                    className="payment-links-container__edit-input payment-links-container__edit-input--amount"
                  />
                  <input
                    type="url"
                    name="url"
                    value={link.url}
                    onChange={(e) => handleInputChange(e, link.id)}
                    className="payment-links-container__edit-input payment-links-container__edit-input--url"
                  />
                  <button onClick={() => handleUpdate(link.id)} className="payment-links-container__action-button payment-links-container__action-button--save">
                    {t('Save')}
                  </button>
                  <button onClick={() => setEditingLinkId(null)} className="payment-links-container__action-button payment-links-container__action-button--cancel">
                    {t('Cancel')}
                  </button>
                </div>
              </>
            ) : (
              <>
                <h3 className="payment-links-container__item-description">{link.description}</h3>
                <div className="payment-links-container__item-row">
                  <p className="payment-links-container__item-amount">{t('Amount')}: ${link.amount}</p>
                  <a href={link.url} target="_blank" rel="noopener noreferrer" className="payment-links-container__pay-button">
                    {t('Pay Now')}
                  </a>
                  <div className="payment-links-container__actions">
                    <button onClick={() => setEditingLinkId(link.id)} className="payment-links-container__action-button payment-links-container__action-button--edit" aria-label={t('Edit link')}>
                      <Edit2 size={18} />
                    </button>
                    <button onClick={() => handleDelete(link.id)} className="payment-links-container__action-button payment-links-container__action-button--delete" aria-label={t('Delete link')}>
                      <Trash2 size={18} />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
