import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoChatbubbleEllipses, IoClose } from "react-icons/io5";
import '../styles/FloatingChatButton.css'; // Import the CSS file


const FloatingChatButton = () => {
  const [showChatButton, setShowChatButton] = useState(true);

  const handleClose = (e) => {
    e.preventDefault();
    setShowChatButton(false);
  };

  if (!showChatButton) return null;

  return (
    <div className="floating-button">
      <Link to="/ChatWithAgent" style={{ textDecoration: 'none' }}>
        <div className="chat-button">
          <IoChatbubbleEllipses/>
        </div>
      </Link>
      <button className="close-button" onClick={handleClose}>
        x
      </button>
    </div>
  );
};

export default FloatingChatButton;