// PassengerList.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaUsers } from 'react-icons/fa';

const PassengerList = ({ flight }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    const passengers = flight.bookings.flatMap(booking => 
      (booking.tickets || []).map(ticket => ({
        name: ticket.passengerDetails ? `${ticket.passengerDetails.firstName || ''} ${ticket.passengerDetails.lastName || ''}`.trim() : t('N/A'),
        ticketNo: ticket.ticketNo || t('N/A'),
        seat: ticket.seat || t('N/A'),
        passport: ticket.passengerDetails ? ticket.passengerDetails.passport || t('N/A') : t('N/A'),
        gender: ticket.passengerDetails ? ticket.passengerDetails.gender || t('N/A') : t('N/A')
      }))
    );
    
    const filteredPassengers = passengers.filter(passenger =>
      passenger.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      passenger.ticketNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      passenger.seat.toLowerCase().includes(searchTerm.toLowerCase()) ||
      passenger.passport.toLowerCase().includes(searchTerm.toLowerCase()) ||
      passenger.gender.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div className="passengers-container">
        <h3><FaUsers className="icon" /> {t('Passenger List')}</h3>
        <div className="search-container">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder={t('Search passengers...')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          /> 
        </div>
        <table className="passengers-table">
          <thead>
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Ticket Number')}</th>
              <th>{t('Seat')}</th>
              <th>{t('Passport')}</th>
              <th>{t('Gender')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredPassengers.map((passenger, index) => (
              <tr key={index}>
                <td>{passenger.name}</td>
                <td>{passenger.ticketNo}</td>
                <td>{passenger.seat}</td>
                <td>{passenger.passport}</td>
                <td>{passenger.gender}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default PassengerList;