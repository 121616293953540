// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import './styles/index.css';

// ReactDOM.createRoot(document.getElementById('root')).render(<App />);

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import { auth, db } from './config/firebase'; // Adjust the path as necessary
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import './styles/index.css';


import './Backend/Translation'; //this is for the translate 
import i18n from './Backend/Translation'; // import i18n for language change detection


// function InitApp() {
//   const { i18n } = useTranslation();

//   useEffect(() => {
//     const setLanguageFromFirestore = async (user) => {
//       if (user) {
//         const docRef = doc(db, 'users', user.uid, 'settings', 'preferences');
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists() && docSnap.data().language) {
//           i18n.changeLanguage(docSnap.data().language);
//         }
//       }
//     };

//     const unsubscribe = onAuthStateChanged(auth, setLanguageFromFirestore);
//     return () => unsubscribe();
//   }, [i18n]);

//   return <App />;
// }

function InitApp() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const setLanguage = async (user) => {
      let language = localStorage.getItem('i18nextLng') || 'he';
      if (user) {
        const docRef = doc(db, 'users', user.uid, 'settings', 'preferences');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().language) {
          language = docSnap.data().language;
        }
      }
      await i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', language);
    };

    const unsubscribe = onAuthStateChanged(auth, setLanguage);
    return () => unsubscribe();
  }, [i18n]);

  return <App />;
}


document.documentElement.dir = i18n.language === 'he' ? 'rtl' : 'ltr';
i18n.on('languageChanged', (lng) => {
  document.documentElement.dir = lng === 'he' ? 'rtl' : 'ltr';
});


  ReactDOM.createRoot(document.getElementById('root')).render(<InitApp />);
