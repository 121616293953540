import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { FaSearch } from 'react-icons/fa';
import '../../styles/ManageFlights.css';

function ManageFlights() {
    const { t } = useTranslation();
    const [flights, setFlights] = useState([]);
    const [filteredFlights, setFilteredFlights] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [editing, setEditing] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFlights = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "flights"));
                const flightsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFlights(flightsData);
                setFilteredFlights(flightsData);
            } finally {
                setLoading(false);
            }
        };
        fetchFlights();
    }, []);

    useEffect(() => {
        const filtered = flights.filter(flight => 
            flight.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeSource.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeDestination.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.departureDate.includes(searchTerm)
        );
        setFilteredFlights(filtered);
    }, [searchTerm, flights]);

    const handleDelete = (flightId) => {
        Swal.fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: t('No, dont delete.'),
            confirmButtonText: t('Yes, delete it!')
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDoc(doc(db, "flights", flightId));
                const updatedFlights = flights.filter(flight => flight.id !== flightId);
                setFlights(updatedFlights);
                setFilteredFlights(updatedFlights.filter(flight => 
                    flight.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    flight.routeSource.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    flight.routeDestination.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    flight.departureDate.includes(searchTerm)
                ));
                Swal.fire({
                    title: t('Deleted!'),
                    text: t('The flight has been deleted.'),
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false
                });
            }
        });
    };

    const handleEdit = (flightId) => {
        setEditing(flightId);
    };

    const handleSave = async (flightId) => {
        const flight = flights.find(f => f.id === flightId);
        const flightRef = doc(db, "flights", flightId);
        await updateDoc(flightRef, {
            ...flight,
            insurancePrice: parseInt(flight.insurancePrice, 10),
        });
        setEditing(null);
    };

    const handleCancel = () => {
        setEditing(null);
    };

    const handleChange = (e, flightId, field) => {
        const updatedFlights = flights.map(flight =>
            flight.id === flightId ? { ...flight, [field]: field === 'insurancePrice' ? parseInt(e.target.value, 10) : e.target.value } : flight
        );
        setFlights(updatedFlights);
        setFilteredFlights(updatedFlights.filter(flight => 
            flight.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeSource.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeDestination.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.departureDate.includes(searchTerm)
        ));
    };

    const handleToggleFlightType = (flightId) => {
        const updatedFlights = flights.map(flight =>
            flight.id === flightId ? { ...flight, isRoundTrip: !flight.isRoundTrip } : flight
        );
        setFlights(updatedFlights);
        setFilteredFlights(updatedFlights.filter(flight => 
            flight.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeSource.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.routeDestination.toLowerCase().includes(searchTerm.toLowerCase()) ||
            flight.departureDate.includes(searchTerm)
        ));
    };

    return (
        <div className="manage-flights-container">
            <h1>{t('Flight Management')}</h1>

            <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                    type="text"
                    placeholder={t('Search flights...')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}

            <table className="flights-table">
                <thead>
                    <tr>
                        <th>{t('Flight Name')}</th>
                        <th>{t('Flight Type')}</th>
                        <th>{t('Route')}</th>
                        <th>{t('Departure')}</th>
                        <th>{t('Economy Class')}</th>
                        <th>{t('Business Class')}</th>
                        <th>{t('First Class')}</th>
                        <th>{t('Insurance Price')}</th>
                        <th>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFlights.map((flight) => (
                        <tr key={flight.id}>
                            <td>
                                {editing === flight.id ? (
                                    <input
                                        type="text"
                                        value={flight.name}
                                        onChange={(e) => handleChange(e, flight.id, 'name')}
                                    />
                                ) : flight.name}
                            </td>

                            <td>
                                {editing === flight.id ? (
                                    <select
                                        value={flight.isRoundTrip ? 'roundTrip' : 'oneWay'}
                                        onChange={(e) => handleToggleFlightType(flight.id)}
                                    >
                                        <option value="roundTrip">{t('Round Trip')}</option>
                                        <option value="oneWay">{t('One Way')}</option>
                                    </select>
                                ) : (
                                    flight.isRoundTrip ? t('Round Trip') : t('One Way Trip')
                                )}
                            </td>

                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <input
                                            type="text"
                                            value={flight.routeSource}
                                            onChange={(e) => handleChange(e, flight.id, 'routeSource')}
                                        />
                                        <input
                                            type="text"
                                            value={flight.routeDestination}
                                            onChange={(e) => handleChange(e, flight.id, 'routeDestination')}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p>{flight.routeSource}</p>
                                        <p> {flight.routeDestination}</p>
                                    </>
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <input
                                            type="date"
                                            value={flight.departureDate}
                                            onChange={(e) => handleChange(e, flight.id, 'departureDate')}
                                        />
                                        <input
                                            type="time"
                                            value={flight.departureTime}
                                            onChange={(e) => handleChange(e, flight.id, 'departureTime')}
                                        />
                                    </>
                                ) : (
                                    `${flight.departureDate} ${flight.departureTime}`
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <input
                                            type="number"
                                            value={flight.economyClassTicketCost}
                                            onChange={(e) => handleChange(e, flight.id, 'economyClassTicketCost')}
                                        />
                                        <div className="seats-info">
                                            <input
                                                type="number"
                                                value={flight.economyClassTotalSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'economyClassTotalSeats')}
                                            />
                                            <input
                                                type="number"
                                                value={flight.economyClassRemainingSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'economyClassRemainingSeats')}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>${flight.economyClassTicketCost}</div>
                                        <div className="seats-info">
                                            <div>{t('Total')}: {flight.economyClassTotalSeats}</div>
                                            <div>{t('Remaining')}: {flight.economyClassRemainingSeats}</div>
                                        </div>
                                    </>
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <input
                                            type="number"
                                            value={flight.businessClassTicketCost}
                                            onChange={(e) => handleChange(e, flight.id, 'businessClassTicketCost')}
                                        />
                                        <div className="seats-info">
                                            <input
                                                type="number"
                                                value={flight.businessClassTotalSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'businessClassTotalSeats')}
                                            />
                                            <input
                                                type="number"
                                                value={flight.businessClassRemainingSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'businessClassRemainingSeats')}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>${flight.businessClassTicketCost}</div>
                                        <div className="seats-info">
                                            <div>{t('Total')}: {flight.businessClassTotalSeats}</div>
                                            <div>{t('Remaining')}: {flight.businessClassRemainingSeats}</div>
                                        </div>
                                    </>
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <input
                                            type="number"
                                            value={flight.firstClassTicketCost}
                                            onChange={(e) => handleChange(e, flight.id, 'firstClassTicketCost')}
                                        />
                                        <div className="seats-info">
                                            <input
                                                type="number"
                                                value={flight.firstClassTotalSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'firstClassTotalSeats')}
                                            />
                                            <input
                                                type="number"
                                                value={flight.firstClassRemainingSeats}
                                                onChange={(e) => handleChange(e, flight.id, 'firstClassRemainingSeats')}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>${flight.firstClassTicketCost}</div>
                                        <div className="seats-info">
                                            <div>{t('Total')}: {flight.firstClassTotalSeats}</div>
                                            <div>{t('Remaining')}: {flight.firstClassRemainingSeats}</div>
                                        </div>
                                    </>
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <input
                                        type="number"
                                        value={flight.insurancePrice}
                                        onChange={(e) => handleChange(e, flight.id, 'insurancePrice')}
                                    />
                                ) : (
                                    `$${flight.insurancePrice}`
                                )}
                            </td>
                            <td>
                                {editing === flight.id ? (
                                    <>
                                        <button className="btn save-btn" onClick={() => handleSave(flight.id)}>{t('Save')}</button>
                                        <button className="btn cancel-btn" onClick={handleCancel}>{t('Cancel')}</button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn edit-btn" onClick={() => handleEdit(flight.id)}>{t('Edit')}</button>
                                        <button className="btn delete-btn" onClick={() => handleDelete(flight.id)}>{t('Delete')}</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ManageFlights;