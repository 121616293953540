// AdminPage.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircle, IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { FaPlane, FaBookOpen, FaUsers, FaComments, FaBars, FaUserShield } from 'react-icons/fa';
import { RiCoupon3Fill, RiSecurePaymentFill } from 'react-icons/ri';
import { ImAirplane } from 'react-icons/im';
import AddFlight from '../pages/Management/add-flight';
import ManageFlights from '../pages/Management/ManageFlights';
import ManageAirplaneTypes from '../pages/Management/ManageAirplaneTypes';
import ManageBookings from '../pages/Management/ManageBookings';
import ManageUsers from '../pages/Management/ManageUsers';
import CouponManagement from '../pages/Management/CouponManagement';
import AdminChatRooms from '../Backend/AdminChatRooms';
import ManageRoles from '../pages/Management/ManageRoles';
import PaymentLinks from 'pages/Management/PaymentLinks';
import Swal from 'sweetalert2';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

import '../styles/AdminPage.css';

function AdminPage() {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState('manage-bookings');
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [loadingRole, setLoadingRole] = useState(true);

    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    useEffect(() => {
        const fetchUserRole = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserRole(userData.role);
                }
            }
            setLoadingRole(false);
        };

        fetchUserRole();
    }, []);

    const handleLogin = () => {
        if (password === adminPassword) {
            setIsAuthenticated(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: t('Incorrect Password'),
                text: t('Please try again.'),
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    const adminMenuItems = [
        { id: 'add-flight', icon: <IoMdAddCircle />, label: t('Add Flight') },
        { id: 'manage-flights', icon: <ImAirplane />, label: t('Manage Flights') },
        { id: 'manage-airplane-types', icon: <FaPlane />, label: t('Manage Airplane Types') },
        { id: 'manage-bookings', icon: <FaBookOpen />, label: t('Manage Bookings') },
        { id: 'manage-users', icon: <FaUsers />, label: t('Manage Users') },
        { id: 'manage-coupons', icon: <RiCoupon3Fill />, label: t('Manage Coupons') },
        { id: 'manage-chat-rooms', icon: <FaComments />, label: t('Manage Chat Rooms') },
        { id: 'manage-roles', icon: <FaUserShield />, label: t('Manage Roles') },
        { id: 'payment-links', icon: <RiSecurePaymentFill />, label: t('Payment Links') },

    ];

    const workerMenuItems = [
        { id: 'manage-bookings', icon: <FaBookOpen />, label: t('Manage Bookings') },
        { id: 'manage-chat-rooms', icon: <FaComments />, label: t('Manage Chat Rooms') },
        { id: 'manage-users', icon: <FaUsers />, label: t('Manage Users') },
        { id: 'payment-links', icon: <RiSecurePaymentFill />, label: t('Payment Links') },
    ];

    let menuItems = [];

    if (userRole === 'admin') {
        menuItems = adminMenuItems;
    } else if (userRole === 'worker') {
        menuItems = workerMenuItems;
    }

    const hasAccess = (sectionId) => {
        if (userRole === 'admin') {
            return true;
        } else if (userRole === 'worker') {
            return workerMenuItems.some((item) => item.id === sectionId);
        }
        return false;
    };

    const renderActiveSection = () => {
        if (!hasAccess(activeSection)) {
            return (
                <div>
                    <h2>{t('Access Denied')}</h2>
                    <p>{t('You do not have permission to view this section.')}</p>
                </div>
            );
        }

        switch (activeSection) {
            case 'add-flight':
                return <AddFlight />;
            case 'manage-flights':
                return <ManageFlights />;
            case 'manage-airplane-types':
                return <ManageAirplaneTypes />;
            case 'manage-bookings':
                return <ManageBookings />;
            case 'manage-users':
                return <ManageUsers />;
            case 'manage-coupons':
                return <CouponManagement />;
            case 'manage-chat-rooms':
                return <AdminChatRooms />;
            case 'manage-roles':
                return <ManageRoles />;
            case 'payment-links':
                return <PaymentLinks />;
            default:
                return <ManageBookings />;
        }
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    if (loadingRole) {
        return (
            <div className="loading-page">
                <h2>{t('Loading...')}</h2>
            </div>
        );
    }

    if (!isAuthenticated) {
        return (
            <div className="login-page">
                <h2>{t('Admin Login')}</h2>
                <input
                    type="password"
                    placeholder={t('Enter Password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>{t('Login')}</button>
            </div>
        );
    }

    return (
        <div
            className={`admin-page ${isCollapsed ? 'collapsed' : ''} ${
                isMobileMenuOpen ? 'mobile-menu-open' : ''
            }`}
        >
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                <FaBars />
            </button>
            <div className="admin-page-sidebar">
                <div className="sidebar-toggle" onClick={toggleSidebar}>
                    {isCollapsed ? <IoMdArrowDropright /> : <IoMdArrowDropleft />}
                </div>
                <ul>
                    {menuItems.map((item) => (
                        <li
                            key={item.id}
                            className={activeSection === item.id ? 'active' : ''}
                            onClick={() => {
                                setActiveSection(item.id);
                                setIsMobileMenuOpen(false);
                            }}
                        >
                            <span className="icon">{item.icon}</span>
                            {(!isCollapsed || isMobileMenuOpen) && (
                                <span className="label">{item.label}</span>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main-content">{renderActiveSection()}</div>
        </div>
    );
}

export default AdminPage;
