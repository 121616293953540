// SeatMap.jsx
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useTranslation } from 'react-i18next';


const SeatMap = ({ flight }) => {
    const { t } = useTranslation();
    const [seatConfig, setSeatConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reservedSeats, setReservedSeats] = useState([]);


    

    // useEffect(() => {
    //   const fetchSeatConfig = async () => {
    //     try {
    //       const airplaneTypeQuery = query(
    //         collection(db, 'airplaneTypes'),
    //         where('name', '==', flight.airplaneType)
    //       );
    //       const querySnapshot = await getDocs(airplaneTypeQuery);

    //       if (!querySnapshot.empty) {
    //         const airplaneTypeDoc = querySnapshot.docs[0];
    //         setSeatConfig(airplaneTypeDoc.data());
    //       } else {
    //         console.error(t('Airplane configuration not found'));
    //       }
    //     } catch (error) {
    //       console.error(t('Error fetching airplane configuration:'), error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };

    //   fetchSeatConfig();
    // }, [flight.airplaneType, t]);


    useEffect(() => {
      const fetchSeatConfig = async () => {
        try {
          const airplaneTypeQuery = query(
            collection(db, 'airplaneTypes'),
            where('name', '==', flight.airplaneType)
          );
          const querySnapshot = await getDocs(airplaneTypeQuery);
  
          if (!querySnapshot.empty) {
            const airplaneTypeDoc = querySnapshot.docs[0];
            setSeatConfig(airplaneTypeDoc.data());
          } else {
            console.error(t('Airplane configuration not found'));
          }
        } catch (error) {
          console.error(t('Error fetching airplane configuration:'), error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchSeatConfig();
  
      // Add real-time listener for reserved seats
      const flightDocRef = doc(db, 'flights', flight.id);
      const unsubscribe = onSnapshot(flightDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const flightData = docSnapshot.data();
          setReservedSeats(flightData.reservedSeats || []);
          console.log('Reserved seats updated:', flightData.reservedSeats || []);
        }
      });
  
      return () => unsubscribe();
    }, [flight.airplaneType, flight.id, t]);


    if (loading) {
      return <div className="loading">{t('Loading')}...</div>;
    }

    if (!seatConfig) {
      return <div>{t('Seat configuration not available for this flight.')}</div>;
    }

    const renderSeats = (classType, rows, seatsPerRow) => {
      return Array.from({ length: rows }).map((_, rowIndex) => (
        <div key={`${classType}-${rowIndex}`} className="seat-row">
          {Array.from({ length: seatsPerRow }).map((_, seatIndex) => {
            const seatLabel = getSeatLabel(classType, rowIndex, seatIndex, seatsPerRow);
            const fullSeatLabel = `${classType}-${seatLabel}`;
            const isReserved = flight.reservedSeats && flight.reservedSeats.includes(fullSeatLabel);

            return (
              <React.Fragment key={`${classType}-${rowIndex}-${seatIndex}`}>
                {seatIndex === 3 && <div className="aisle"></div>}
                <button
                  className={`seat ${isReserved ? 'reserved' : ''}`}
                  disabled
                >
                  {seatLabel}
                </button>
              </React.Fragment>
            );    
          })} 
        </div>
      ));
    };

    const getSeatLabel = (classType, rowIndex, seatIndex, seatsPerRow) => {
      const rowNumber = rowIndex + 1;
      const seatLetter = seatIndex < 3 ? String.fromCharCode(65 + seatIndex) : String.fromCharCode(68 + (seatIndex - 3));
      return `${rowNumber}${seatLetter}`;
    };

    return (
      <div className="seat-map">
        {seatConfig.firstClass && seatConfig.firstClass.rows > 0 && (
          <div className="seat-class-section">
            <h3>{t('First Class')}</h3>
            <div className="seat-grid">
              {renderSeats('A', seatConfig.firstClass.rows, 4)}
            </div>
          </div>
        )}

        {seatConfig.businessClass && seatConfig.businessClass.rows > 0 && (
          <div className="seat-class-section">
            <h3>{t('Business Class')}</h3>
            <div className="seat-grid">
              {renderSeats('B', seatConfig.businessClass.rows, 6)}
            </div>
          </div>
        )}

        {seatConfig.economyClass && seatConfig.economyClass.rows > 0 && (
          <div className="seat-class-section">
            <h3>{t('Economy Class')}</h3>
            <div className="seat-grid">
              {renderSeats('C', seatConfig.economyClass.rows, 6)}
            </div>
          </div>
        )}
      </div>
    );
};

export default SeatMap;