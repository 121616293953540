




import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './Backend/Translation';
import { auth } from './config/firebase';
import { onAuthStateChanged } from 'firebase/auth';

// Import your components here
import Header from './components/Header';
import HomeComponent from './pages/information/Home';
import AddFlight from './pages/Management/add-flight';
import FlightBooking from './pages/flight booking/FlightBooking';
import Flights from './pages/flight booking/Flights';
import FlightDetails from './pages/flight booking/FlightDetails';
import FlightTickets from './pages/flight booking/FlightTickets';
import SavedFlights from './pages/User account/SavedFlights';
import AuthPage from './pages/User account/AuthPage'; 
import ManageFlights from './pages/Management/ManageFlights'; 
import ManageAirplaneTypes from './pages/Management/ManageAirplaneTypes';
import ManageUsers from './pages/Management/ManageUsers';
import CouponManagement from 'pages/Management/CouponManagement';
import InvoiceComponent from './pages/flight booking/InvoiceComponent';
import AccountPage from './pages/User account/AccountPage';
import SeatSelection from './pages/flight booking/SeatSelection';
import OrderHistory from './pages/User account/OrderHistory';
import ManageBookings from './pages/Management/ManageBookings';
import AdminPage from './components/AdminPage';
import AboutUs from './pages/information/About-Us';
import ChatWithAgent from './Backend/ChatWithAgent';
import FAQ from './pages/information/FAQ';
import TermsOfUse from './pages/information/TermsOfUse';
import PassengerDetails from './pages/flight booking/PassengerDetails';
import PaymentPage from './pages/flight booking/PaymentPage';
import ImageGallery from './pages/information/ImageGallery';
import {PartnerSection, OfficeLocations} from './pages/information/ImageGallery';
import ComingSoon from './Backend/ComingSoon';
import NotFound from 'Backend/NotFound';


const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/auth" />;
};

function App() {
  const [isDevelopmentDomain, setIsDevelopmentDomain] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    setIsDevelopmentDomain(
      hostname === 'flight-56537.web.app' ||
      hostname === 'flight-56537.firebaseapp.com' ||
      hostname === 'localhost'
    );
  }, []);

  if (!isDevelopmentDomain) {
    return <ComingSoon />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Header />
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/" element={<ProtectedRoute><HomeComponent /></ProtectedRoute>} />
          <Route path="/add-flight" element={<ProtectedRoute><AddFlight /></ProtectedRoute>} />
          <Route path="/book-flight" element={<ProtectedRoute><FlightBooking /></ProtectedRoute>} />
          <Route path="/flights" element={<ProtectedRoute><Flights /></ProtectedRoute>} />
          <Route path="/flight-details" element={<ProtectedRoute><FlightDetails /></ProtectedRoute>} />
          <Route path="/flight-tickets" element={<ProtectedRoute><FlightTickets /></ProtectedRoute>} />
          <Route path="/passenger-details" element={<ProtectedRoute><PassengerDetails /></ProtectedRoute>} />
          <Route path="/saved" element={<ProtectedRoute><SavedFlights /></ProtectedRoute>} />
          <Route path="/flight-receipt" element={<ProtectedRoute><InvoiceComponent /></ProtectedRoute>} />
          <Route path="/manage-flights" element={<ProtectedRoute><ManageFlights /></ProtectedRoute>} />
          <Route path="/manage-airplane-types" element={<ProtectedRoute><ManageAirplaneTypes /></ProtectedRoute>} />
          <Route path="/manage-users" element={<ProtectedRoute><ManageUsers /></ProtectedRoute>} />
          <Route path="/manage-coupons" element={<ProtectedRoute><CouponManagement /></ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
          <Route path="/OrderHistory" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>} />
          <Route path="/seat-selection" element={<ProtectedRoute><SeatSelection /></ProtectedRoute>} />
          <Route path="/manage-bookings" element={<ProtectedRoute><ManageBookings /></ProtectedRoute>} />
          <Route path="/Payment" element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
          <Route path="/about-us" element={<ProtectedRoute><AboutUs /></ProtectedRoute>} />
          <Route path="/ChatWithAgent" element={<ProtectedRoute><ChatWithAgent /></ProtectedRoute>} />
          <Route path="/FAQ" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
          <Route path="/TermsOfUse" element={<ProtectedRoute><TermsOfUse /></ProtectedRoute>} />
          <Route path="/gallery" element={<ProtectedRoute><ImageGallery /></ProtectedRoute>} />
          <Route path="/business-details" element={<ProtectedRoute><PartnerSection /></ProtectedRoute>} />
          <Route path="/offices" element={<ProtectedRoute><OfficeLocations /></ProtectedRoute>} />
          <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} /> {/* Catch-all route */}
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;