// ManageRoles.js

import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  ButtonGroup,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Search as SearchIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const auth = getAuth();

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
}));

const FilterContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

function ManageRoles() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('admin');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((docItem) => {
          const userData = docItem.data();
          return {
            id: docItem.id,
            ...userData,
          };
        });
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
        Swal.fire(
          t('Error'),
          t('There was an issue fetching the users. Please try again later.'),
          'error'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [t]);

  const handleRoleChange = async (userId, newRole) => {
    try {
      await updateDoc(doc(db, 'users', userId), { role: newRole });
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
      Swal.fire(t('Success'), t('Role updated successfully'), 'success');
    } catch (error) {
      console.error('Error updating role:', error);
      Swal.fire(
        t('Error'),
        t('There was an issue updating the role. Please try again later.'),
        'error'
      );
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };


  // פילטר של המשתמשים להצגה
  const filteredUsers = users.filter((user) => {
    const matchesSearch =
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase());

    if (user.id === auth.currentUser?.uid) {
      return false; // לא להציג את המשתמש המחובר עצמו
    }

    if (selectedCategory === 'all') {
      return matchesSearch || !searchTerm;
    }

    return user.role === selectedCategory && (matchesSearch || !searchTerm);
  });

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '50px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '40px auto',
        padding: '20px',
      }}
    >
      <Typography variant="h4" gutterBottom align="center">
        {t('Manage Roles')}
      </Typography>

      <SearchContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <SearchIcon sx={{ marginRight: 1 }} />
          <TextField
            variant="standard"
            placeholder={t('Search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>

        <FilterContainer>
          <ButtonGroup variant="outlined" color="primary">
            <Button
              onClick={() => handleCategoryChange('admin')}
              variant={selectedCategory === 'admin' ? 'contained' : 'outlined'}
            >
              {t('Admins')}
            </Button>
            <Button
              onClick={() => handleCategoryChange('worker')}
              variant={selectedCategory === 'worker' ? 'contained' : 'outlined'}
            >
              {t('Workers')}
            </Button>
            <Button
              onClick={() => handleCategoryChange('all')}
              variant={selectedCategory === 'all' ? 'contained' : 'outlined'}
            >
              {t('All Users')}
            </Button>
          </ButtonGroup>
        </FilterContainer>

      </SearchContainer>

      {filteredUsers.length > 0 ? (
        <UserTable users={filteredUsers} handleRoleChange={handleRoleChange} />
      ) : (
        <Typography variant="body1" align="center">
          {t('No users found.')}
        </Typography>
      )}
    </Box>
  );
}

function UserTable({ users, handleRoleChange }) {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Email')}</TableCell>
            <TableCell>{t('Current Role')}</TableCell>
            <TableCell>{t('Actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id} hover>
              <TableCell>{user.name || t('User')}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{t(user.role || 'User')}</TableCell>
              <TableCell>
                <FormControl variant="outlined" size="small">
                  <InputLabel>{t('Role')}</InputLabel>
                  <Select
                    label={t('Role')}
                    value={user.role || 'user'}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <MenuItem value="admin">{t('Admin')}</MenuItem>
                    <MenuItem value="worker">{t('General Worker')}</MenuItem>
                    <MenuItem value="user">{t('User')}</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ManageRoles;
