import React from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../../config/firebase'; // Import Firebase
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore methods
import '../../styles/SettingsPage.css';

function SettingsPage() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleLanguageChange = async (language) => {
        i18n.changeLanguage(language);

        // Save the selected language to Firebase
        const user = auth.currentUser;
        if (user) {
            const userSettingsRef = doc(db, 'users', user.uid, 'settings', 'preferences');
            await setDoc(userSettingsRef, { language }, { merge: true });
        }
    };

    return (
        <div className="settings-page">
            <h1>{t('Language Settings')}</h1>
            <div className="language-buttons">
                <button
                    onClick={() => handleLanguageChange('en')}
                    className={`language-btn ${currentLanguage === 'en' ? 'active' : ''}`}
                >
                    {t('English')}
                </button>
                <button
                    onClick={() => handleLanguageChange('he')}
                    className={`language-btn ${currentLanguage === 'he' ? 'active' : ''}`}
                >
                    {t('Hebrew')}
                </button>
            </div>
        </div>
    );
}

export default SettingsPage;
