import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import '../../styles/OrderHistory.css';

const OrderHistory = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const ordersCollection = collection(db, 'users', user.uid, 'orders');
          const ordersSnapshot = await getDocs(ordersCollection);
          const ordersList = ordersSnapshot.docs.map(doc => doc.data());
          console.log('Orders fetched from Firebase:', ordersList);
          setOrders(ordersList);
        } catch (error) {
          console.error('Error fetching orders from Firebase:', error);
        }
      } else {
        console.error('User is not logged in');
      }
      setLoading(false);
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <div className="loading">{t('Loading...')}</div>;
  }

  return (
    <>
      <br /><br />
      <div className="order-history-container">
        <h1>{t('Your Order History')}</h1>
        {orders.length > 0 ? (
          <table className="orders-table">
            <thead>
              <tr>
                <th>{t('Booking ID')}</th>
                <th>{t('Flight ID')}</th>
                <th>{t('Total Cost')}</th>
                <th>{t('Tickets')}</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{order.bookingId}</td>
                  <td>{order.flightId}</td>
                  <td>${order.totalCost}</td>
                  <td>
                    <ul>
                      {order.tickets.map((ticket, idx) => (
                        <li key={idx}>
                          {ticket.className}: {ticket.ticketNo} 
                          {order.selectedSeats && order.selectedSeats[idx] ? (
                            <> ({t('Seat')}: {order.selectedSeats[idx]})</>
                          ) : (
                            <> ({t('Seat')}: {t('Unassigned')})</>
                          )}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t('You have no orders yet.')}</p>
        )}
      </div>
    </>
  );
};

export default OrderHistory;
