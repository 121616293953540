// import React, { useState, useEffect } from 'react';
// import styled, { keyframes, createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle`
//   @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;700&display=swap');

//   :root {
//     --primary-color: #0066cc;
//     --secondary-color: #00aaff;
//     --accent-color: #ff9900;
//     --text-color: #ffffff;
//     --font-main: 'Roboto', Arial, sans-serif;
//     --font-accent: 'Montserrat', sans-serif;
//   }

//   body, html {
//     height: 100%;
//     margin: 0;
//     font-family: var(--font-main);
//     background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
//     color: var(--text-color);
//     overflow: hidden;
//   }
// `;

// const fly = keyframes`
//   0% { transform: translateX(-100vw) translateY(0); }
//   25% { transform: translateX(-25vw) translateY(-20px); }
//   50% { transform: translateX(25vw) translateY(20px); }
//   75% { transform: translateX(75vw) translateY(-20px); }
//   100% { transform: translateX(100vw) translateY(0); }
// `;

// const fadeInDown = keyframes`
//   from { opacity: 0; transform: translateY(-20px); }
//   to { opacity: 1; transform: translateY(0); }
// `;

// const fadeIn = keyframes`
//   from { opacity: 0; }
//   to { opacity: 1; }
// `;

// const pulse = keyframes`
//   0%, 100% { transform: scale(1); }
//   50% { transform: scale(1.05); }
// `;

// const moveClouds = keyframes`
//   from { background-position: 0 bottom; }
//   to { background-position: -100% bottom; }
// `;

// const Container = styled.div`
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   padding: 2rem;
//   box-sizing: border-box;
// `;

// const Title = styled.h1`
//   font-family: var(--font-accent);
//   font-size: 4em;
//   margin-bottom: 1rem;
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   animation: ${fadeInDown} 1s ease-out;

//   @media (max-width: 768px) {
//     font-size: 3em;
//   }

//   @media (max-width: 480px) {
//     font-size: 2.5em;
//   }
// `;

// const Paragraph = styled.p`
//   font-size: 1.4em;
//   margin-bottom: 2rem;
//   max-width: 600px;
//   line-height: 1.6;
//   animation: ${fadeIn} 1.5s ease-out;

//   @media (max-width: 768px) {
//     font-size: 1.2em;
//   }

//   @media (max-width: 480px) {
//     font-size: 1em;
//   }
// `;

// const Countdown = styled.div`
//   font-size: 2.5em;
//   margin-bottom: 3rem;
//   font-weight: bold;
//   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
//   animation: ${pulse} 2s infinite;

//   @media (max-width: 768px) {
//     font-size: 2em;
//   }

//   @media (max-width: 480px) {
//     font-size: 1.8em;
//   }
// `;

// const Plane = styled.div`
//   font-size: 6em;
//   animation: ${fly} 15s infinite linear;
//   opacity: 0.8;
//   filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));

//   @media (max-width: 768px) {
//     font-size: 4em;
//   }

//   @media (max-width: 480px) {
//     font-size: 3em;
//   }
// `;

// const CloudBackground = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 28" width="56" height="28"><path fill="%23ffffff" fill-opacity="0.05" d="M56 26c-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64C5.3 24.97 3.28 26 1 26H0V0h1c2.22 0 4.16 1.03 5.42 2.64C7.7 1.03 9.72 0 12 0c2.22 0 4.16 1.03 5.42 2.64C18.7 1.03 20.72 0 23 0c2.22 0 4.16 1.03 5.42 2.64C29.7 1.03 31.72 0 34 0c2.22 0 4.16 1.03 5.42 2.64C40.7 1.03 42.72 0 45 0c2.22 0 4.16 1.03 5.42 2.64C51.7 1.03 53.72 0 56 0v26z"/></svg>') repeat-x bottom;
//   animation: ${moveClouds} 30s linear infinite;
// `;

// export default function ComingSoon() {
//   const [countdown, setCountdown] = useState('Loading...');

//   useEffect(() => {
//     // Set the countdown date to 2nd March 2025, 2:00 PM
//     const countDownDate = new Date('2025-03-02T14:00:00').getTime();

//     const timer = setInterval(() => {
//       const now = new Date().getTime();
//       const distance = countDownDate - now;

//       // Calculate days, hours, minutes, and seconds
//       const days = Math.floor(distance / (1000 * 60 * 60 * 24));
//       const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//       const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//       const seconds = Math.floor((distance % (1000 * 60)) / 1000);

//       setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);

//       // If the countdown is finished, display EXPIRED
//       if (distance < 0) {
//         clearInterval(timer);
//         setCountdown('EXPIRED');
//       }
//     }, 1000);

//     // Cleanup the timer on component unmount
//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <>
//       <GlobalStyle />
//       <CloudBackground />
//       <Container>
//         <Title>PleasuAirlines</Title>
//         <Paragraph>We're preparing for takeoff! Our new website is coming soon.</Paragraph>
//         <Countdown>{countdown}</Countdown>
//         <Paragraph>Get ready for an amazing flight experience!</Paragraph>
//         <Plane>✈️</Plane>
//       </Container>
//     </>
//   );
// }

















import React, { useState, useEffect } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;700&display=swap');

  :root {
    --primary-color: #0066cc;
    --secondary-color: #00aaff;
    --accent-color: #ff9900;
    --text-color: #ffffff;
    --font-main: 'Roboto', Arial, sans-serif;
    --font-accent: 'Montserrat', sans-serif;
  }

  body, html {
    height: 100%;
    margin: 0;
    font-family: var(--font-main);
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--text-color);
    overflow: hidden;
  }
`;

const fly = keyframes`
  0% { transform: translateX(-100vw) translateY(0); }
  25% { transform: translateX(-25vw) translateY(-20px); }
  50% { transform: translateX(25vw) translateY(20px); }
  75% { transform: translateX(75vw) translateY(-20px); }
  100% { transform: translateX(100vw) translateY(0); }
`;

const fadeInDown = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
`;

const moveClouds = keyframes`
  from { background-position: 0 bottom; }
  to { background-position: -100% bottom; }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-family: var(--font-accent);
  font-size: 4em;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  animation: ${fadeInDown} 1s ease-out;

  @media (max-width: 768px) {
    font-size: 3em;
  }

  @media (max-width: 480px) {
    font-size: 2.5em;
  }
`;

const Paragraph = styled.p`
  font-size: 1.4em;
  margin-bottom: 2rem;
  max-width: 600px;
  line-height: 1.6;
  animation: ${fadeIn} 1.5s ease-out;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const Countdown = styled.div`
  font-size: 2.5em;
  margin-bottom: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: ${pulse} 2s infinite;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.8em;
  }
`;

const Plane = styled.div`
  font-size: 6em;
  animation: ${fly} 15s infinite linear;
  opacity: 0.8;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));

  @media (max-width: 768px) {
    font-size: 4em;
  }

  @media (max-width: 480px) {
    font-size: 3em;
  }
`;

const CloudBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 28" width="56" height="28"><path fill="%23ffffff" fill-opacity="0.05" d="M56 26c-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64-1.28 1.65-3.3 2.64-5.58 2.64-2.22 0-4.16-1.03-5.42-2.64C5.3 24.97 3.28 26 1 26H0V0h1c2.22 0 4.16 1.03 5.42 2.64C7.7 1.03 9.72 0 12 0c2.22 0 4.16 1.03 5.42 2.64C18.7 1.03 20.72 0 23 0c2.22 0 4.16 1.03 5.42 2.64C29.7 1.03 31.72 0 34 0c2.22 0 4.16 1.03 5.42 2.64C40.7 1.03 42.72 0 45 0c2.22 0 4.16 1.03 5.42 2.64C51.7 1.03 53.72 0 56 0v26z"/></svg>') repeat-x bottom;
  animation: ${moveClouds} 30s linear infinite;
`;

const LanguageSelector = styled.select`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export default function ComingSoon() {
  const [countdown, setCountdown] = useState('Loading...');
  const [language, setLanguage] = useState('he');

  useEffect(() => {
    const countDownDate = new Date('2025-03-02T14:00:00').getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (distance < 0) {
        clearInterval(timer);
        setCountdown('EXPIRED');
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const translations = {
    en: {
      title: 'PleasuAirlines',
      subtitle: "We're preparing for takeoff! Our new website is coming soon.",
      getReady: 'Get ready for an amazing flight experience!',
    },
    he: {
      title: 'פלז׳ואיירליינס',
      subtitle: 'אנחנו מתכוננים להמראה! האתר החדש שלנו יעלה בקרוב.',
      getReady: 'התכוננו לחוויית טיסה מדהימה!',
    },
  };

  return (
    <>
      <GlobalStyle />
      <CloudBackground />
      <Container>
        <LanguageSelector onChange={(e) => setLanguage(e.target.value)} value={language}>
          <option value="he">עברית</option>
          <option value="en">English</option>
        </LanguageSelector>
        <Title>{translations[language].title}</Title>
        <Paragraph>{translations[language].subtitle}</Paragraph>
        <Countdown>{countdown}</Countdown>
        <Paragraph>{translations[language].getReady}</Paragraph>
        <Plane>✈️</Plane>
      </Container>
    </>
  );
}