import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logos/logo PleasuAir.png';
import { QRCodeSVG } from 'qrcode.react';
import { FaArrowRightLong } from "react-icons/fa6";
import '../../styles/BoardingPass.css';


const generateQRCodeData = (flight, passenger, isReturn = false) => {
    return `Passenger: ${passenger.firstName} ${passenger.lastName}
    Date of Birth: ${passenger.dateOfBirth}
    Passport: ${passenger.passport}
    Passport Expiry Date: ${passenger.passportExpiryDate || 'N/A'}
    Nationality: ${passenger.nationality}
    Gender: ${passenger.gender}
    Class: ${passenger.className || 'N/A'}
    Ticket No: ${passenger.ticketNo || 'N/A'}
    Seat: ${passenger.seat || 'N/A'}
    Flight ID: ${flight.id || 'N/A'}
    Flight NAME: ${flight.name || 'N/A'}
    Booking ID: ${passenger.bookingId || 'N/A'}
    ${isReturn ? 'Return Flight' : 'Outbound Flight'}
    From: ${isReturn ? flight.routeDestination : flight.routeSource || 'N/A'}
    To: ${isReturn ? flight.routeSource : flight.routeDestination || 'N/A'}
    Date: ${isReturn ? flight.returnDate : flight.departureDate || 'N/A'}
    Time: ${isReturn ? flight.returnTime : flight.departureTime || 'N/A'}`;
  };


const BoardingPass = ({ flight, passenger, isReturn }) => {
    const { t } = useTranslation();

    const departureTime = isReturn ? flight.returnTime : flight.departureTime;
    const departureDate = isReturn ? flight.returnDate : flight.departureDate;
    const from = isReturn ? flight.routeDestination : flight.routeSource;
    const to = isReturn ? flight.routeSource : flight.routeDestination;

    return (
      <div className="boarding-pass">
        <div className="main-ticket">
          <div className="header">
            <div className="airline">
              <img src={logo} alt="PleasuAir Logo" className="logo-image" />
            </div>
            <div className="title">Boarding pass</div>
            <div className="travel-class">{passenger.className}</div>
          </div>
          <div className="content">
            <div className="route-info">
              <div className="from">
                <label>{t('From')}</label>
                <span>{from}</span>
              </div>
              <div className="arrow"><FaArrowRightLong /></div>
              <div className="to">
                <label>{t('To')}</label>
                <span>{to}</span>
              </div>
            </div>
            <div className="passenger-info">
              <label>{t('Passenger')}</label>
              <span>{`${passenger.firstName} ${passenger.lastName}`}</span>
            </div>
            <div className="flight-info">
              <div>
                <label>{t('Flight Number')}</label>
                <span>{flight.name}</span>
              </div>
              <div>
                <label>{t('Date')}</label>
                <span>{departureDate}</span>
              </div>
            </div>
            <div className="time-info">
              <div>
                <label>{t('Boarding')}</label>
                <span>{departureTime}</span>
              </div>
              <div>
                <label>{t('Gate')}</label>
                <span>{flight.gate || 'TBA'}</span>
              </div>
            </div>
            <div className="bottom-info">
              <div>
                <label>{t('Seat')}</label>
                <span>{passenger.seat}</span>
              </div>
              <div>
                <label>{t('Departing')}</label>
                <span>{departureTime}</span>
              </div>
            </div>
          </div>
          <div className="qr-code">
            <QRCodeSVG value={generateQRCodeData(flight, passenger, isReturn)} size={64} />
          </div>
        </div>
        <div className="tear-off">
          <div className="airline">Pleasuair Lines</div>
          <div >
            <label>{t('Your flight')}</label>
            <span>{flight.name}</span>
          </div>
          <div className="passenger-info">
            <div >
              <label>{t('Passenger')}</label>
              <span>{`${passenger.firstName} ${passenger.lastName}`}</span>
            </div>
            <div >
              <label>{t('Passport')}</label>
              <span>{`${passenger.passport}`}</span>
            </div>
          </div>
          <div className="seat-time">
            <div>
              <label>{t('Departing')}</label>
              <span>{departureTime}</span>
            </div>
            <div>
              <label>{t('Seat')}</label>
              <span>{passenger.seat}</span>
            </div>
          </div>
          <div className="qr-code">
            <QRCodeSVG value={generateQRCodeData(flight, passenger, isReturn)} size={64} />
          </div>
        </div>
      </div>
    );
  };

export default BoardingPass;