import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressTracker from './ProgressTracker';
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';

import { storage, auth, db } from '../../config/firebase';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { doc, getDoc } from 'firebase/firestore';
import { QRCodeCanvas } from 'qrcode.react';
import QRCode from 'qrcode';
import { FaDownload, FaPlane, FaUser, FaPassport, FaCalendar, FaVenusMars, FaGlobe, FaTicketAlt, FaChair, FaDollarSign, FaShieldAlt } from 'react-icons/fa';

import html2pdf from 'html2pdf.js';

import '../../styles/InvoiceComponent.css'

export default function InvoiceComponent() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(true);


  const { bookingData, selectedSeats, passengers, isRoundTrip } = location.state || {};
  const [selectedFlight, setSelectedFlight] = useState(bookingData?.selectedFlight || null);

  useEffect(() => {
    if (!selectedFlight && bookingData?.flightId) {
      const fetchFlightData = async () => {
        try {
          const flightDocRef = doc(db, 'flights', bookingData.flightId);
          const flightDoc = await getDoc(flightDocRef);
          if (flightDoc.exists()) {
            const flightData = flightDoc.data();
            setSelectedFlight(flightData);
          } else {
            console.error('Flight data not found');
          }
        } catch (error) {
          console.error('Error fetching flight data:', error);
        }
      };
      fetchFlightData();
    }
  }, [selectedFlight, bookingData]);

  
  const generateQRCodeData = (passenger, index, isReturn = false) => {
    const flight = bookingData?.selectedFlight || {};
    const ticket = bookingData?.tickets?.[index] || {};

    return `Passenger: ${passenger.firstName} ${passenger.lastName}\n` +
      `Date of Birth: ${passenger.dateOfBirth}\n` +
      `Passport: ${passenger.passport}\n` +
      `Passport Expiry Date: ${passenger.passportExpiryDate}\n` +
      `Nationality: ${passenger.nationality}\n` +
      `Gender: ${passenger.gender}\n` +
      `Class: ${ticket.className || 'N/A'}\n` +
      `Ticket No: ${ticket.ticketNo || 'N/A'}\n` +
      `Seat: ${selectedSeats?.[index] || 'N/A'}\n` +
      `Price: $${getTicketPrice(ticket.className)}\n` +
      `Flight ID: ${flight.id || 'N/A'}\n` +
      `Booking ID: ${bookingData?.bookingId || 'N/A'}\n` +
      `Class: ${ticket.className || 'N/A'}\n` +
      `Ticket No: ${ticket.ticketNo || 'N/A'}\n` +
      `Seat: ${selectedSeats?.[index] || 'N/A'}\n` +
      `Price: $${getTicketPrice(ticket.className)}\n` +
      `Flight ID: ${flight.id || 'N/A'}\n` +
      `Booking ID: ${bookingData?.bookingId || 'N/A'}\n` +
      `${isReturn ? 'Return Flight' : 'Outbound Flight'}`;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserEmail(userData.email);
          } else {
            setEmailError('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user email:', error);
          setEmailError(error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setEmailError('No authenticated user');
        setLoading(false);
        navigate('/auth');
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (loading || !selectedFlight) return;

    if (!bookingData || !passengers) {
      console.error('No booking data found, redirecting to flight booking page.');
      navigate('/flight-booking');
      return;
    }

    if (!userEmail) {
      console.log('User email not yet fetched, waiting...');
      return;
    }

    const sendEmail = async () => {
      try {
        const passengersData = await Promise.all(passengers.map(async (passenger, index) => {
          const outboundQRCodeContent = generateQRCodeData(passenger, index);
          const outboundQRCodeDataUrl = await QRCode.toDataURL(outboundQRCodeContent);
          const outboundQRStorageRef = ref(storage, `qr_codes/${bookingData.bookingId}_outbound_${index}.png`);
          await uploadString(outboundQRStorageRef, outboundQRCodeDataUrl, 'data_url');
          const outboundQRCodeUrl = await getDownloadURL(outboundQRStorageRef);

          let returnQRCodeUrl = null;
          if (isRoundTrip) {
            const returnQRCodeContent = generateQRCodeData(passenger, index, true);
            const returnQRCodeDataUrl = await QRCode.toDataURL(returnQRCodeContent);
            const returnQRStorageRef = ref(storage, `qr_codes/${bookingData.bookingId}_return_${index}.png`);
            await uploadString(returnQRStorageRef, returnQRCodeDataUrl, 'data_url');
            returnQRCodeUrl = await getDownloadURL(returnQRStorageRef);
          }
          
          return {
            username: bookingData.username || auth.currentUser?.displayName || t('N/A'),
            flightId: bookingData.selectedFlight?.id || t('N/A'),
            username: bookingData.username || auth.currentUser?.displayName || t('N/A'),
            flightId: bookingData.selectedFlight?.id || t('N/A'),
            bookingId: bookingData.bookingId || t('N/A'),
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            dateOfBirth: passenger.dateOfBirth,
            passport: passenger.passport,
            passportExpiryDate: passenger.passportExpiryDate,
            nationality: passenger.nationality,
            gender: passenger.gender,
            className: bookingData.tickets?.[index]?.className || t('N/A'),
            ticketNo: bookingData.tickets?.[index]?.ticketNo || t('N/A'),
            seat: selectedSeats?.[index] || t('N/A'),
            ticketPrice: getTicketPrice(bookingData.tickets?.[index]?.className),
            className: bookingData.tickets?.[index]?.className || t('N/A'),
            ticketNo: bookingData.tickets?.[index]?.ticketNo || t('N/A'),
            seat: selectedSeats?.[index] || t('N/A'),
            ticketPrice: getTicketPrice(bookingData.tickets?.[index]?.className),
            outboundQRCode: outboundQRCodeUrl,
            returnQRCode: returnQRCodeUrl
          };
        }));

        const emailData = {
          to_name: bookingData.username,
          to_email: userEmail,
          flight_id: bookingData.selectedFlight?.id || t('N/A'),
          flight_id: bookingData.selectedFlight?.id || t('N/A'),
          booking_id: bookingData.bookingId || t('N/A'),
          total_cost: bookingData.totalCost != null ? bookingData.totalCost.toFixed(2) : t('N/A'),
          passengers: passengersData,
          is_round_trip: isRoundTrip,
          include_insurance: bookingData.includeInsurance
        };

        const response = await emailjs.send(
          'bla', //service_az1imc9
          'template_weq4kig',
          emailData,
          'D3uhPhYDWFwD3TKPH'
        );
        console.log('Email sent successfully:', response.status, response.text);
        setEmailSent(true);
      } catch (error) {
        console.error('Error sending email:', error);
        setEmailError(error.message);
      }
    };

    sendEmail();
  }, [bookingData, passengers, selectedSeats, t, navigate, userEmail, isRoundTrip, selectedFlight]);

  const getTicketPrice = (ticketClass) => {
    if (!bookingData) return 0;
    if (!bookingData) return 0;
    switch (ticketClass) {
      case t('Economy'):
        return bookingData.economyClassTicketCost || 0;
      case t('Business'):
        return bookingData.businessClassTicketCost || 0;
      case t('First'):
        return bookingData.firstClassTicketCost || 0;
      default:
        return 0;
    }
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('invoice-content');
    const opt = {
      margin: 10,
      filename: `flight-booking-receipt-${bookingData?.bookingId || 'unknown'}.pdf`,
      filename: `flight-booking-receipt-${bookingData?.bookingId || 'unknown'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save();
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!bookingData || !passengers || !userEmail) {
    return <div className="error">Error: Missing booking data or user information.</div>;
  }

  return (
    <>
      <ProgressTracker currentStep={5} />
      <div className="invoice-page">
      <div className="invoice-container" id="invoice-content">
        <div className="invoice-header">
          <h1>{t('Flight Booking Receipt')}</h1>
          <button onClick={handleDownloadPDF} className="download-pdf-button">
            <FaDownload className="icon" />
            {t('Download PDF')}
          </button>
        </div>

        <div className="booking-details">
          <h2>{t('Booking Details')}</h2>
          <p><FaUser className="icon" /> <strong>{t('flight booker name')}:</strong> {bookingData.username || 'N/A'}</p>
          <p><FaTicketAlt className="icon" /> <strong>{t('Booking ID')}:</strong> {bookingData.bookingId || 'N/A'}</p>
          <p><FaPlane className="icon" /> <strong>{t('Trip Type')}:</strong> {isRoundTrip ? t('Round Trip') : t('One Way')}</p>
          <p><FaShieldAlt className="icon" /> <strong>{t('Insurance')}:</strong> {bookingData.includeInsurance ? t('Included') : t('Not Included')}</p>
          {bookingData.includeInsurance && (
            <p className="insurance-cost">
              <FaShieldAlt className="icon" />
              <strong>{t('Insurance Cost')}:</strong> ${bookingData.insuranceCost || 'N/A'}
            </p>
          )}
          <p className="total-cost">
            <FaDollarSign className="icon" />
            <strong>{t('Total Cost')}: </strong>
             {bookingData.totalCost != null ? parseFloat(bookingData.totalCost).toFixed(2) : 'N/A'}
          </p>
        </div>

        <div className="passenger-details">
          <h2>{t('Passenger Details')}</h2>
          {passengers.map((passenger, index) => (
            <div key={index} className="passenger-card">
              <h3>{passenger.firstName} {passenger.lastName}</h3>
              <p><FaCalendar className="icon" /> <strong>{t('Date of Birth')}:</strong> {passenger.dateOfBirth}</p>
              <p><FaPassport className="icon" /> <strong>{t('Passport')}:</strong> {passenger.passport}</p>
              <p><FaCalendar className="icon" /> <strong>{t('Passport Expiry Date')}:</strong> {passenger.passportExpiryDate}</p>
              <p><FaGlobe className="icon" /> <strong>{t('Nationality')}:</strong> {passenger.nationality}</p>
              <p>
                <FaVenusMars className="icon" /> 
                <strong>{t('Gender')}:</strong> {passenger.gender === 'Female' ? t('Female') : t('Male')}
              </p>
            </div>
          ))}
        </div>

        <div className="flight-tickets">
          <h2>{t('Flight Tickets')}</h2>
          {passengers.map((passenger, index) => (
            <div key={index} className="ticket">
              <div className="ticket-header">
                <h3>{t('Outbound Flight')}</h3>
                <p><FaPlane className="icon" /> {bookingData.selectedFlight?.id || 'N/A'}</p>
              </div>
              <div className="ticket-body">
                <div className="ticket-info">
                <p><strong>{t('From')}:</strong> {selectedFlight?.routeSource || 'N/A'}</p>
                <p><strong>{t('To')}:</strong> {selectedFlight?.routeDestination || 'N/A'}</p>
                <p><strong>{t('Date')}:</strong> {selectedFlight?.departureDate || 'N/A'}</p>
                <p><strong>{t('Time')}:</strong> {selectedFlight?.departureTime || 'N/A'}</p>
                <p><strong>{t('Passenger')}:</strong> {passenger.firstName} {passenger.lastName}</p>
                <p><strong>{t('Class')}:</strong> {bookingData.tickets?.[index]?.className || 'N/A'}</p>
                <p><strong>{t('Seat')}:</strong> {selectedSeats?.[index] || 'N/A'}</p>
                </div>
                <div className="ticket-qr">
                  <QRCodeCanvas value={generateQRCodeData(passenger, index)} size={100} />
                </div>
              </div>
            </div>
          ))}
          
          {isRoundTrip && passengers.map((passenger, index) => (
            <div key={`return-${index}`} className="ticket">
              <div className="ticket-header">
                <h3>{t('Return Flight')}</h3>
                <p><FaPlane className="icon" /> {bookingData.selectedFlight?.id || 'N/A'}</p>
              </div>
              <div className="ticket-body">
                <div className="ticket-info">
                  <p><strong>{t('From')}:</strong> {selectedFlight?.routeDestination || 'N/A'}</p>
                  <p><strong>{t('To')}:</strong> {selectedFlight?.routeSource || 'N/A'}</p>
                  <p><strong>{t('Date')}:</strong> {selectedFlight?.returnDate || 'N/A'}</p>
                  <p><strong>{t('Time')}:</strong> {selectedFlight?.returnTime || 'N/A'}</p>
                  <p><strong>{t('Passenger')}:</strong> {passenger.firstName} {passenger.lastName}</p>
                  <p><strong>{t('Class')}:</strong> {bookingData.tickets?.[index]?.className || 'N/A'}</p>
                  <p><strong>{t('Seat')}:</strong> {selectedSeats?.[index] || 'N/A'}</p>
                </div>
                <div className="ticket-qr">
                  <QRCodeCanvas value={generateQRCodeData(passenger, index, true)} size={100} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="invoice-footer">
          <p>{t('THANK YOU FOR BOOKING')}</p>
        </div>
      </div>
    </div>
    </>
  );
};
