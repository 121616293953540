import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FaScroll, FaUserShield, FaLock, FaCreditCard, FaUndoAlt, FaShieldAlt, FaEdit } from 'react-icons/fa';
import '../../styles/TermsOfUse.css';

const TermsOfUse = () => {
  const { t } = useTranslation();

  const sections = [
    { title: t('1. Acceptance of Terms'), content: t('By accessing and using PLEASUAIR services, you agree to be bound by these Terms of Use.'), icon: <FaScroll /> },
    { title: t('2. Use of Services'), content: t('Our services are designed for personal, non-commercial use. You agree not to misuse our services or help anyone else do so.'), icon: <FaUserShield /> },
    { title: t('3. Privacy'), content: t('Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect and use your information.'), icon: <FaLock /> },
    { title: t('4. Bookings and Payments'), content: t('All bookings are subject to availability. Payment is required at the time of booking unless otherwise specified.'), icon: <FaCreditCard /> },
    { title: t('5. Cancellations and Refunds'), content: t('Cancellation policies vary depending on the type of service booked. Please refer to the specific terms for each booking.'), icon: <FaUndoAlt /> },
    { title: t('6. Liability'), content: t('PLEASUAIR is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.'), icon: <FaShieldAlt /> },
    { title: t('7. Changes to Terms'), content: t('We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the modified terms.'), icon: <FaEdit /> },
  ];

  return (
    <div className="terms-of-use">
      <motion.header 
        className="terms-header"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1>{t('Terms of Use')}</h1>
        <p>{t('PLEASUAIR - Luxury in Every Journey')}</p>
      </motion.header>
      <main className="terms-content">
        {sections.map((section, index) => (
          <motion.section 
            key={index}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div className="section-icon">{section.icon}</div>
            <div className="section-text">
              <h2>{section.title}</h2>
              <p>{section.content}</p>
            </div>
          </motion.section>
        ))}
      </main>
      <motion.footer 
        className="terms-footer"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <p>{t('Last updated: August 2024')}</p>
        <p>{t('© 2024 PLEASUAIR. All rights reserved.')}</p>
      </motion.footer>
    </div>
  );
};

export default TermsOfUse;