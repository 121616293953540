import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, addDoc, query, where, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import '../../styles/AddFlight.css';

function AddFlight() {
  const [insurancePrice, setInsurancePrice] = useState('');
  const { t } = useTranslation();
  const [flightName, setFlightName] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [duration, setDuration] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [returnTime, setReturnTime] = useState('');
  const [airplaneType, setAirplaneType] = useState('');
  const [economyClassTicketPrice, setEconomyClassTicketPrice] = useState('');
  const [businessClassTicketPrice, setBusinessClassTicketPrice] = useState('');
  const [firstClassTicketPrice, setFirstClassTicketPrice] = useState('');
  const [airplaneTypes, setAirplaneTypes] = useState([]);
  const [customOrigin, setCustomOrigin] = useState('');
  const [customDestination, setCustomDestination] = useState('');
  const [countries, setCountries] = useState([]);
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [includeBus, setIncludeBus] = useState(false);
  const [busPrice, setBusPrice] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      const airplaneTypesCollection = collection(db, 'airplaneTypes');
      const airplaneTypesSnapshot = await getDocs(airplaneTypesCollection);
      const airplaneTypesList = airplaneTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAirplaneTypes(airplaneTypesList);

      const countriesCollection = collection(db, 'countries');
      const countriesSnapshot = await getDocs(countriesCollection);
      const countriesList = countriesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      setCountries([ ...countriesList.map(c => c.name)]);
    };

    fetchData();
  }, []);

  const handleAirplaneTypeChange = (e) => {
    const selectedType = e.target.value;
    setAirplaneType(selectedType);
  };

  const saveCustomCountry = async (countryName) => {
    const countriesCollection = collection(db, 'countries');
    const q = query(countriesCollection, where("name", "==", countryName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      await addDoc(countriesCollection, { name: countryName });
      setCountries(prevCountries => [...prevCountries, countryName]);
    }
  };

  // const deleteCountry = async (countryName) => {
  //   try {
  //     const countriesCollection = collection(db, 'countries');
  //     const q = query(countriesCollection, where("name", "==", countryName));
  //     const querySnapshot = await getDocs(q);

  //     if (!querySnapshot.empty) {
  //       const docToDelete = querySnapshot.docs[0];
  //       await deleteDoc(doc(db, 'countries', docToDelete.id));
  //     }

  //     setCountries(prevCountries => prevCountries.filter(country => country !== countryName));
  //     Swal.fire({
  //       title: t('Deleted!'),
  //       text: t('Country has been deleted.'),
  //       icon: 'success',
  //       timer: 3000, // 3 seconds
  //       showConfirmButton: false
  //     });      
  //   } catch (error) {
  //     console.error("Error deleting country:", error);
  //     Swal.fire(t('Error'), t('Error deleting country'), 'error');
  //   }
  // };

  const deleteCountry = async (countryName) => {
    try {
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes, delete it!')
      });
  
      if (result.isConfirmed) {
        const countriesCollection = collection(db, 'countries');
        const q = query(countriesCollection, where("name", "==", countryName));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const docToDelete = querySnapshot.docs[0];
          await deleteDoc(doc(db, 'countries', docToDelete.id));
        }
  
        setCountries(prevCountries => prevCountries.filter(country => country !== countryName));
        Swal.fire({
          title: t('Deleted!'),
          text: t('Country has been deleted.'),
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        });
      }
    } catch (error) {
      console.error("Error deleting country:", error);
      Swal.fire(t('Error'), t('Error deleting country'), 'error');
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const finalOrigin = origin === 'custom' ? customOrigin : origin;
    const finalDestination = destination === 'custom' ? customDestination : destination;

    if (finalOrigin === finalDestination) {
      Swal.fire({
        icon: 'warning',
        title: t('Warning'),
        text: t('Origin and destination cannot be the same'),
        confirmButtonText: t('OK')
      });
      setIsLoading(false);
      return;
    }

    if (origin === 'custom') {
      await saveCustomCountry(customOrigin);
    }

    if (destination === 'custom') {
      await saveCustomCountry(customDestination);
    }

    const selectedAirplane = airplaneTypes.find(type => type.id === airplaneType);

    const economyClassTotalSeats = selectedAirplane.economyClass.rows * selectedAirplane.economyClass.seatsPerRow;
    const businessClassTotalSeats = selectedAirplane.businessClass.rows * selectedAirplane.businessClass.seatsPerRow;
    const firstClassTotalSeats = selectedAirplane.firstClass.rows * selectedAirplane.firstClass.seatsPerRow;

    const classTypes = [];
    if (economyClassTotalSeats > 0) classTypes.push('Economy Class');
    if (businessClassTotalSeats > 0) classTypes.push('Business Class');
    if (firstClassTotalSeats > 0) classTypes.push('First Class');

    const newFlightObject = {
      name: flightName,
      routeSource: finalOrigin,
      routeDestination: finalDestination,
      flightDuration: duration,
      departureDate,
      departureTime,
      isRoundTrip,
      returnDate: isRoundTrip ? returnDate : null,
      returnTime: isRoundTrip ? returnTime : null,
      airplaneType: selectedAirplane.name,
      economyClassTotalSeats: economyClassTotalSeats,
      economyClassRemainingSeats: economyClassTotalSeats,
      economyClassTicketCost: parseInt(economyClassTicketPrice, 10),
      businessClassTotalSeats: businessClassTotalSeats,
      businessClassRemainingSeats: businessClassTotalSeats,
      businessClassTicketCost: parseInt(businessClassTicketPrice, 10),
      firstClassTotalSeats: firstClassTotalSeats,
      firstClassRemainingSeats: firstClassTotalSeats,
      firstClassTicketCost: parseInt(firstClassTicketPrice, 10),
      insurancePrice: parseInt(insurancePrice, 10),
      includeBus,
      busPrice: includeBus ? parseInt(busPrice, 10) : null,
    };

    try {
      const flightRef = doc(collection(db, "flights"));
      newFlightObject.id = flightRef.id;

      await setDoc(flightRef, newFlightObject);

      Swal.fire({
        title: t('Added!'),
        text: t('Flight added successfully.'),
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
      
      // Reset form fields
      setFlightName('');
      setOrigin('');
      setDestination('');
      setCustomOrigin('');
      setCustomDestination('');
      setDuration('');
      setDepartureDate('');
      setDepartureTime('');
      setReturnDate('');
      setReturnTime('');
      setAirplaneType('');
      setEconomyClassTicketPrice('');
      setBusinessClassTicketPrice('');
      setFirstClassTicketPrice('');
      setIsRoundTrip(false);
      setIsLoading(false);
      setIncludeBus(false);
      setBusPrice('');
    } catch (e) {
      console.error(t("Error adding document:"), e);
      Swal.fire(t('Error'), t('Error adding flight'), 'error');
      setIsLoading(false);
    } finally {
      setIsLoading(false); 
    }
  };


  return (
    <div className="add-flight-container">
      <form onSubmit={handleFormSubmit} className="add-flight-form">
        <h1 className="form-title">{t('Add New Flight')}</h1>

        <div className="form-section">
          <h2 className="section-title">{t('General Details')}</h2>
          <div className="input-group origin-destination">
            <div className="origin">
              <label htmlFor="origin">{t('Origin')}</label>
              <select
                id="origin"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                required
              >
                <option value="">{t('Choose Origin')}</option>
                <option value="custom">{t('Other (specify)')}</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {origin === 'custom' && (
                <input
                  type="text"
                  value={customOrigin}
                  onChange={(e) => setCustomOrigin(e.target.value)}
                  placeholder={t('Enter custom origin')}
                  required
                />
              )}
            </div>
            <div className="destination">
              <label htmlFor="destination">{t('Destination')}</label>
              <select
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                required
              >
                <option value="">{t('Choose Destination')}</option>
                <option value="custom">{t('Other (specify)')}</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {destination === 'custom' && (
                <input
                  type="text"
                  value={customDestination}
                  onChange={(e) => setCustomDestination(e.target.value)}
                  placeholder={t('Enter custom destination')}
                  required
                />
              )}
            </div>
          </div>

          <div className="input-group">
            <label>{t('Countries')}</label>
            <ul className="countries-list">
              {countries.map((country, index) => (
                <li key={index} className="country-item">
                  {country}
                  <button
                    type="button"
                    className="delete-country"
                    onClick={() => deleteCountry(country)}
                  >
                    <FaTimes />
                    <span className="visually-hidden">{t('Delete')} {country}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="input-group duration">
            <label htmlFor="duration">{t('Duration (in Hrs)')}</label>
            <input
              type="text"
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>

            <div className="form-row flight-type-toggle">
              <div className="form-group">
                <label>{t('Trip Type')}</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="roundTrip"
                    name="tripType"
                    checked={isRoundTrip}
                    onChange={() => setIsRoundTrip(true)}
                  />
                  <label htmlFor="roundTrip">{t('Round Trip')}</label>
                </div>
              </div>
              
              <div className="form-group">
                <label>&nbsp;</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="oneWay"
                    name="tripType"
                    checked={!isRoundTrip}
                    onChange={() => setIsRoundTrip(false)}
                  />
                  <label htmlFor="oneWay">{t('One Way Trip')}</label>
                </div>
              </div>
            </div>

          <div className="input-group date-time-group">
            <div className="date-input">
              <label htmlFor="departureDate">{t('Departure Date')}</label>
              <input
                type="date"
                id="departureDate"
                value={departureDate}
                onChange={(e) => setDepartureDate(e.target.value)}
                required
              />
            </div>
            <div className="time-input">
              <label htmlFor="departureTime">{t('Departure Time')}</label>
              <input
                type="time"
                id="departureTime"
                value={departureTime}
                onChange={(e) => setDepartureTime(e.target.value)}
                required
              />
            </div>
          </div>

          {isRoundTrip && (
            <div className="input-group date-time-group">
              <div className="date-input">
                <label htmlFor="returnDate">{t('Return Date')}</label>
                <input
                  type="date"
                  id="returnDate"
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                  required
                />
              </div>
              <div className="time-input">
                <label htmlFor="returnTime">{t('Return Time')}</label>
                <input
                  type="time"
                  id="returnTime"
                  value={returnTime}
                  onChange={(e) => setReturnTime(e.target.value)}
                  required
                />
              </div>
            </div>
          )}

          <div className="input-group">
            <label htmlFor="flightName">{t('Flight Name')}</label>
            <input
              type="text"
              id="flightName"
              value={flightName}
              onChange={(e) => setFlightName(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="airplaneType">{t('Airplane Type')}</label>
            <select
              id="airplaneType"
              value={airplaneType}
              onChange={handleAirplaneTypeChange}
              required
            >
              <option value="">{t('Select Airplane Type')}</option>
              {airplaneTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-section">
  <h2 className="section-title">{t('Bus Options')}</h2>
  <div className="input-group">
    <label htmlFor="includeBus">{t('Include Bus Service')}</label>
    <input
      type="checkbox"
      id="includeBus"
      checked={includeBus}
      onChange={(e) => setIncludeBus(e.target.checked)}
    />
  </div>
  {includeBus && (
    <div className="input-group">
      <label htmlFor="busPrice">{t('Bus Price')}</label>
      <input
        type="number"
        id="busPrice"
        value={busPrice}
        onChange={(e) => setBusPrice(e.target.value)}
        required={includeBus}
      />
    </div>
  )}
</div>

        {airplaneType && (
          <div className="form-section">
            <h2 className="section-title">{t('Class Details')}</h2>
            <div className="class-details">
              <div className="input-group">
                <label>{t('Economy Class')}</label>
                <p>{t('Rows')}: {airplaneTypes.find(type => type.id === airplaneType).economyClass.rows}, {t('Seats per Row')}: {airplaneTypes.find(type => type.id === airplaneType).economyClass.seatsPerRow}</p>
                <input
                  type="number"
                  placeholder={t('Economy Class Ticket Price')}
                  value={economyClassTicketPrice}
                  onChange={(e) => setEconomyClassTicketPrice(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label>{t('Business Class')}</label>
                <p>{t('Rows')}: {airplaneTypes.find(type => type.id === airplaneType).businessClass.rows}, {t('Seats per Row')}: {airplaneTypes.find(type => type.id === airplaneType).businessClass.seatsPerRow}</p>
                <input
                  type="number"
                  placeholder={t('Business Class Ticket Price')}
                  value={businessClassTicketPrice}
                  onChange={(e) => setBusinessClassTicketPrice(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label>{t('First Class')}</label>
                <p>{t('Rows')}: {airplaneTypes.find(type => type.id === airplaneType).firstClass.rows}, {t('Seats per Row')}: {airplaneTypes.find(type => type.id === airplaneType).firstClass.seatsPerRow}</p>
                <input
                  type="number"
                  placeholder={t('First Class Ticket Price')}
                  value={firstClassTicketPrice}
                  onChange={(e) => setFirstClassTicketPrice(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        )}

        <div className="input-group">
          <label htmlFor="insurancePrice">{t('Insurance Price')}</label>
          <input
            type="number"
            id="insurancePrice"
            value={insurancePrice}
            onChange={(e) => setInsurancePrice(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? t('Adding...') : t('Add Flight')}
        </button>
      </form>
    </div>
  );
}

export default AddFlight;