import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../config/firebase'; 
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import '../../styles/SavedFlights.css';

const SavedFlights = () => {
  const { t } = useTranslation();
  const [savedFlights, setSavedFlights] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSavedFlights = async () => {
      const user = auth.currentUser;
      if (user) {
        const savedFlightsCollection = collection(db, 'users', user.uid, 'savedFlights');
        const savedFlightsSnapshot = await getDocs(savedFlightsCollection);
        const savedFlightsData = savedFlightsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Check if each saved flight still exists in the 'flights' collection
        const existingFlights = await Promise.all(
          savedFlightsData.map(async (flight) => {
            const flightRef = doc(db, 'flights', flight.id);
            const flightDoc = await getDoc(flightRef);
            return flightDoc.exists() ? flight : null;
          })
        );

        // Filter out null values (flights that no longer exist)
        setSavedFlights(existingFlights.filter(flight => flight !== null));
      }
    };

    fetchSavedFlights();
  }, []);

  const handleRemoveSavedFlight = async (flightId) => {
    const result = await Swal.fire({
      title: t('Are you sure?'),
      text: t('Do you really want to remove this flight from your saved flights?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Yes, remove it!')
    });
  
    if (result.isConfirmed) {
      try {
        const user = auth.currentUser;
        if (user) {
          await deleteDoc(doc(db, 'users', user.uid, 'savedFlights', flightId));
          setSavedFlights(savedFlights.filter(flight => flight.id !== flightId));
          Swal.fire(t('Removed!'), t('The flight has been removed from your saved flights.'), 'success');
        }
      } catch (error) {
        console.error('Error removing saved flight:', error);
        Swal.fire(t('Error!'), t('There was an issue removing the flight. Please try again later.'), 'error');
      }
    }
  };

  const showDetails = (flight) => {
    navigate('/flight-details', { state: { selectedFlight: flight } });
  };

  return (
    <div className="saved-flights-container">
      <h2 className="section-title">{t('Saved Flights')}</h2>
      {savedFlights.length === 0 ? (
        <p className="no-flights">{t('You have no saved flights')}</p>
      ) : (
        <div className="flights-grid">
          {savedFlights.map((flight, index) => (
            <div className="flight-card" key={index}>
              <h3 className="flight-name">{flight.name}</h3>
              <div className="flight-details">
                <p><strong>{t('From')}:</strong> {flight.routeSource}</p>
                <p><strong>{t('To')}:</strong> {flight.routeDestination}</p>
                <p><strong>{t('Date')}:</strong> {flight.departureDate}</p>
                <p><strong>{t('Time')}:</strong> {flight.departureTime}</p>
              </div>
              <button className="details-btn" onClick={() => showDetails(flight)}>{t('Details')}</button>
              <button 
                className="remove-btn" 
                onClick={() => handleRemoveSavedFlight(flight.id)}
              >
                {t('Remove Flight')}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedFlights;