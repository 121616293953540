// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { FaTimes } from 'react-icons/fa';
// import '../../styles/galleryImages.css';

// const galleryImages = [
//   { src: 'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MjAwMTUwMjI4Mzc1NDQ2NjM2/disadvantages-of-travelling-by-plane.webp', alt: 'Luxury airplane interior' },
//   { src: 'https://images.unsplash.com/photo-1587019158091-1a103c5dd17f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'First-class seating' },
//   { src: 'https://images.unsplash.com/photo-1519666336592-e225a99dcd2f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Gourmet in-flight meal' },
//   { src: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Scenic aerial view' },
//   { src: 'https://cdn.britannica.com/69/155469-131-14083F59/airplane-flight.jpg?w=1000', alt: 'VIP lounge' },
//   { src: 'https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Sunset view from airplane window' },
// ];

// const partnerInfo = [
//   { name: 'SkyHigh Airlines', logo: 'https://logo.clearbit.com/delta.com', description: 'Premium airline partner for international flights' },
//   { name: 'LuxeStay Hotels', logo: 'https://logo.clearbit.com/hilton.com', description: 'Luxury accommodations for our esteemed passengers' },
//   { name: 'SwiftJet Services', logo: 'https://logo.clearbit.com/swissport.com', description: 'Efficient ground handling and logistics support' },
//   { name: 'GourmetSky Catering', logo: 'https://logo.clearbit.com/gategroup.com', description: 'Exquisite in-flight dining experiences' },
// ];

// const officeLocations = [
//   { city: 'New York', image: 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '123 Sky Tower, Manhattan, NY 10001' },
//   { city: 'London', image: 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '456 Cloud Street, Westminster, London SW1A 1AA' },
//   { city: 'Tokyo', image: 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '789 Air Avenue, Minato City, Tokyo 105-0001' },
// ];

// function ImageGallery() {
//   const [selectedImage, setSelectedImage] = useState(null);

//   return (
//     <section className="pleasuair-gallery image-gallery">
//       <h2 className="title">Experience Luxury in the Air</h2>
//       <motion.div 
//         className="gallery-grid"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 0.5 }}
//       >
//         {galleryImages.map((image, index) => (
//           <motion.div
//             key={index}
//             className="gallery-item"
//             whileHover={{ scale: 1.05 }}
//             onClick={() => setSelectedImage(image)}
//           >
//             <img
//               src={image.src}
//               alt={image.alt}
//               className="gallery-image"
//             />
//             <div className="image-overlay">
//               <p>{image.alt}</p>
//             </div>
//           </motion.div>
//         ))}
//       </motion.div>

//       <AnimatePresence>
//         {selectedImage && (
//           <motion.div 
//             className="modal" 
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             onClick={() => setSelectedImage(null)}
//           >
//             <motion.div 
//               className="modal-content"
//               initial={{ scale: 0.8 }}
//               animate={{ scale: 1 }}
//               exit={{ scale: 0.8 }}
//             >
//               <button
//                 className="close-button"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   setSelectedImage(null);
//                 }}
//                 aria-label="Close image"
//               >
//                 <FaTimes />
//               </button>
//               <img
//                 src={selectedImage.src}
//                 alt={selectedImage.alt}
//                 className="modal-image"
//               />
//             </motion.div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </section>
//   );
// }

// function PartnerSection() {
//   return (
//     <section className="pleasuair-partners partner-section">
//       <h2 className="title">Our Trusted Partners</h2>
//       <motion.div 
//         className="partner-grid"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 0.5 }}
//       >
//         {partnerInfo.map((partner, index) => (
//           <motion.div 
//             key={index} 
//             className="partner-item"
//             initial={{ y: 50, opacity: 0 }}
//             animate={{ y: 0, opacity: 1 }}
//             transition={{ delay: index * 0.1 }}
//           >
//             <img src={partner.logo} alt={`${partner.name} logo`} className="partner-logo" />
//             <h3 className="partner-name">{partner.name}</h3>
//             <p className="partner-description">{partner.description}</p>
//           </motion.div>
//         ))}
//       </motion.div>
//     </section>
//   );
// }

// function OfficeLocations() {
//   return (
//     <section className="pleasuair-offices office-section">
//       <h2 className="title">Our Global Offices</h2>
//       <motion.div 
//         className="office-grid"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 0.5 }}
//       >
//         {officeLocations.map((office, index) => (
//           <motion.div 
//             key={index} 
//             className="office-item"
//             initial={{ y: 50, opacity: 0 }}
//             animate={{ y: 0, opacity: 1 }}
//             transition={{ delay: index * 0.1 }}
//           >
//             <img src={office.image} alt={`${office.city} office`} className="office-image" />
//             <div className="office-info">
//               <h3 className="office-city">{office.city}</h3>
//               <p className="office-address">{office.address}</p>
//             </div>
//           </motion.div>
//         ))}
//       </motion.div>
//     </section>
//   );
// }

// export { PartnerSection, OfficeLocations };
// export default ImageGallery;



import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../../styles/galleryImages.css';

const galleryImages = [
  { src: 'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MjAwMTUwMjI4Mzc1NDQ2NjM2/disadvantages-of-travelling-by-plane.webp', alt: 'Luxury airplane interior' },
  { src: 'https://images.unsplash.com/photo-1587019158091-1a103c5dd17f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'First-class seating' },
  { src: 'https://images.unsplash.com/photo-1519666336592-e225a99dcd2f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Gourmet in-flight meal' },
  { src: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Scenic aerial view' },
  { src: 'https://cdn.britannica.com/69/155469-131-14083F59/airplane-flight.jpg?w=1000', alt: 'VIP lounge' },
  { src: 'https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', alt: 'Sunset view from airplane window' },
];

const partnerInfo = [
  { name: 'SkyHigh Airlines', logo: 'https://logo.clearbit.com/delta.com', description: 'Premium airline partner for international flights' },
  { name: 'LuxeStay Hotels', logo: 'https://logo.clearbit.com/hilton.com', description: 'Luxury accommodations for our esteemed passengers' },
  { name: 'SwiftJet Services', logo: 'https://logo.clearbit.com/swissport.com', description: 'Efficient ground handling and logistics support' },
  { name: 'GourmetSky Catering', logo: 'https://logo.clearbit.com/gategroup.com', description: 'Exquisite in-flight dining experiences' },
];

const officeLocations = [
  { city: 'New York', image: 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '123 Sky Tower, Manhattan, NY 10001' },
  { city: 'London', image: 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '456 Cloud Street, Westminster, London SW1A 1AA' },
  { city: 'Tokyo', image: 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', address: '789 Air Avenue, Minato City, Tokyo 105-0001' },
];

function ImageGallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();

  return (
    <div className="gallery-page">
      <section className="pleasuair-gallery image-gallery">
        <h2 className="title">{t('Experience Luxury in the Air')}</h2>
        <motion.div 
          className="gallery-grid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              className="gallery-item"
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedImage(image)}
            >
              <img
                src={image.src}
                alt={t(image.alt)}
                className="gallery-image"
              />
              <div className="image-overlay">
                <p>{t(image.alt)}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {selectedImage && (
            <motion.div 
              className="modal" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedImage(null)}
            >
              <motion.div 
                className="modal-content"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
              >
                <button
                  className="close-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImage(null);
                  }}
                  aria-label={t('Close image')}
                >
                  <FaTimes />
                </button>
                <img
                  src={selectedImage.src}
                  alt={t(selectedImage.alt)}
                  className="modal-image"
                />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </div>
  );
}

function PartnerSection() {
  const { t } = useTranslation();
  
  return (
    <div className="gallery-page">
      <section className="pleasuair-partners partner-section">
        <h2 className="title">{t('Our Trusted Partners')}</h2>
        <motion.div 
          className="partner-grid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {partnerInfo.map((partner, index) => (
            <motion.div 
              key={index} 
              className="partner-item"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
            >
              <img src={partner.logo} alt={t(`${partner.name} logo`)} className="partner-logo" />
              <h3 className="partner-name">{t(partner.name)}</h3>
              <p className="partner-description">{t(partner.description)}</p>
            </motion.div>
          ))}
        </motion.div>
      </section>
    </div>
  );
}

function OfficeLocations() {
  const { t } = useTranslation();

  return (
    <div className="gallery-page">
      <section className="pleasuair-offices office-section">
        <h2 className="title">{t('Our Global Offices')}</h2>
        <motion.div 
          className="office-grid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {officeLocations.map((office, index) => (
            <motion.div 
              key={index} 
              className="office-item"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
            >
              <img src={office.image} alt={t(`${office.city} office`)} className="office-image" />
              <div className="office-info">
                <h3 className="office-city">{t(office.city)}</h3>
                <p className="office-address">{t(office.address)}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </section>
    </div>
  );
}

export { PartnerSection, OfficeLocations };
export default ImageGallery;
