// import React, { useState, useEffect, useMemo } from 'react';
// import ReactDOMServer from 'react-dom/server';
// import { collection, getDocs, onSnapshot, query, where, deleteDoc, doc, updateDoc, arrayRemove } from 'firebase/firestore';
// import { db } from '../../config/firebase';
// import { useTranslation } from 'react-i18next';
// import { FaIdCard, FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaChair, FaDollarSign, FaTicketAlt, FaBirthdayCake, FaFlag, FaPassport, FaCalendarAlt, FaVenusMars, FaPlane, FaClock, FaCouch, FaSearch, FaCalendar } from 'react-icons/fa';
// import Swal from 'sweetalert2';
// import html2pdf from 'html2pdf.js';
// import '../../styles/ManageBookings.css';
// import logo from '../../assets/logos/logo PleasuAir.png';


// // import the outside pages
// import SeatMap from './SeatMap';
// import PassengerList from './PassengerList';
// import BoardingPass from './BoardingPass';
// // import RenderBoardingPass from './RenderBoardingPass';


// const ManageBookings = () => {
//   const { t } = useTranslation();
//   const [flights, setFlights] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [openSections, setOpenSections] = useState({});
//   const [searchTerms, setSearchTerms] = useState({});
//   const [globalSearchTerm, setGlobalSearchTerm] = useState('');


//   useEffect(() => {
//     let isMounted = true;
  
//     const handleDataUpdate = (flightsList, bookingsList) => {
//       const bookingsByFlightId = bookingsList.reduce((acc, booking) => {
//         const flightId = booking.flightId;
//         if (!acc[flightId]) {
//           acc[flightId] = [];
//         }
//         acc[flightId].push(booking);
//         return acc;
//       }, {});
  
//       const flightsWithBookings = flightsList.map(flight => ({
//         ...flight,
//         bookings: bookingsByFlightId[flight.id] || []
//       }));
  
//       if (isMounted) {
//         setFlights(flightsWithBookings);
//       }
//     };
  
//     let flightsUnsubscribe;
//     let bookingsUnsubscribe;
  
//     const fetchData = async () => {
//       try {
//         flightsUnsubscribe = onSnapshot(collection(db, 'flights'), (flightsSnapshot) => {
//           const flightsList = flightsSnapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data(),
//             bookings: []
//           }));
  
//           if (bookingsCache) {
//             handleDataUpdate(flightsList, bookingsCache);
//           } else {
//             flightsCache = flightsList;
//           }
//         });
  
//         bookingsUnsubscribe = onSnapshot(collection(db, 'bookings'), (bookingsSnapshot) => {
//           const bookingsList = bookingsSnapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data()
//           }));
  
//           if (flightsCache) {
//             handleDataUpdate(flightsCache, bookingsList);
//           } else {
//             bookingsCache = bookingsList;
//           }
//         });
//       } catch (error) {
//         console.error(t('Error fetching bookings:'), error);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     let flightsCache = null;
//     let bookingsCache = null;
  
//     fetchData();
  
//     return () => {
//       isMounted = false;
//       if (flightsUnsubscribe) flightsUnsubscribe();
//       if (bookingsUnsubscribe) bookingsUnsubscribe();
//     };
//   }, [t]);

//   const deleteBooking = async (flightId, bookingId) => {
//     try {
//       const result = await Swal.fire({
//         title: t('Are you sure?'),
//         text: t("You won't be able to revert this!"),
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: t('Yes, delete it!')
//       });
  
//       if (result.isConfirmed) {
//         // Delete the booking document from the bookings collection
//         await deleteDoc(doc(db, 'bookings', bookingId));
  
//         // Update the reservedSeats in the flight document
//         const flightRef = doc(db, 'flights', flightId);
  
//         // Get the booking data to retrieve seats to remove
//         const bookingToDelete = flights
//           .find(flight => flight.id === flightId)
//           ?.bookings.find(booking => booking.id === bookingId);
  
//         const seatsToRemove = bookingToDelete?.selectedSeats || [];
  
//         await updateDoc(flightRef, {
//           reservedSeats: arrayRemove(...seatsToRemove),
//         });
  
//         Swal.fire(
//           t('Deleted!'),
//           t('The booking has been deleted and seats have been freed.'),
//           'success'
//         );
//       }
//     } catch (error) {
//       console.error(t('Error deleting booking:'), error);
//       Swal.fire(t('Error'), t('An error occurred while deleting the booking.'), 'error');
//     }
//   };

//   const deleteTicket = async (flightId, bookingId, ticketIndex) => {
//     try {
//       const result = await Swal.fire({
//         title: t('Are you sure?'),
//         text: t("You won't be able to revert this!"),
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: t('Yes, delete it!')
//       });

//       if (result.isConfirmed) {
//         const flightRef = doc(db, 'flights', flightId);
//         const updatedFlights = flights.map(flight => {
//           if (flight.id === flightId) {
//             return {
//               ...flight,
//               bookings: flight.bookings.map(booking => {
//                 if (booking.bookingId === bookingId) {
//                   return {
//                     ...booking,
//                     tickets: booking.tickets.filter((_, index) => index !== ticketIndex)
//                   };
//                 }
//                 return booking;
//               })
//             };
//           }
//           return flight;
//         });

//         await updateDoc(flightRef, {
//           bookings: updatedFlights.find(f => f.id === flightId).bookings
//         });

//         setFlights(updatedFlights);

//         Swal.fire(
//           t('Deleted!'),
//           t('The ticket has been deleted.'),
//           'success'
//         );
//       }
//     } catch (error) {
//       console.error(t('Error deleting ticket:'), error);
//       Swal.fire(t('Error'), t('An error occurred while deleting the ticket.'), 'error');
//     }
//   };

//   const printBooking = (booking, flight) => {
//     const element = document.createElement('div');
//     element.className = 'printable-area';
  
//     element.innerHTML = `
//       <h2>${t('Booking Receipt')}</h2>
//       <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
//       <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
//       <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
//       <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
//       <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
//       ${booking.isRoundTrip ? `
//         <p><strong>${t('Return Flight')}:</strong> ${flight.returnName || flight.name}</p>
//         <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
//         <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
//       ` : ''}
//       <p><strong>${t('Total Cost')}:</strong> $${parseFloat(booking.totalCost).toFixed(2)}</p>
      
//       <h3>${t('Passengers')}</h3>
//       ${booking.tickets.map((ticket, idx) => `
//         <div class="passenger-info">
//           <h4>${t('Passenger')} ${idx + 1}</h4>
//           <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
//           <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
//           <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
//           <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
//         </div>
//       `).join('')}
//     `;
  
//     html2pdf().from(element).set({
//       margin: 10,
//       filename: `booking-${booking.bookingId}.pdf`,
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
//     }).save();
//   };
  


//   const printTicket = (ticket, booking, flight) => {
//     const element = document.createElement('div');
//     element.className = 'printable-area';
  
//     element.innerHTML = `
//       <h2>${t('Flight Ticket')}</h2>
//       <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
//       <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
//       <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
//       <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
//       <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
//       <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
//       <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
      
//       <h3>${t('Passenger Information')}</h3>
//       <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
//       <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
//       <p><strong>${t('Date of Birth')}:</strong> ${ticket.passengerDetails.dateOfBirth}</p>
//       <p><strong>${t('Nationality')}:</strong> ${ticket.passengerDetails.nationality}</p>
      
//       ${booking.isRoundTrip ? `
//         <h3>${t('Return Flight')}</h3>
//         <p><strong>${t('Flight')}:</strong> ${flight.returnName || flight.name}</p>
//         <p><strong>${t('Route')}:</strong> ${flight.routeDestination} to ${flight.routeSource}</p>
//         <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
//         <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
//       ` : ''}
//     `;
  
//     html2pdf().from(element).set({
//       margin: 10,
//       filename: `ticket-${ticket.ticketNo}.pdf`,
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
//     }).save();
//   };
  
//   const toggleSection = (flightId, section) => {
//     setOpenSections(prevState => ({
//       ...prevState,
//       [flightId]: prevState[flightId] === section ? null : section
//     }));
//   };

  // const handleSearchChange = (flightId, section, value) => {
  //   setSearchTerms(prevTerms => ({
  //     ...prevTerms,
  //     [flightId]: {
  //       ...prevTerms[flightId],
  //       [section]: value
  //     }
  //   }));
  // };

  // const handleGlobalSearch = (event) => {
  //   setGlobalSearchTerm(event.target.value.toLowerCase());
  // };



//   const handleSearchChange = (flightId, section, value) => {
//     setSearchTerms(prevTerms => ({
//       ...prevTerms,
//       [flightId]: {
//         ...prevTerms[flightId],
//         [section]: value.toLowerCase()
//       }
//     }));
//   };


  // const filterFlights = (flight) => {
  //   const searchTerm = globalSearchTerm.toLowerCase();
  //   return (
  //     flight.id.toLowerCase().includes(searchTerm) ||
  //     flight.routeSource.toLowerCase().includes(searchTerm) ||
  //     flight.routeDestination.toLowerCase().includes(searchTerm) ||
  //     flight.departureDate.toLowerCase().includes(searchTerm) ||
  //     flight.bookings.some(booking => 
  //       booking.bookingId.toLowerCase().includes(searchTerm) ||
  //       (booking.username && booking.username.toLowerCase().includes(searchTerm)) ||
  //       (booking.email && booking.email.toLowerCase().includes(searchTerm)) ||
  //       booking.tickets.some(ticket => 
  //         ticket.ticketNo.toLowerCase().includes(searchTerm) ||
  //         (ticket.passengerDetails && 
  //           (ticket.passengerDetails.firstName.toLowerCase().includes(searchTerm) ||
  //            ticket.passengerDetails.lastName.toLowerCase().includes(searchTerm)))
  //       )
  //     )
  //   );
  // };

//   const generateQRCodeData = (flight, passenger, isReturn = false) => {
//     return `Passenger: ${passenger.firstName} ${passenger.lastName}
//     Date of Birth: ${passenger.dateOfBirth}
//     Passport: ${passenger.passport}
//     Passport Expiry Date: ${passenger.passportExpiryDate || 'N/A'}
//     Nationality: ${passenger.nationality}
//     Gender: ${passenger.gender}
//     Class: ${passenger.className || 'N/A'}
//     Ticket No: ${passenger.ticketNo || 'N/A'}
//     Seat: ${passenger.seat || 'N/A'}
//     Flight ID: ${flight.id || 'N/A'}
//     Booking ID: ${passenger.bookingId || 'N/A'}
//     ${isReturn ? 'Return Flight' : 'Outbound Flight'}
//     From: ${isReturn ? flight.routeDestination : flight.routeSource || 'N/A'}
//     To: ${isReturn ? flight.routeSource : flight.routeDestination || 'N/A'}
//     Date: ${isReturn ? flight.returnDate : flight.departureDate || 'N/A'}
//     Time: ${isReturn ? flight.returnTime : flight.departureTime || 'N/A'}`;
//   };



  // const filteredFlights = globalSearchTerm ? flights.filter(filterFlights) : flights;
  
//   if (loading) {
//     return <div className="loading">{t('Loading')}...</div>;
//   }


//   return (
//     <div className="manage-bookings-container">
//       <h1 className="manage-bookings-title">{t('Manage Bookings')}</h1>
//       {/* <div className="global-search-container">
//         <FaSearch className="search-icon" />
//         <input
//           type="text"
//           placeholder={t('Search all flights...')}
//           value={globalSearchTerm}
//           onChange={handleGlobalSearch}
//           className="global-search-input"
//         />
//       </div> */}

//       <div className="global-search-container">
//         <FaSearch className="search-icon" />
//         <input
//           type="text"
//           placeholder={t('Search all flights...')}
//           value={globalSearchTerm}
//           onChange={handleGlobalSearch}
//           className="global-search-input"
//         />
//       </div>

//       {filteredFlights.length > 0 ? (
//         filteredFlights.map(flight => (
//           <div key={flight.id} className="flight-card">
//             <div className="flight-header">
//               <div className="flight-info">
//                 <h2><FaPlane className="icon" /> {flight.id}</h2>
//                 <p><FaMapMarkerAlt className="icon" /> {flight.routeSource} {t('to')} {flight.routeDestination}</p>
//                 <p><FaClock className="icon" /> {flight.departureDate}</p>
//               </div>
//               <div className="flight-actions">
//                 <button 
//                   className={`toggle-seat-map-btn ${openSections[flight.id] === 'seatMap' ? 'active' : ''}`} 
//                   onClick={() => toggleSection(flight.id, 'seatMap')}
//                 >
//                   {openSections[flight.id] === 'seatMap' ? t('Hide Seat Map') : t('Show Seat Map')}
//                 </button>
//                 <button 
//                   className={`toggle-bookings-btn ${openSections[flight.id] === 'bookings' ? 'active' : ''}`} 
//                   onClick={() => toggleSection(flight.id, 'bookings')}
//                 >
//                   {openSections[flight.id] === 'bookings' ? t('Hide Bookings') : t('Show Bookings')}
//                 </button>
//                 <button 
//                   className={`toggle-passengers-btn ${openSections[flight.id] === 'passengers' ? 'active' : ''}`} 
//                   onClick={() => toggleSection(flight.id, 'passengers')}
//                 >
//                   {openSections[flight.id] === 'passengers' ? t('Hide Passengers') : t('Show Passengers')}
//                 </button>
//               </div>
//             </div>

//             {openSections[flight.id] === 'seatMap' && (
//               <div className="seat-map-container">
//                 <h3>{t('Seat Map for')} {t(flight.routeSource)} {t('to')} {t(flight.routeDestination)}</h3>
//                 <SeatMap flight={flight} />
//               </div>
//             )}

//             {openSections[flight.id] === 'bookings' && (
//               <div className="bookings-container">
//                 <div className="search-container">
//                   <FaSearch className="search-icon" />
//                   <input
//                     type="text"  
//                     placeholder={t('Search bookings...')}
//                     value={searchTerms[flight.id]?.bookings || ''}
//                     onChange={(e) => handleSearchChange(flight.id, 'bookings', e.target.value)}
//                     className="search-input"
//                   />
//                 </div>
//                 {flight.bookings && flight.bookings.length > 0 ? (
//                   flight.bookings
//                     // .filter(booking => 
//                     //   !searchTerms[flight.id]?.bookings ||
//                     //   booking.bookingId.toLowerCase().includes(searchTerms[flight.id].bookings.toLowerCase()) ||
//                     //   (booking.username && booking.username.toLowerCase().includes(searchTerms[flight.id].bookings.toLowerCase())) ||
//                     //   (booking.email && booking.email.toLowerCase().includes(searchTerms[flight.id].bookings.toLowerCase()))
//                     // )
//                     .filter(booking => {
//                       const bookingSearchTerm = searchTerms[flight.id]?.bookings || '';
//                       return (
//                         !bookingSearchTerm ||
//                         booking.bookingId.toLowerCase().includes(bookingSearchTerm) ||
//                         (booking.username && booking.username.toLowerCase().includes(bookingSearchTerm)) ||
//                         (booking.email && booking.email.toLowerCase().includes(bookingSearchTerm)) ||
//                         booking.tickets.some(ticket => 
//                           ticket.ticketNo.toLowerCase().includes(bookingSearchTerm) ||
//                           (ticket.passengerDetails && 
//                             (ticket.passengerDetails.firstName.toLowerCase().includes(bookingSearchTerm) ||
//                              ticket.passengerDetails.lastName.toLowerCase().includes(bookingSearchTerm)))
//                         )
//                       );
//                     })

//                     .map((booking, index) => (
//                       <div key={index} className="booking-card">
//                         <div className="booking-header">
//                           <h3><FaIdCard className="icon" /> {t('Booking ID')}: {booking.bookingId}</h3>
//                           <button 
//                             className="print-booking-btn" 
//                             onClick={() => printBooking(booking, flight)}
//                           >
//                             {t('Print Booking')}
//                           </button>
//                           <button 
//                             className="delete-booking-btn" 
//                             onClick={() => deleteBooking(flight.id, booking.bookingId)}
//                           >
//                             {t('Delete Booking')}
//                           </button>
//                         </div>
//                         <div className="booking-details">
//                           <div className="booking-info">
//                             <p><FaUser className="icon" /> <strong>{t('Passenger')}:</strong> {booking.username || t('N/A')}</p>
//                             <p><FaEnvelope className="icon" /> <strong>{t('Email')}:</strong> {booking.email || t('N/A')}</p>
//                             <p><FaPhone className="icon" /> <strong>{t('Phone')}:</strong> {booking.phoneNumber || t('N/A')}</p>
//                             <p><FaMapMarkerAlt className="icon" /> <strong>{t('Address')}:</strong> {booking.address || t('N/A')}</p>
//                             <p><FaChair className="icon" /> <strong>{t('Selected Seats')}:</strong> {booking.selectedSeats ? booking.selectedSeats.join(', ') : t('N/A')}</p>
//                             <p><FaDollarSign className="icon" /> <strong>{t('Total Cost')}:</strong> ${booking.totalCost ? parseFloat(booking.totalCost).toFixed(2) : t('N/A')}</p>
//                           </div>
//                           <div className="tickets-container">
//                             <h4><FaTicketAlt className="icon" /> {t('Tickets')}</h4>
//                             {booking.tickets && booking.tickets.map((ticket, idx) => (
//                               <div key={idx} className="ticket-card">
//                                 <div className="ticket-header">
//                                   <h5>{ticket.className}: {ticket.ticketNo}</h5>
//                                   <button 
//                                     className="delete-ticket-btn" 
//                                     onClick={() => deleteTicket(flight.id, booking.bookingId, idx)}
//                                   >
//                                     {t('Delete Ticket')}
//                                   </button>
//                                   <button 
//                                     className="print-ticket-btn" 
//                                     onClick={() => printTicket(ticket, booking, flight)}
//                                   >
//                                     {t('Print Ticket')}
//                                   </button>
//                                   <p>{t('Seat')}: {ticket.seat || t('N/A')}</p>
//                                 </div>
//                                 <div className="passenger-details">
//                                   <h6>{t('Passenger Details')}</h6>
//                                   {ticket.passengerDetails ? (
//                                     <ul>
//                                       <li><FaUser className="icon" /> {ticket.passengerDetails.firstName} {ticket.passengerDetails.lastName}</li>
//                                       <li><FaBirthdayCake className="icon" /> {ticket.passengerDetails.dateOfBirth}</li>
//                                       <li><FaFlag className="icon" /> {ticket.passengerDetails.nationality}</li>
//                                       <li><FaPassport className="icon" /> {ticket.passengerDetails.passport}</li>
//                                       <li><FaCalendarAlt className="icon" /> {ticket.passengerDetails.passportExpiryDate || t('N/A')}</li>
//                                       <li><FaVenusMars className="icon" /> {ticket.passengerDetails.gender}</li>
//                                     </ul>
//                                   ) : (
//                                     <p>{t('Passenger details not available')}</p>
//                                   )}
//                                 </div>

//                               <BoardingPass 
//                                 flight={flight} 
//                                 passenger={{...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId}}
//                                 isReturn={false}
//                               />
//                               {booking.isRoundTrip && (
//                                 <BoardingPass 
//                                   flight={flight} 
//                                   passenger={{...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId}}
//                                   isReturn={true}
//                                 />
//                               )}

//                               </div>
//                             ))}
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                 ) : (
//                   <p className="no-bookings">{t('No bookings yet')}</p>
//                 )}
//               </div>
//             )}

//             {openSections[flight.id] === 'passengers' && (
//               <PassengerList flight={flight} searchTerm={searchTerms[flight.id]?.passengers || ''} onSearchChange={(value) => handleSearchChange(flight.id, 'passengers', value)} />
//             )}
//           </div>
//         ))
//       ) : (
//         <p className="no-flights">{t('No flights available')}.</p>
//       )}
//     </div>
//   );
// };


// export default ManageBookings;
















import React, { useState, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { collection, onSnapshot, deleteDoc, doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import { FaTrashCan } from "react-icons/fa6";
import { FaIdCard, FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaChair, FaDollarSign, FaTicketAlt, FaBirthdayCake, FaFlag, FaPassport, FaCalendarAlt, FaVenusMars, FaPlane, FaClock, FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import html2pdf from 'html2pdf.js';
import '../../styles/ManageBookings.css';

import SeatMap from './SeatMap';
import PassengerList from './PassengerList';
import BoardingPass from './BoardingPass';

import { renderBoardingPass } from './renderBoardingPasses';

const ManageBookings = () => {
  const { t } = useTranslation();
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSections, setOpenSections] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');

  useEffect(() => {
    let isMounted = true;
    let flightsUnsubscribe;
    let bookingsUnsubscribe;
    let flightsCache = null;
    let bookingsCache = null;

    const handleDataUpdate = (flightsList, bookingsList) => {
      const bookingsByFlightId = bookingsList.reduce((acc, booking) => {
        const flightId = booking.flightId;
        if (!acc[flightId]) {
          acc[flightId] = [];
        }
        acc[flightId].push(booking);
        return acc;
      }, {});

      const flightsWithBookings = flightsList.map(flight => ({
        ...flight,
        bookings: bookingsByFlightId[flight.id] || []
      }));

      if (isMounted) {
        setFlights(flightsWithBookings);
        setLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        flightsUnsubscribe = onSnapshot(collection(db, 'flights'), (flightsSnapshot) => {
          const flightsList = flightsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            bookings: []
          }));

          if (bookingsCache) {
            handleDataUpdate(flightsList, bookingsCache);
          } else {
            flightsCache = flightsList;
          }
        });

        bookingsUnsubscribe = onSnapshot(collection(db, 'bookings'), (bookingsSnapshot) => {
          const bookingsList = bookingsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          if (flightsCache) {
            handleDataUpdate(flightsCache, bookingsList);
          } else {
            bookingsCache = bookingsList;
          }
        });
      } catch (error) {
        console.error(t('Error fetching bookings:'), error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (flightsUnsubscribe) flightsUnsubscribe();
      if (bookingsUnsubscribe) bookingsUnsubscribe();
    };
  }, [t]);

  const deleteBooking = async (flightId, bookingId) => {
    try {
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes, delete it!')
      });

      if (result.isConfirmed) {
        await deleteDoc(doc(db, 'bookings', bookingId));

        const flightRef = doc(db, 'flights', flightId);
        const bookingToDelete = flights
          .find(flight => flight.id === flightId)
          ?.bookings.find(booking => booking.id === bookingId);

        const seatsToRemove = bookingToDelete?.selectedSeats || [];

        await updateDoc(flightRef, {
          reservedSeats: arrayRemove(...seatsToRemove),
        });

        Swal.fire(
          t('Deleted!'),
          t('The booking has been deleted and seats have been freed.'),
          'success'
        );
      }
    } catch (error) {
      console.error(t('Error deleting booking:'), error);
      Swal.fire(t('Error'), t('An error occurred while deleting the booking.'), 'error');
    }
  };
  let y;
  // const deleteTicket = async (flightId, bookingId, ticketIndex) => {
  //   try {
  //     const result = await Swal.fire({
  //       title: t('Are you sure?'),
  //       text: t("You won't be able to revert this!"),
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: t('Yes, delete it!')
  //     });

  //     if (result.isConfirmed) {
  //       const flightRef = doc(db, 'flights', flightId);
  //       const updatedFlights = flights.map(flight => {
  //         if (flight.id === flightId) {
  //           return {
  //             ...flight,
  //             bookings: flight.bookings.map(booking => {
  //               if (booking.bookingId === bookingId) {
  //                 return {
  //                   ...booking,
  //                   tickets: booking.tickets.filter((_, index) => index !== ticketIndex)
  //                 };
  //               }
  //               return booking;
  //             })
  //           };
  //         }
  //         return flight;
  //       });

  //       await updateDoc(flightRef, {
  //         bookings: updatedFlights.find(f => f.id === flightId).bookings
  //       });

  //       setFlights(updatedFlights);

  //       Swal.fire(
  //         t('Deleted!'),
  //         t('The ticket has been deleted.'),
  //         'success'
  //       );
  //     }
  //   } catch (error) {
  //     console.error(t('Error deleting ticket:'), error);
  //     Swal.fire(t('Error'), t('An error occurred while deleting the ticket.'), 'error');
  //   }
  // };

  // const printBooking = (booking, flight) => {
  //   const element = document.createElement('div');
  //   element.className = 'printable-area';
  //   element.innerHTML = `
  //     <h2>${t('Booking Receipt')}</h2>
  //     <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
  //     <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
  //     <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
  //     <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
  //     <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
  //     ${booking.isRoundTrip ? `
  //       <p><strong>${t('Return Flight')}:</strong> ${flight.returnName || flight.name}</p>
  //       <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
  //       <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
  //     ` : ''}
  //     <p><strong>${t('Total Cost')}:</strong> $${parseFloat(booking.totalCost).toFixed(2)}</p>
      
  //     <h3>${t('Passengers')}</h3>
  //     ${booking.tickets.map((ticket, idx) => `
  //       <div class="passenger-info">
  //         <h4>${t('Passenger')} ${idx + 1}</h4>
  //         <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
  //         <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
  //         <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
  //         <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
  //       </div>
  //     `).join('')}
  //   `;
  
  //   html2pdf().from(element).set({
  //     margin: 10,
  //     filename: `booking-${booking.bookingId}.pdf`,
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //   }).save();
  // };
  
  const deleteTicket = async (flightId, bookingId, ticketIndex) => {
    try {
      const result = await Swal.fire({
        title: t('Are you sure?'),
        text: t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes, delete it!')
      });

      if (result.isConfirmed) {
        // Get the current booking document
        const bookingRef = doc(db, 'bookings', bookingId);
        const bookingDoc = await getDoc(bookingRef);

        if (!bookingDoc.exists()) {
          throw new Error('Booking not found');
        }

        const bookingData = bookingDoc.data();
        const ticketToDelete = bookingData.tickets[ticketIndex];

        // Update the booking document
        const updatedTickets = bookingData.tickets.filter((_, index) => index !== ticketIndex);
        await updateDoc(bookingRef, { tickets: updatedTickets });

        // Update the flight's reservedSeats
        const flightRef = doc(db, 'flights', flightId);
        await updateDoc(flightRef, {
          reservedSeats: arrayRemove(ticketToDelete.seat)
        });

        // Update the local state
        setFlights(prevFlights => 
          prevFlights.map(flight => {
            if (flight.id === flightId) {
              return {
                ...flight,
                bookings: flight.bookings.map(booking => {
                  if (booking.bookingId === bookingId) {
                    return {
                      ...booking,
                      tickets: updatedTickets
                    };
                  }
                  return booking;
                })
              };
            }
            return flight;
          })
        );

        Swal.fire(
          t('Deleted!'),
          t('The ticket has been deleted.'),
          'success'
        );
      }
    } catch (error) {
      console.error(t('Error deleting ticket:'), error);
      Swal.fire(t('Error'), t('An error occurred while deleting the ticket.'), 'error');
    }
  };

  
  let k;
  const printBooking = (booking, flight) => {
    const element = document.createElement('div');
    element.className = 'printable-area';
    
    const style = document.createElement('style');
    style.textContent = `
      @page {
      
        margin: 0;
      }
      body {
        margin: 0;
      }
      .printable-area {
        font-family: Arial, sans-serif;
        max-width: 210mm;
        margin: 0 auto;
        padding: 10mm;
      }
      .booking-header {
        text-align: center;
        margin-bottom: 20px;
      }
      .booking-details {
        margin-bottom: 20px;
      }
      .passenger-info {
        margin-bottom: 10px;
      }
      .boarding-passes {
        page-break-before: always;
      }
      .boarding-pass {
        transform: scale(0.95);
        transform-origin: top left;
        margin-bottom: 10mm;
      }
    `;
    element.appendChild(style);

    element.innerHTML += `
      <div class="booking-header">
        <h2>${t('Booking Receipt')}</h2>
        <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
      </div>
      <div class="booking-details">
        <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
        <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
        <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
        <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
        ${booking.isRoundTrip ? `
          <p><strong>${t('Return Flight')}:</strong> ${flight.returnName || flight.name}</p>
          <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
          <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
        ` : ''}
        <p><strong>${t('Total Cost')}:</strong> $${parseFloat(booking.totalCost).toFixed(2)}</p>
      </div>
      <h3>${t('Passengers')}</h3>
      ${booking.tickets.map((ticket, idx) => `
        <div class="passenger-info">
          <h4>${t('Passenger')} ${idx + 1}</h4>
          <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
          <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
          <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
          <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
        </div>
      `).join('')}
    `;

    const boardingPassesContainer = document.createElement('div');
    boardingPassesContainer.className = 'boarding-passes';
    booking.tickets.forEach(ticket => {
      const passenger = {...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId};
      boardingPassesContainer.innerHTML += renderBoardingPass(flight, passenger, false);
      if (booking.isRoundTrip) {
        boardingPassesContainer.innerHTML += renderBoardingPass(flight, passenger, true);
      }
    });
    element.appendChild(boardingPassesContainer);

    html2pdf().from(element).set({
      margin: 10,
      filename: `booking-${booking.bookingId}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).save();
  };

  // const printTicket = (ticket, booking, flight) => {
  //   const element = document.createElement('div');
  //   element.className = 'printable-area';
  //   element.innerHTML = `
  //     <h2>${t('Flight Ticket')}</h2>
  //     <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
  //     <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
  //     <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
  //     <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
  //     <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
  //     <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
  //     <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
      
  //     <h3>${t('Passenger Information')}</h3>
  //     <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
  //     <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
  //     <p><strong>${t('Date of Birth')}:</strong> ${ticket.passengerDetails.dateOfBirth}</p>
  //     <p><strong>${t('Nationality')}:</strong> ${ticket.passengerDetails.nationality}</p>
      
  //     ${booking.isRoundTrip ? `
  //       <h3>${t('Return Flight')}</h3>
  //       <p><strong>${t('Flight')}:</strong> ${flight.returnName || flight.name}</p>
  //       <p><strong>${t('Route')}:</strong> ${flight.routeDestination} to ${flight.routeSource}</p>
  //       <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
  //       <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
  //     ` : ''}
  //   `;
  
  //   html2pdf().from(element).set({
  //     margin: 10,
  //     filename: `ticket-${ticket.ticketNo}.pdf`,
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //   }).save();
  // };
  let lsl;
  // const printTicket = (ticket, booking, flight) => {
  //   const element = document.createElement('div');
  //   element.className = 'printable-area';
    
  //   const style = document.createElement('style');
  //   style.textContent = `
  //     @page {
  //       margin: 0;
  //     }
  //     body {
  //       margin: 0;
  //     }
  //     .printable-area {
  //       font-family: Arial, sans-serif;
  //       max-width: 210mm;
  //       margin: 0 auto;
  //       padding: 10mm;
  //     }
  //     .ticket-header {
  //       text-align: center;
  //       margin-bottom: 20px;
  //     }
  //     .ticket-details {
  //       margin-bottom: 20px;
  //     }
  //     .boarding-pass-container {
  //       page-break-before: always;
  //     }
  //     .boarding-pass {
  //       transform: scale(0.95);
  //       transform-origin: top left;
  //       margin-bottom: 10mm;
  //     }
  //   `;
  //   element.appendChild(style);

  //   element.innerHTML += `
  //     <div class="ticket-header">
  //       <h2>${t('Flight Ticket')}</h2>
  //       <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
  //       <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
  //     </div>
  //     <div class="ticket-details">
  //       <p><strong>${t('Flight')}:</strong> ${flight.id}</p>
  //       <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
  //       <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
  //       <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
  //       <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
        
  //       <h3>${t('Passenger Information')}</h3>
  //       <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
  //       <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
  //       <p><strong>${t('Date of Birth')}:</strong> ${ticket.passengerDetails.dateOfBirth}</p>
  //       <p><strong>${t('Nationality')}:</strong> ${ticket.passengerDetails.nationality}</p>
        
  //       ${booking.isRoundTrip ? `
  //         <h3>${t('Return Flight')}</h3>
  //         <p><strong>${t('Flight')}:</strong> ${flight.returnName || flight.name}</p>
  //         <p><strong>${t('Route')}:</strong> ${flight.routeDestination} to ${flight.routeSource}</p>
  //         <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
  //         <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
  //       ` : ''}
  //     </div>
  //   `;

  //   const boardingPassContainer = document.createElement('div');
  //   boardingPassContainer.className = 'boarding-pass-container';
  //   const passenger = {...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId};
  //   boardingPassContainer.innerHTML += renderBoardingPass(flight, passenger, false);
  //   if (booking.isRoundTrip) {
  //     boardingPassContainer.innerHTML += renderBoardingPass(flight, passenger, true);
  //   }
  //   element.appendChild(boardingPassContainer);

  //   html2pdf().from(element).set({
  //     margin: 10,
  //     filename: `ticket-${ticket.ticketNo}.pdf`,
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //   }).save();
  // };
  let ll;

  const printTicket = (ticket, booking, flight) => {
    const element = document.createElement('div');
    element.className = 'printable-area';
    
    const style = document.createElement('style');
    style.textContent = `
      @page {
        size: A4;
        margin: 0;
      }
      body {
        margin: 0;
        padding: 0;
      }
      .printable-area {
        width: 210mm;
        min-height: 297mm;
        padding: 10mm;
        box-sizing: border-box;
        page-break-after: always;
      }
      .ticket-header {
        text-align: center;
        margin-bottom: 20px;
      }
      .ticket-details {
        margin-bottom: 20px;
      }
      .boarding-pass-container {
        width: 100%;
        page-break-before: always;
      }
      .boarding-pass {
        width: 100%;
        margin-bottom: 10mm;
      }
    `;
    element.appendChild(style);

    const ticketDetailsHtml = `
      <div class="printable-area">
        <div class="ticket-header">
          <h2>${t('Flight Ticket')}</h2>
          <p><strong>${t('Booking ID')}:</strong> ${booking.bookingId}</p>
          <p><strong>${t('Ticket Number')}:</strong> ${ticket.ticketNo}</p>
        </div>
        <div class="ticket-details">
          <p><strong>${t('Flight')}:</strong> ${flight.name}</p>
          <p><strong>${t('Route')}:</strong> ${flight.routeSource} to ${flight.routeDestination}</p>
          <p><strong>${t('Departure Date')}:</strong> ${flight.departureDate}</p>
          <p><strong>${t('Departure Time')}:</strong> ${flight.departureTime}</p>
          <p><strong>${t('Seat')}:</strong> ${ticket.seat}</p>
          
          <h3>${t('Passenger Information')}</h3>
          <p><strong>${t('Name')}:</strong> ${ticket.passengerDetails.firstName} ${ticket.passengerDetails.lastName}</p>
          <p><strong>${t('Passport')}:</strong> ${ticket.passengerDetails.passport}</p>
          <p><strong>${t('Date of Birth')}:</strong> ${ticket.passengerDetails.dateOfBirth}</p>
          <p><strong>${t('Nationality')}:</strong> ${ticket.passengerDetails.nationality}</p>
          
          ${booking.isRoundTrip ? `
            <h3>${t('Return Flight')}</h3>
            <p><strong>${t('Flight')}:</strong> ${flight.returnName || flight.name}</p>
            <p><strong>${t('Route')}:</strong> ${flight.routeDestination} to ${flight.routeSource}</p>
            <p><strong>${t('Return Date')}:</strong> ${flight.returnDate}</p>
            <p><strong>${t('Return Time')}:</strong> ${flight.returnTime}</p>
          ` : ''}
        </div>
      </div>
    `;

    element.innerHTML = ticketDetailsHtml;

    const boardingPassContainer = document.createElement('div');
    boardingPassContainer.className = 'printable-area boarding-pass-container';
    const passenger = {...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId};
    
    const outboundBoardingPass = ReactDOMServer.renderToString(
      <BoardingPass
        flight={flight}
        passenger={passenger}
        isReturn={false}
      />
    );
    boardingPassContainer.innerHTML += outboundBoardingPass;

    if (booking.isRoundTrip) {
      const returnBoardingPass = ReactDOMServer.renderToString(
        <BoardingPass
          flight={flight}
          passenger={passenger}
          isReturn={true}
        />
      );
      boardingPassContainer.innerHTML += returnBoardingPass;
    }

    element.appendChild(boardingPassContainer);

    html2pdf().from(element).set({
      margin: 0,
      filename: `ticket-${ticket.ticketNo}.pdf`,
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).save();
  };

  const toggleSection = (flightId, section) => {
    setOpenSections(prevState => ({
      ...prevState,
      [flightId]: prevState[flightId] === section ? null : section
    }));
  };

  const handleGlobalSearch = (event) => {
    setGlobalSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchChange = (flightId, section, value) => {
    setSearchTerms(prevTerms => ({
      ...prevTerms,
      [flightId]: {
        ...prevTerms[flightId],
        [section]: value.toLowerCase()
      }
    }));
  };

  const filteredFlightsAndBookings = useMemo(() => {
    if (!globalSearchTerm) return flights;
  
    return flights.map(flight => {
      const searchTerm = globalSearchTerm.toLowerCase();
      const flightMatches = 
        flight.id.toLowerCase().includes(searchTerm) ||
        flight.routeSource.toLowerCase().includes(searchTerm) ||
        flight.routeDestination.toLowerCase().includes(searchTerm) ||
        flight.departureDate.toLowerCase().includes(searchTerm);
  
      const matchedBookings = flight.bookings.filter(booking => 
        booking.bookingId.toLowerCase().includes(searchTerm) ||
        (booking.username && booking.username.toLowerCase().includes(searchTerm)) ||
        (booking.email && booking.email.toLowerCase().includes(searchTerm)) ||
        (booking.phoneNumber && booking.phoneNumber.toLowerCase().includes(searchTerm)) ||
        (booking.address && booking.address.toLowerCase().includes(searchTerm)) ||
        (booking.totalCost && booking.totalCost.toString().includes(searchTerm)) ||
        booking.tickets.some(ticket => 
          ticket.ticketNo.toLowerCase().includes(searchTerm) ||
          ticket.seat.toLowerCase().includes(searchTerm) ||
          (ticket.passengerDetails && (
            ticket.passengerDetails.firstName.toLowerCase().includes(searchTerm) ||
            ticket.passengerDetails.lastName.toLowerCase().includes(searchTerm) ||
            ticket.passengerDetails.dateOfBirth.toLowerCase().includes(searchTerm) ||
            ticket.passengerDetails.nationality.toLowerCase().includes(searchTerm) ||
            ticket.passengerDetails.passport.toLowerCase().includes(searchTerm) ||
            (ticket.passengerDetails.passportExpiryDate && ticket.passengerDetails.passportExpiryDate.toLowerCase().includes(searchTerm)) ||
            ticket.passengerDetails.gender.toLowerCase().includes(searchTerm)
          ))
        )
      );
  
      return {
        ...flight,
        matches: flightMatches,
        bookings: matchedBookings
      };
    }).filter(flight => flight.matches || flight.bookings.length > 0);
  }, [flights, globalSearchTerm]);

  const getFilteredBookings = useMemo(() => {
    return (flight) => {
      const bookingSearchTerm = searchTerms[flight.id]?.bookings || '';
      return flight.bookings.filter(booking => 
        !bookingSearchTerm ||
        booking.bookingId.toLowerCase().includes(bookingSearchTerm) ||
        (booking.username && booking.username.toLowerCase().includes(bookingSearchTerm)) ||
        (booking.email && booking.email.toLowerCase().includes(bookingSearchTerm)) ||
        (booking.phoneNumber && booking.phoneNumber.toLowerCase().includes(bookingSearchTerm)) ||
        (booking.address && booking.address.toLowerCase().includes(bookingSearchTerm)) ||
        (booking.totalCost && booking.totalCost.toString().includes(bookingSearchTerm)) ||
        booking.tickets.some(ticket => 
          ticket.ticketNo.toLowerCase().includes(bookingSearchTerm) ||
          ticket.seat.toLowerCase().includes(bookingSearchTerm) ||
          (ticket.passengerDetails && (
            ticket.passengerDetails.firstName.toLowerCase().includes(bookingSearchTerm) ||
            ticket.passengerDetails.lastName.toLowerCase().includes(bookingSearchTerm) ||
            ticket.passengerDetails.dateOfBirth.toLowerCase().includes(bookingSearchTerm) ||
            ticket.passengerDetails.nationality.toLowerCase().includes(bookingSearchTerm) ||
            ticket.passengerDetails.passport.toLowerCase().includes(bookingSearchTerm) ||
            (ticket.passengerDetails.passportExpiryDate && ticket.passengerDetails.passportExpiryDate.toLowerCase().includes(bookingSearchTerm)) ||
            ticket.passengerDetails.gender.toLowerCase().includes(bookingSearchTerm)
          ))
        )
      );
    };
  }, [searchTerms]);
  
  if (loading) {
    return <div className="loading">{t('Loading')}...</div>;
  }

  return (
    <div className="manage-bookings-container">
      <h1 className="manage-bookings-title">{t('Manage Bookings')}</h1>
      <div className="global-search-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder={t('Search all flights and bookings...')}
          value={globalSearchTerm}
          onChange={handleGlobalSearch}
          className="global-search-input"
        />
      </div>
      {filteredFlightsAndBookings.length > 0 ? (
        filteredFlightsAndBookings.map(flight => (
          <div key={flight.id} className="flight-card">
            <div className="flight-header">
              <div className="flight-info">
                <h2><FaPlane className="icon" /> {flight.id}</h2>
                <p><FaMapMarkerAlt className="icon" /> {flight.routeSource} {t('to')} {flight.routeDestination}</p>
                <p><FaClock className="icon" /> {flight.departureDate}</p>
              </div>
              <div className="flight-actions">
                <button 
                  className={`toggle-seat-map-btn ${openSections[flight.id] === 'seatMap' ? 'active' : ''}`} 
                  onClick={() => toggleSection(flight.id, 'seatMap')}
                >
                  {openSections[flight.id] === 'seatMap' ? t('Hide Seat Map') : t('Show Seat Map')}
                </button>
                <button 
                  className={`toggle-bookings-btn ${openSections[flight.id] === 'bookings' ? 'active' : ''}`} 
                  onClick={() => toggleSection(flight.id, 'bookings')}
                >
                  {openSections[flight.id] === 'bookings' ? t('Hide Bookings') : t('Show Bookings')}
                </button>
                <button 
                  className={`toggle-passengers-btn ${openSections[flight.id] === 'passengers' ? 'active' : ''}`} 
                  onClick={() => toggleSection(flight.id, 'passengers')}
                >
                  {openSections[flight.id] === 'passengers' ? t('Hide Passengers') : t('Show Passengers')}
                </button>
              </div>
            </div>

            {openSections[flight.id] === 'seatMap' && (
              <div className="seat-map-container">
                <h3>{t('Seat Map for')} {t(flight.routeSource)} {t('to')} {t(flight.routeDestination)}</h3>
                <SeatMap flight={flight} />
              </div>
            )}

            {openSections[flight.id] === 'bookings' && (
              <div className="bookings-container">
                <div className="search-container">
                  <FaSearch className="search-icon" />
                  <input
                    type="text"  
                    placeholder={t('Search bookings...')}
                    value={searchTerms[flight.id]?.bookings || ''}
                    onChange={(e) => handleSearchChange(flight.id, 'bookings', e.target.value)}
                    className="search-input"
                  />
                </div>
                {getFilteredBookings(flight).length > 0 ? (
                  getFilteredBookings(flight).map((booking, index) => (
                    <div key={index} className="booking-card">
                      <div className="booking-header">
                        <h3><FaIdCard className="icon" /> {t('Booking ID')}: {booking.bookingId}</h3>
                        {/* <button 
                          className="print-booking-btn" 
                          onClick={() => printBooking(booking, flight)}
                        >
                          {t('Print Booking')}
                        </button> */}
                        <button 
                          className="delete-booking-btn" 
                          onClick={() => deleteBooking(flight.id, booking.bookingId)}
                        >
                          {t('Delete Booking')} <FaTrashCan />
                        </button>
                      </div>
                      <div className="booking-details">
                        <div className="booking-info">
                          <p><FaUser className="icon" /> <strong>{t('Passenger')}:</strong> {booking.username || t('N/A')}</p>
                          <p><FaEnvelope className="icon" /> <strong>{t('Email')}:</strong> {booking.email || t('N/A')}</p>
                          <p><FaPhone className="icon" /> <strong>{t('Phone')}:</strong> {booking.phoneNumber || t('N/A')}</p>
                          <p><FaMapMarkerAlt className="icon" /> <strong>{t('Address')}:</strong> {booking.address || t('N/A')}</p>
                          <p><FaChair className="icon" /> <strong>{t('Selected Seats')}:</strong> {booking.selectedSeats ? booking.selectedSeats.join(', ') : t('N/A')}</p>
                          <p><FaDollarSign className="icon" /> <strong>{t('Total Cost')}:</strong> ${booking.totalCost ? parseFloat(booking.totalCost).toFixed(2) : t('N/A')}</p>
                        </div>
                        <div className="tickets-container">
                          <h4><FaTicketAlt className="icon" /> {t('Tickets')}</h4>
                          {booking.tickets && booking.tickets.map((ticket, idx) => (
                            <div key={idx} className="ticket-card">
                              <div className="ticket-header">
                                <h5>{ticket.className}: {ticket.ticketNo}</h5>
                                <button 
                                  className="delete-ticket-btn" 
                                  onClick={() => deleteTicket(flight.id, booking.bookingId, idx)}
                                >
                                  {t('Delete Ticket')} <FaTrashCan />
                                </button>
                                <button 
                                  className="print-ticket-btn" 
                                  onClick={() => printTicket(ticket, booking, flight)}
                                >
                                  {t('Print Ticket')}
                                </button>
                                <p>{t('Seat')}: {ticket.seat || t('N/A')}</p>
                              </div>
                              <div className="passenger-details">
                                <h6>{t('Passenger Details')}</h6>
                                {ticket.passengerDetails ? (
                                  <ul>
                                    <li><FaUser className="icon" /> {ticket.passengerDetails.firstName} {ticket.passengerDetails.lastName}</li>
                                    <li><FaBirthdayCake className="icon" /> {ticket.passengerDetails.dateOfBirth}</li>
                                    <li><FaFlag className="icon" /> {ticket.passengerDetails.nationality}</li>
                                    <li><FaPassport className="icon" /> {ticket.passengerDetails.passport}</li>
                                    <li><FaCalendarAlt className="icon" /> {ticket.passengerDetails.passportExpiryDate || t('N/A')}</li>
                                    <li><FaVenusMars className="icon" /> {ticket.passengerDetails.gender}</li>
                                  </ul>
                                ) : (
                                  <p>{t('Passenger details not available')}</p>
                                )}
                              </div>
                              <BoardingPass 
                                flight={flight} 
                                passenger={{...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId}}
                                isReturn={false}
                              />
                              {booking.isRoundTrip && (
                                <BoardingPass 
                                  flight={flight} 
                                  passenger={{...ticket.passengerDetails, ...ticket, bookingId: booking.bookingId}}
                                  isReturn={true}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-bookings">{t('No bookings found')}</p>
                )}

              </div>
            )}

            {openSections[flight.id] === 'passengers' && (
              <PassengerList 
                flight={flight} 
                searchTerm={searchTerms[flight.id]?.passengers || ''} 
                onSearchChange={(value) => handleSearchChange(flight.id, 'passengers', value)} 
              />
            )}
          </div>
        ))
      ) : (
        <p className="no-flights">{t('No flights or bookings found matching your search.')}</p>
      )}
    </div>
  );
};

export default ManageBookings;