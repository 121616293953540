import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/PassengerDetails.css';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import ProgressTracker from './ProgressTracker';
import { FaUser, FaPassport, FaGlobe, FaIdCard, FaBirthdayCake, FaVenusMars, FaCalendarAlt } from 'react-icons/fa';

const PassengerDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { totalTickets, selectedFlight, bookingData, isRoundTrip, searchParams } = location.state;
  const [selectedOrigin, setSelectedOrigin] = useState('');

  const availableOrigins = [
    { key: 'Israeli', label: t('Israeli') },
    { key: 'United States', label: t('United States') },
    { key: 'Canada', label: t('Canada') },
    { key: 'United Kingdom', label: t('United Kingdom') },
    { key: 'Germany', label: t('Germany') },
    { key: 'France', label: t('France') },
    { key: 'Italy', label: t('Italy') }
  ];

  const [passengers, setPassengers] = useState(
    Array(totalTickets).fill().map(() => ({
      firstName: '', lastName: '', dateOfBirth: '', passport: '', passportExpiryDate: '', nationality: '', gender: '', className: bookingData.tickets[0].className, israeliId: ''
    }))
  );

  const handleInputChange = (index, field, value) => {
    const updatedPassengers = [...passengers];

    if (field === 'firstName' || field === 'lastName') {
      const englishOnly = value.replace(/[^A-Za-z\s]/g, '');
      if (englishOnly !== value) {
        // Non-English characters detected, show warning
        Swal.fire({
          icon: 'warning',
          title: t('English Characters Only'),
          text: t('Please use only English characters for names.'),
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        value = englishOnly;
      }
      // Convert to uppercase (this happens silently for English input)
      value = value.toUpperCase();
    } else if (field === 'dateOfBirth') {
      value = formatDateOfBirth(value);
    }

    updatedPassengers[index] = {
      ...updatedPassengers[index],
      [field]: value
    };
    setPassengers(updatedPassengers);
  
    if (field === 'nationality') {
      setSelectedOrigin(value);
    }
  };

  const formatDateOfBirth = (date) => {
    const parts = date.split('-');
    if (parts.length === 3) {
      let [year, month, day] = parts;
      year = year.slice(0, 4);
      return `${year}-${month}-${day}`;
    }
    return date;
  };

  const validateIsraeliID = (id) => {
    if (id.length !== 9 || isNaN(id)) return false;

    let sum = 0;

    for (let i = 0; i < 8; i++) {
      let digit = parseInt(id[i], 10);
      digit *= (i % 2) + 1;
      if (digit > 9) digit -= 9;
      sum += digit;
    }

    return (sum + parseInt(id[8], 10)) % 10 === 0;
  };

  const validatePassportNumber = (passport) => {
    const passportRegex = /^[a-zA-Z0-9]{5,9}$/;
    return passportRegex.test(passport);
  };

  const validateDate = (date, type) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    if (type === 'birthdate' && inputDate > currentDate) {
      Swal.fire({
        icon: 'error',
        title: t('Invalid Date'),
        text: t('Date of birth cannot be in the future.')
      });
      return false;
    }

    if (type === 'passportExpiryDate') {
      if (inputDate < currentDate) {
        Swal.fire({
          icon: 'error',
          title: t('Expired Passport'),
          text: t('Passport has already expired.')
        });
        return false;
      }

      const threeMonthsFromNow = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
      if (inputDate <= threeMonthsFromNow) {
        Swal.fire({
          icon: 'warning',
          title: t('Passport Expiry Warning'),
          text: t('Your passport is expiring soon. Please renew it if it will expire within the next 3 months.')
        });
      }
    }

    return true;
  };

  const validatePassengerDetails = () => {
    for (let i = 0; i < passengers.length; i++) {
      const { firstName, lastName, dateOfBirth, passport, nationality, gender, israeliId, passportExpiryDate } = passengers[i];

      if (!firstName || !lastName || !dateOfBirth || !passport || !nationality || !gender) {
        Swal.fire({
          icon: 'error',
          title: t('Missing Details'),
          text: t('Please fill in all details for Passenger {{number}}.', { number: i + 1 })
        });
        return false;
      }

      if (nationality.toLowerCase() === 'israeli' || nationality.toLowerCase() === 'ישראלי') {
        if (!israeliId) {
          Swal.fire({
            icon: 'error',
            title: t('Missing ID Number'),
            text: t('Please provide an Israeli ID number for Passenger {{number}}.', { number: i + 1 })
          });
          return false;
        }

        if (!validateIsraeliID(israeliId)) {
          Swal.fire({
            icon: 'error',
            title: t('Invalid ID Number'),
            text: t('The Israeli ID number for Passenger {{number}} is invalid.', { number: i + 1 })
          });
          return false;
        }
      }

      if (!validatePassportNumber(passport)) {
        Swal.fire({
          icon: 'error',
          title: t('Invalid Passport Number'),
          text: t('The passport number for Passenger {{number}} is invalid.', { number: i + 1 })
        });
        return false;
      }

      if (!validateDate(dateOfBirth, 'birthdate')) {
        return false;
      }

      if (!validateDate(passportExpiryDate, 'passportExpiryDate')) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, '', '/passenger-details');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!totalTickets || !selectedFlight || !bookingData) {
      navigate('/book-flight', { replace: true });
    }
  }, [totalTickets, selectedFlight, bookingData, navigate]);

  const handleProceedToSeats = () => {
    if (validatePassengerDetails()) {
      const updatedBookingData = {
        ...bookingData,
        passengers: passengers
      };

      navigate('/seat-selection', {
        state: {
          selectedFlight,
          totalTickets,
          tickets: bookingData.tickets,
          bookingData: updatedBookingData,
          isRoundTrip,
          searchParams
        },
        replace: true
      });
    }
  };

  return (
    <>
      <ProgressTracker currentStep={2} />

      <div className="passenger-details-page">
        <div className="passenger-details-container">
          <h1 className="page-title">{t('Passenger Details')}</h1>
          {isRoundTrip && <p className="round-trip-indicator">{t('Round Trip Flight')}</p>}
          <form className="passenger-details-form">
            {passengers.map((passenger, index) => (
              <div key={index} className="passenger-card">
                <div className="passenger-header">
                  <FaUser className="passenger-icon" />
                  <h2 className="passenger-title">
                    {t('Passenger {{number}}', { number: index + 1 })}
                  </h2>
                  <span className="class-type">
                    {passenger.className}
                  </span>
                </div>
                <div className="passenger-details-grid">
                  <div className="form-group">
                    <label htmlFor={`firstName-${index}`}>{t('First Name')}</label>
                    <input
                      id={`firstName-${index}`}
                      type="text"
                      value={passenger.firstName}
                      onChange={(e) => handleInputChange(index, 'firstName', e.target.value)}
                      placeholder={t('Enter first name')}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`lastName-${index}`}>{t('Last Name')}</label>
                    <input
                      id={`lastName-${index}`}
                      type="text"
                      value={passenger.lastName}
                      onChange={(e) => handleInputChange(index, 'lastName', e.target.value)}
                      placeholder={t('Enter last name')}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`dateOfBirth-${index}`}>
                      <FaBirthdayCake className="icon" />
                      {t('Date of Birth')}
                    </label>
                    <input
                      id={`dateOfBirth-${index}`}
                      type="date"
                      value={passenger.dateOfBirth}
                      onChange={(e) => handleInputChange(index, 'dateOfBirth', e.target.value)}
                      required
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`gender-${index}`}>
                      <FaVenusMars className="icon" />
                      {t('Gender')}
                    </label>
                    <select
                      id={`gender-${index}`}
                      value={passenger.gender}
                      onChange={(e) => handleInputChange(index, 'gender', e.target.value)}
                      required
                    >
                      <option value="">{t('Select gender')}</option>
                      <option value="Male">{t('Male')}</option>
                      <option value="Female">{t('Female')}</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`passport-${index}`}>
                      <FaPassport className="icon" />
                      {t('Passport Number')}
                    </label>
                    <input
                      id={`passport-${index}`}
                      type="text"
                      value={passenger.passport}
                      onChange={(e) => handleInputChange(index, 'passport', e.target.value)}
                      placeholder={t('Enter passport number')}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`passportExpiryDate-${index}`}>
                      <FaCalendarAlt className="icon" />
                      {t('Passport Expiry Date')}
                    </label>
                    <input
                      id={`passportExpiryDate-${index}`}
                      type="date"
                      value={passenger.passportExpiryDate}
                      onChange={(e) => handleInputChange(index, 'passportExpiryDate', e.target.value)}
                      required
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                  <div className="form-group nationality-id-group">
                    <div className="nationality-field">
                      <label htmlFor={`nationality-${index}`}>
                        <FaGlobe className="icon" />
                        {t('Nationality')}
                      </label>
                      <select
                        id={`nationality-${index}`}
                        value={passenger.nationality}
                        onChange={(e) => handleInputChange(index, 'nationality', e.target.value)}
                        required
                      >
                        <option value="">{t('Select nationality')}</option>
                        {availableOrigins.map((origin, i) => (
                          <option key={origin.key} value={origin.key}>
                            {origin.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {passenger.nationality === 'Israeli' && (
                    <div className="form-group israeli-id-field">
                      <label htmlFor={`israeliId-${index}`}>
                        <FaIdCard className="icon" />
                        {t('Israeli ID Number')}
                      </label>
                      <input
                        id={`israeliId-${index}`}
                        type="text"
                        value={passenger.israeliId}
                        onChange={(e) => handleInputChange(index, 'israeliId', e.target.value)}
                        placeholder={t('Enter Israeli ID number')}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <button type="button" className="proceed-button" onClick={handleProceedToSeats}>
              {t('Proceed to Seat Selection')}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PassengerDetails;
