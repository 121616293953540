
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc, deleteDoc, query, where } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import ProgressTracker from './ProgressTracker';
import { MdOutlineRefresh } from 'react-icons/md';
import { VscGoToSearch } from "react-icons/vsc";
import '../../styles/Flights.css';

const Flights = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { searchParams, flightList } = location.state || {};
  const [exactMatchFlights, setExactMatchFlights] = useState([]);
  const [laterFlights, setLaterFlights] = useState([]);
  const [savedFlights, setSavedFlights] = useState([]);

 

  useEffect(() => {
    const fetchFlights = async () => {
      console.log("Fetching flights with searchParams:", searchParams);
      const flightsCollection = collection(db, 'flights');
      const flightSnapshot = await getDocs(flightsCollection);
      const allFlights = flightSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log("All flights:", allFlights);
  
      const filteredFlights = allFlights.filter(flight => {
        const isMatchingRoute = flight.routeSource === searchParams.origin &&
                                flight.routeDestination === searchParams.destination;
        const isMatchingClass = searchParams.flightClass === 'All' || 
          (searchParams.flightClass === 'Economy Class' && flight.economyClassRemainingSeats > 0) ||
          (searchParams.flightClass === 'Business Class' && flight.businessClassRemainingSeats > 0) ||
          (searchParams.flightClass === 'First Class' && flight.firstClassRemainingSeats > 0);
        const isMatchingType = searchParams.isRoundTrip === flight.isRoundTrip;
  
        console.log(`Flight ${flight.id}:`, { 
          isMatchingRoute, 
          isMatchingClass, 
          isMatchingType,
          economySeats: flight.economyClassRemainingSeats,
          businessSeats: flight.businessClassRemainingSeats,
          firstSeats: flight.firstClassRemainingSeats,
          searchParamsClass: searchParams.flightClass
        });
  
        return isMatchingRoute && isMatchingClass && isMatchingType;
      });
  
      console.log("Filtered flights:", filteredFlights);
  
      const specificDateFlights = filteredFlights.filter(flight => 
        flight.departureDate === searchParams.departureDate
      );
  
      const afterDepartureDateFlights = filteredFlights.filter(flight => 
        new Date(flight.departureDate) > new Date(searchParams.departureDate)
      );
  
      console.log("Specific date flights:", specificDateFlights);
      console.log("Later flights:", afterDepartureDateFlights);
  
      setExactMatchFlights(specificDateFlights);
      setLaterFlights(afterDepartureDateFlights);
    };
  
    fetchFlights();
  }, [searchParams]);


  const handleSaveOrRemoveFlight = async (flight) => {
    const savedFlight = savedFlights.find(savedFlight => savedFlight.id === flight.id);

    if (savedFlight) {
      const user = auth.currentUser;
      if (user) {
        const q = query(collection(db, 'users', user.uid, 'savedFlights'), where('id', '==', flight.id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (docSnapshot) => {
          await deleteDoc(docSnapshot.ref);
        });
        setSavedFlights(savedFlights.filter(savedFlight => savedFlight.id !== flight.id));
      }
    } else {
      const user = auth.currentUser;
      if (user) {
        await addDoc(collection(db, 'users', user.uid, 'savedFlights'), flight);
        setSavedFlights([...savedFlights, flight]);
      }
    }
  };

  const handleGoingBack = () => {
    navigate(-1);
  };

  const showDetails = (flight) => {
    navigate('/flight-tickets', { 
      state: { 
        selectedFlight: flight,
        isRoundTrip: searchParams.isRoundTrip,
        searchParams
      }, 
      replace: true 
    });
  };

  // const renderFlightCards = (flightList) => (
  //   <div className="flights-grid">
  //     {flightList.map((flight, index) => (
  //       <div className="flight-card" key={index}>
  //         <h3 className="flight-name">{flight.name}</h3>
  //         <div className="flight-details">
  //           <p><strong>{t('From')}:</strong> {flight.routeSource}</p>
  //           <p><strong>{t('To')}:</strong> {flight.routeDestination}</p>
  //           <p><strong>{t('Date')}:</strong> {flight.departureDate}</p>
  //           <p><strong>{t('Time')}:</strong> {flight.departureTime}</p>
  //           {flight.isRoundTrip && (
  //             <>
  //               <p><strong>{t('Return Date')}:</strong> {flight.returnDate}</p>
  //               <p><strong>{t('Return Time')}:</strong> {flight.returnTime}</p>
  //             </>
  //           )}
  //         </div>
  //         <div className="button-container">
  //           <button 
  //             className="details-btn1" 
  //             onClick={() => showDetails(flight)}
  //           >
  //             {t('Book this flight')}
  //           </button>
  //           <button 
  //             className={`save-btn1 ${savedFlights.some(savedFlight => savedFlight.id === flight.id) ? 'remove-btn1' : ''}`} 
  //             onClick={() => handleSaveOrRemoveFlight(flight)}
  //           >
  //             {savedFlights.some(savedFlight => savedFlight.id === flight.id) ? t('Remove Saved') : t('Save Flight')}
  //           </button>
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );

  const renderFlightCards = (flightList) => (
    <div className="flights-grid">
      {flightList.map((flight, index) => (
        <div className={`flight-card ${flight.isRoundTrip ? 'round-trip' : ''}`} key={index}>
          <h3 className="flight-name">{flight.name}</h3>
          <div className="flight-details-container">
            <div className="flight-details outbound">
              <h4>{t('Outbound Flight')}</h4>
              <p><strong>{t('From')}:</strong> {flight.routeSource}</p>
              <p><strong>{t('To')}:</strong> {flight.routeDestination}</p>
              <p><strong>{t('Date')}:</strong> {flight.departureDate}</p>
              <p><strong>{t('Time')}:</strong> {flight.departureTime}</p>
            </div>
            {flight.isRoundTrip && (
              <div className="flight-details return">
                <h4>{t('Return Flight')}</h4>
                <p><strong>{t('From')}:</strong> {flight.routeDestination}</p>
                <p><strong>{t('To')}:</strong> {flight.routeSource}</p>
                <p><strong>{t('Date')}:</strong> {flight.returnDate}</p>
                <p><strong>{t('Time')}:</strong> {flight.returnTime}</p>
              </div>
            )}
          </div>
          <div className="button-container">
            <button 
              className="details-btn1" 
              onClick={() => showDetails(flight)}
            >
              {t('Book this flight')}
            </button>
            <button 
              className={`save-btn1 ${savedFlights.some(savedFlight => savedFlight.id === flight.id) ? 'remove-btn1' : ''}`} 
              onClick={() => handleSaveOrRemoveFlight(flight)}
            >
              {savedFlights.some(savedFlight => savedFlight.id === flight.id) ? t('Remove Saved') : t('Save Flight')}
            </button>
          </div>
        </div>
      ))}
    </div>
  );


  return ( 
    <>
      <ProgressTracker currentStep={0} />
      <div className="flights-page">
        <div className="flights-container">
          <div className="flights-section">
            <h2 className="section-title">
              {searchParams.isRoundTrip ? t('Round Trip Flights') : t('One Way Flights')} - {t('(Exact Matches)')}
            </h2>
            {exactMatchFlights.length === 0 ? (
              <p className="no-flights">{t('No flights available for the selected date and criteria')}</p>
            ) : (
              renderFlightCards(exactMatchFlights)
            )}
          </div>

          <div className="flights-section">
            <h2 className="section-title">{t('Other Flights (After Departure Date)')}</h2>
            {laterFlights.length === 0 ? (
              <p className="no-flights">{t('No upcoming flights available')}</p>
            ) : (
              renderFlightCards(laterFlights)
            )}
          </div>

          <button className="search-again-btn" onClick={handleGoingBack}>
            {t('Search Again')}
            <span className="icon refresh-icon"><MdOutlineRefresh /></span>
            <span className="icon search-icon"><VscGoToSearch /></span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Flights;