// import React from 'react';
// import '../../styles/ProgressTracker.css';
// import { FaPlaneDeparture, FaTicketAlt, FaUser, FaCreditCard, FaCheckCircle } from 'react-icons/fa';
// import { PiSeatFill  } from "react-icons/pi";

// const ProgressTracker = ({ currentStep }) => {


//   const steps = [
//     { name: 'Flight selection', label: 'בחירת טיסה', icon: <FaPlaneDeparture className='ic-he'/> },
//     { name: 'Tickets Selection', label: 'בחירת כרטיסים', icon: <FaTicketAlt /> },
//     { name: 'Passenger details', label: 'פרטי נוסעים', icon: <FaUser /> },
//     { name: 'Seat selection', label: 'בחירת מושבים', icon: <PiSeatFill  className='ic-he'/> },
//     { name: 'Payment', label: 'תשלום', icon: <FaCreditCard className='ic-he'/> },
//     { name: 'Reception', label: 'אישור', icon: <FaCheckCircle /> },
//   ];

//   return (
//     <div className="progress-tracker-page">
//       <div className="progress-tracker-container">
//         <div className="progress-tracker">
//           {steps.map((step, index) => (
//             // <div key={index} className={`step ${index <= currentStep ? 'active' : ''}`}>
//             <div 
//               key={index} 
//               className={`step ${index <= currentStep ? 'active' : ''} 
//               ${index === steps.length - 1 && currentStep === steps.length - 1 ? 'last-step' : ''}`}
//             >
//               <div className="step-icon">{step.icon}</div>
//               <div className="step-label">{step.label}</div>
//               {index < steps.length - 1 && (
//                 <div className={`step-line ${index < currentStep ? 'active' : ''}`} />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgressTracker;







import React from 'react';
import '../../styles/ProgressTracker.css';
import { FaPlaneDeparture, FaTicketAlt, FaUser, FaCreditCard, FaCheckCircle } from 'react-icons/fa';
import { PiSeatFill } from "react-icons/pi";

const ProgressTracker = ({ currentStep }) => {
  const steps = [
    { name: 'Flight selection', label: 'בחירת טיסה', icon: <FaPlaneDeparture className='ic-he'/> },
    { name: 'Tickets Selection', label: 'בחירת כרטיסים', icon: <FaTicketAlt /> },
    { name: 'Passenger details', label: 'פרטי נוסעים', icon: <FaUser /> },
    { name: 'Seat selection', label: 'בחירת מושבים', icon: <PiSeatFill className='ic-he'/> },
    { name: 'Payment', label: 'תשלום', icon: <FaCreditCard className='ic-he'/> },
    { name: 'Reception', label: 'אישור', icon: <FaCheckCircle /> },
  ];

  return (
    <div className="progress-tracker-page">
      <div className="progress-tracker-container">
        <div className="progress-tracker">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className={`step ${index <= currentStep ? 'active' : ''} 
              ${index === steps.length - 1 && currentStep === steps.length - 1 ? 'last-step' : ''}`}
            >
              <div className="step-icon">{step.icon}</div>
              <div className="step-label">{step.label}</div>
              {index < steps.length - 1 && (
                <div className={`step-line ${index < currentStep ? 'active' : ''}`} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressTracker;