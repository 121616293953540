// VisaPayment.js
import { useState, useEffect } from 'react';

const makeVisaApiCall = async (endpoint, data) => {
  const response = await fetch(`https://sandbox.api.visa.com/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(process.env.REACT_APP_VISA_USER_ID + ':' + process.env.REACT_APP_VISA_PASSWORD)
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

const VisaPayment = ({ amount, onPaymentSuccess, onPaymentError, autoInitialize = true }) => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false);

  const initializeSDK = () => {
    const loadVisaCheckoutSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/integration/v1/sdk.js';
      script.async = true;
      script.onload = () => {
        setIsSDKLoaded(true);
        initializeVisaCheckout();
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadVisaCheckoutSDK();
  };

  useEffect(() => {
    if (autoInitialize) {
      initializeSDK();
    }
  }, [autoInitialize]);

  useEffect(() => {
    if (isSDKLoaded) {
      initializeVisaCheckout();
    }
  }, [isSDKLoaded, amount]);

  const initializeVisaCheckout = () => {
    if (window.V) {
      window.V.init({
        apikey: process.env.REACT_APP_VISA_API_KEY,
        paymentRequest: {
          currencyCode: "USD",
          subtotal: amount
        }
      });

      window.V.on("payment.success", handleVisaPaymentSuccess);
      window.V.on("payment.error", handleVisaPaymentError);
      window.V.on("payment.cancel", handleVisaPaymentCancel);
    }
  };

  const handleVisaPaymentSuccess = (payment) => {
    console.log('Visa payment successful:', payment);
    onPaymentSuccess(payment);
  };

  const handleVisaPaymentError = (error, payment) => {
    console.error('Visa payment error:', error);
    onPaymentError(error);
  };

  const handleVisaPaymentCancel = () => {
    console.log('Visa payment cancelled');
    onPaymentError(new Error('Payment cancelled'));
  };

  const openVisaCheckout = () => {
    if (window.V) {
      window.V.open();
    }
  };

  return { openVisaCheckout, isSDKLoaded, initializeSDK };
};

// export const validateVisaPayment = (payment) => {
//   // Implement your validation logic here
//   // This is a placeholder and should be replaced with actual validation
//   return payment && payment.callid && payment.encryptedPaymentData;
// };

// export const processVisaPayment = async (payment) => {
//   // This function would typically make an API call to your backend
//   // to process the payment. For now, we'll simulate this with a Promise.
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (validateVisaPayment(payment)) {
//         resolve({ success: true, transactionId: 'mock-transaction-id' });
//       } else {
//         reject(new Error('Invalid payment data'));
//       }
//     }, 1000);
//   });
// };

export const processVisaPayment = async (payment) => {
  try {
    // First, validate the payment data
    if (!validateVisaPayment(payment)) {
      throw new Error('Invalid payment data');
    }

    // Make the API call to process the payment
    const result = await makeVisaApiCall('payments/v1/authorizations', {
      amount: payment.amount,
      // Add other necessary payment details here
      // For example:
      // cardNumber: payment.cardNumber,
      // expirationDate: payment.expirationDate,
      // cvv: payment.cvv,
      // etc.
    });

    if (result.status === 'AUTHORIZED') {
      return { success: true, transactionId: result.id };
    } else {
      throw new Error('Payment not authorized');
    }
  } catch (error) {
    console.error('Error processing Visa payment:', error);
    return { success: false, error: error.message };
  }
};

// Keep the existing validateVisaPayment function
export const validateVisaPayment = (payment) => {
  // Implement your validation logic here
  // This is a placeholder and should be replaced with actual validation
  return payment && payment.callid && payment.encryptedPaymentData;
};

export default VisaPayment;