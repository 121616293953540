import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import ProgressTracker from './ProgressTracker';
import Swal from 'sweetalert2';
import '../../styles/SeatSelection.css';

export default function Component() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedFlight, setSelectedFlight] = useState(null);
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const [isRoundTrip, setIsRoundTrip] = useState(false);

  const [seatConfig, setSeatConfig] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [instructions, setInstructions] = useState('');

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, '', '/seat-selection');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!location.state) {
      navigate('/book-flight', { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);

      if (!location.state) {
        setError(t('Missing state data. Please start the booking process again.'));
        setIsLoading(false);
        return;
      }

      const { selectedFlight, totalTickets, tickets, bookingData, isRoundTrip } = location.state;

      if (!selectedFlight || !totalTickets || !tickets || !bookingData) {
        setError(t('Missing required data for seat selection. Please start the booking process again.'));
        setIsLoading(false);
        return;
      }

      setSelectedFlight(selectedFlight);
      setTotalTickets(totalTickets);
      setTickets(tickets);
      setBookingData(bookingData);
      setPassengers(bookingData.passengers);
      setIsRoundTrip(isRoundTrip);

      try {
        const airplaneTypesCollection = collection(db, 'airplaneTypes');
        const airplaneTypesSnapshot = await getDocs(airplaneTypesCollection);
        const airplaneTypesList = airplaneTypesSnapshot.docs.map(doc => doc.data());

        const selectedAirplaneConfig = airplaneTypesList.find(
          (type) => type.name === selectedFlight.airplaneType
        );

        if (selectedAirplaneConfig) {
          setSeatConfig(selectedAirplaneConfig);
        } else {
          throw new Error(t('Airplane configuration not found for type:') + selectedFlight.airplaneType);
        }

        const flightDocRef = doc(db, 'flights', selectedFlight.id);
        const flightDocSnap = await getDoc(flightDocRef);
        if (flightDocSnap.exists()) {
          setReservedSeats(flightDocSnap.data().reservedSeats || []);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setError(t('Error loading seat selection data. Please try again.'));
        setIsLoading(false);
      }
    };

    loadData();
  }, [location, t]);

  useEffect(() => {
    if (!seatConfig || !tickets) return;

    const ticketsByClass = tickets.reduce((acc, ticket) => {
      acc[ticket.className] = (acc[ticket.className] || 0) + 1;
      return acc;
    }, {});

    let instructionsText = t('Please select: ');
    Object.entries(ticketsByClass).forEach(([className, count]) => {
      instructionsText += `${count} ${t('seats in {{className}}', { className })}, `;
    });
    instructionsText = instructionsText.slice(0, -2) + '.';

    setInstructions(instructionsText);
  }, [seatConfig, tickets, t]);

  const getSeatLabel = useCallback((classType, rowIndex, seatIndex, seatsPerRow) => {
    const rowNumber = rowIndex + 1;
    const seatLetter = String.fromCharCode(70 - seatIndex); // Changed to reverse order (F to A)
    return `${rowNumber}${seatLetter}`;
  }, []);

  const handleSeatClick = useCallback((seatLabel, seatClass) => {
    const fullSeatLabel = `${seatClass}-${seatLabel}`;
    if (reservedSeats.includes(fullSeatLabel)) {
      return;
    }

    const classMap = { 'A': t('First'), 'B': t('Business'), 'C': t('Economy') };
    const fullClassName = classMap[seatClass];
    const seatsInClass = selectedSeats.filter(seat => seat.startsWith(seatClass));
    const maxSeatsAllowed = tickets.filter(ticket => ticket.className === fullClassName).length;

    setSelectedSeats(prevSeats => {
      if (prevSeats.includes(fullSeatLabel)) {
        return prevSeats.filter(seat => seat !== fullSeatLabel);
      } else if (seatsInClass.length < maxSeatsAllowed) {
        return [...prevSeats, fullSeatLabel];
      }
      return prevSeats;
    });
  }, [reservedSeats, selectedSeats, tickets, t]);

  // const renderSeats = useCallback((classType, rows, seatsPerRow) => {
  //   return Array.from({ length: rows }).map((_, rowIndex) => (
  //     <div key={`${classType}-${rowIndex}`} className="seat-selection-seat-row">
  //       {Array.from({ length: seatsPerRow }).reverse().map((_, seatIndex) => {
  //         const seatLabel = getSeatLabel(classType, rowIndex, seatIndex, seatsPerRow);
  //         const fullSeatLabel = `${classType}-${seatLabel}`;

  //         return (
  //           <React.Fragment key={`${classType}-${rowIndex}-${seatIndex}`}>
  //             {seatIndex === 3 && <div className="seat-selection-aisle"></div>}
  //             <button
  //               className={`seat-selection-seat ${selectedSeats.includes(fullSeatLabel) ? 'selected' : ''} ${reservedSeats.includes(fullSeatLabel) ? 'reserved' : ''}`}
  //               onClick={() => handleSeatClick(seatLabel, classType)}
  //               disabled={reservedSeats.includes(fullSeatLabel)}
  //             >
  //               {seatLabel}
  //             </button>
  //           </React.Fragment>
  //         );
  //       })}
  //     </div>
  //   ));
  // }, [getSeatLabel, handleSeatClick, reservedSeats, selectedSeats]);

  const renderSeats = useCallback((classType, rows, seatsPerRow) => {
    return Array.from({ length: rows }).map((_, rowIndex) => (
      <div key={`${classType}-${rowIndex}`} className="seat-selection-seat-row">
        {Array.from({ length: seatsPerRow }).map((_, seatIndex) => {
          const seatLabel = getSeatLabel(classType, rowIndex, seatIndex, seatsPerRow);
          const fullSeatLabel = `${classType}-${seatLabel}`;
  
          // Calculate aisle position based on the number of seats per row
          const aisleIndex = seatsPerRow === 6 ? 3 : (seatsPerRow === 4 ? 2 : null);
  
          return (
            <React.Fragment key={`${classType}-${rowIndex}-${seatIndex}`}>
              {seatIndex === aisleIndex && <div className="seat-selection-aisle"></div>}
              <button
                className={`seat-selection-seat ${selectedSeats.includes(fullSeatLabel) ? 'selected' : ''} ${reservedSeats.includes(fullSeatLabel) ? 'reserved' : ''}`}
                onClick={() => handleSeatClick(seatLabel, classType)}
                disabled={reservedSeats.includes(fullSeatLabel)}
              >
                {seatLabel}
              </button>
            </React.Fragment>
          );
        })}
      </div>
    ));
  }, [getSeatLabel, handleSeatClick, reservedSeats, selectedSeats]);
  

  const handleConfirmSeats = () => {
    if (selectedSeats.length !== totalTickets) {
      Swal.fire({
        icon: 'warning',
        title: t('seats selection warning'),
        text: t(`Please select exactly ${totalTickets} seats.`),
        
      });

      return;
    }

    let newSeatCounts = {
      economyClassRemainingSeats: selectedFlight.economyClassRemainingSeats,
      businessClassRemainingSeats: selectedFlight.businessClassRemainingSeats,
      firstClassRemainingSeats: selectedFlight.firstClassRemainingSeats,
    };

    selectedSeats.forEach(seat => {
      const [seatClass] = seat.split('-');
      if (seatClass === 'A') newSeatCounts.firstClassRemainingSeats -= 1;
      else if (seatClass === 'B') newSeatCounts.businessClassRemainingSeats -= 1;
      else if (seatClass === 'C') newSeatCounts.economyClassRemainingSeats -= 1;
    });

    navigate('/Payment', {
      state: {
        selectedFlight,
        selectedSeats,
        tickets,
        bookingData: {
          ...bookingData,
          selectedSeats,
          newSeatCounts
        },
        passengers,
        isRoundTrip
      },
      replace: true
    });
  };

  if (isLoading) {
    return <div>{t('Loading seat selection...')}</div>;
  }

  if (error) {
    return <div className="seat-selection-error-message">{error}</div>;
  }

  if (!seatConfig) {
    return <div>{t('Unable to load seat configuration. Please try again.')}</div>;
  }

  return (
    <>
      <ProgressTracker currentStep={3} />

      <div className="seat-selection-footer-left">
          <button className="seat-selection-confirm-seats-button" onClick={handleConfirmSeats}>
            {t('Confirm Seats and continue')}
          </button>
      </div>

      <div className="seat-selection-container">
        <div className="seat-selection-instructions-text">
          <h2>{t('Seat Selection Instructions')}</h2>
          <p>{instructions}</p>
          {isRoundTrip && <p>{t('Selected seats will apply to both outbound and return flights.')}</p>}
        </div>

        {seatConfig.firstClass && seatConfig.firstClass.rows > 0 && (
          <div className="seat-selection-seat-class-section">
            <h3>{t('First Class')}</h3>
            <div className="seat-selection-seat-grid">
              {renderSeats('A', seatConfig.firstClass.rows, 4)}
            </div>
          </div>
        )}

        {seatConfig.businessClass && seatConfig.businessClass.rows > 0 && (
          <div className="seat-selection-seat-class-section">
            <h3>{t('Business Class')}</h3>
            <div className="seat-selection-seat-grid">
              {renderSeats('B', seatConfig.businessClass.rows, 6)}
            </div>
          </div>
        )}

        {seatConfig.economyClass && seatConfig.economyClass.rows > 0 && (
          <div className="seat-selection-seat-class-section">
            <h3>{t('Economy Class')}</h3>
            <div className="seat-selection-seat-grid">
              {renderSeats('C', seatConfig.economyClass.rows, 6)}
            </div>
          </div>
        )}

      </div>
    </>
  );
}