// import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
// import { doc, collection, addDoc, query, onSnapshot, getDocs, orderBy, updateDoc, getDoc, arrayUnion } from 'firebase/firestore';
// import { db } from '../config/firebase';
// import '../styles/AdminChatRooms.css';

// const TypingIndicator = memo(() => (
//   <div className="admin-chat-rooms-typing-indicator">
//     <span></span>
//     <span></span>
//     <span></span>
//   </div>
// ));

// const AdminChatRooms = () => {
//   const [rooms, setRooms] = useState([]);
//   const [selectedRoom, setSelectedRoom] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');
//   const [isTyping, setIsTyping] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const messagesEndRef = useRef(null);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const usersRef = collection(db, 'users');
//         const usersSnapshot = await getDocs(usersRef);
//         const usersData = {};
//         usersSnapshot.forEach((doc) => {
//           usersData[doc.id] = doc.data().name;
//         });
//         return usersData;
//       } catch (error) {
//         console.error('Error fetching users:', error);
//         return {};
//       }
//     };

//     const fetchRooms = async () => {
//       try {
//         const usersData = await fetchUsers();
//         const q = query(collection(db, 'messages'));
//         const unsubscribe = onSnapshot(q, (querySnapshot) => {
//           const roomsData = querySnapshot.docs.map((doc) => {
//             const data = doc.data();
//             return {
//               id: doc.id,
//               name: usersData[doc.id] || 'Unknown User',
//               unreadCount: (data.unreadMessages || []).length,
//               ...data,
//             };
//           });

//           roomsData.sort((a, b) => b.unreadCount - a.unreadCount);
//           setRooms(roomsData);
//           setIsLoading(false);
//         });
//         return () => unsubscribe();
//       } catch (error) {
//         console.error('Error fetching rooms:', error);
//         setIsLoading(false);
//       }
//     };

//     fetchRooms();
//   }, []);

//   const handleRoomSelect = useCallback(async (uid) => {
//     setSelectedRoom(uid);
//     try {
//       const userDocRef = doc(db, 'messages', uid);
//       const chatCollectionRef = collection(userDocRef, 'chat');
//       const q = query(chatCollectionRef, orderBy('createdAt'));
  
//       const unsubscribe = onSnapshot(q, async (querySnapshot) => {
//         const msgs = querySnapshot.docs.map(doc => doc.data());
//         setMessages(msgs);
  
//         const userDoc = await getDoc(userDocRef);
//         if (userDoc.exists()) {
//           const { unreadMessages = [] } = userDoc.data();
//           let updatedUnreadMessages = unreadMessages;
  
//           if (unreadMessages.length > 0) {
//             updatedUnreadMessages = unreadMessages.filter(msg => msg.isAutoReply);
//             const readMessages = unreadMessages.filter(msg => !msg.isAutoReply).map(msg => ({...msg, read: true}));
            
//             await updateDoc(userDocRef, {
//               unreadMessages: updatedUnreadMessages,
//               readMessages: arrayUnion(...readMessages)
//             });
//           }
          
//           setRooms(prevRooms => 
//             prevRooms.map(room => 
//               room.id === uid 
//                 ? { ...room, unreadCount: updatedUnreadMessages.length } 
//                 : room
//             )
//           );
//         }
//       });
  
//       return () => unsubscribe();
//     } catch (error) {
//       console.error('Error selecting room:', error);
//     }
//   }, []);

//   const sendMessage = async () => {
//     if (newMessage.trim() !== '' && selectedRoom) {
//       setIsTyping(false);
//       try {
//         const userDocRef = doc(db, 'messages', selectedRoom);
//         const chatCollectionRef = collection(userDocRef, 'chat');

//         const newMessageObj = {
//           text: newMessage,
//           sender: 'agent',
//           createdAt: new Date(),
//           read: true,
//         };

//         await addDoc(chatCollectionRef, newMessageObj);
//         await updateDoc(userDocRef, {
//           readMessages: arrayUnion(newMessageObj),
//         });

//         setNewMessage('');
//       } catch (error) {
//         console.error('Error sending message:', error);
//       }
//     }
//   };

//   const handleTyping = useCallback(() => {
//     setIsTyping(true);
//     const timer = setTimeout(() => setIsTyping(false), 3000);
//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [messages]);

//   if (isLoading) {
//     return <div className="admin-chat-rooms-loading">Loading...</div>;
//   }

//   return (
//     <div className="admin-chat-rooms">
//       <div className="admin-chat-rooms-list">
//         <h1>Admin Chat Rooms</h1>
//         {rooms.map((room) => (
//           <div
//             key={room.id}
//             className={`admin-chat-rooms-room ${selectedRoom === room.id ? 'selected' : ''}`}
//             onClick={() => handleRoomSelect(room.id)}
//           >
//             <h2>User: {room.name}</h2>
//             {room.unreadCount > 0 && (
//               <span className="admin-chat-rooms-unread-count">{room.unreadCount}</span>
//             )}
//           </div>
//         ))}
//       </div>
//       {selectedRoom && (
//         <div className="admin-chat-rooms-details">
//           <h2>
//             Chat with User: {rooms.find((room) => room.id === selectedRoom)?.name || 'Unknown User'}
//           </h2>
//           <div className="admin-chat-rooms-messages">
//             {messages.map((msg, index) => (
//               <div key={index} className={`admin-chat-rooms-message ${msg.sender}`}>
//                 {msg.text}
//               </div>
//             ))}
//             {isTyping && <TypingIndicator />}
//             <div ref={messagesEndRef} />
//           </div>
//           <div className="admin-chat-rooms-message-input">
//             <input
//               type="text"
//               value={newMessage}
//               onChange={(e) => setNewMessage(e.target.value)}
//               onKeyDown={handleTyping}
//               placeholder="Type your message here..."
//             />
//             <button onClick={sendMessage}>Send</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AdminChatRooms;
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { doc, collection, addDoc, query, onSnapshot, getDocs, orderBy, updateDoc, getDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useTranslation } from 'react-i18next';
import '../styles/AdminChatRooms.css';

const TypingIndicator = memo(() => (
  <div className="admin-chat-rooms-typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
));

const AdminChatRooms = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const usersData = {};
        usersSnapshot.forEach((doc) => {
          usersData[doc.id] = doc.data().name;
        });
        return usersData;
      } catch (error) {
        console.error(t('Error fetching users:'), error);
        return {};
      }
    };

    const fetchRooms = async () => {
      try {
        const usersData = await fetchUsers();
        const q = query(collection(db, 'messages'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const roomsData = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              name: usersData[doc.id] || t('Unknown User'),
              unreadCount: (data.unreadMessages || []).length,
              ...data,
            };
          });

          roomsData.sort((a, b) => b.unreadCount - a.unreadCount);
          setRooms(roomsData);
          setIsLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error(t('Error fetching rooms:'), error);
        setIsLoading(false);
      }
    };

    fetchRooms();
  }, [t]);

  const handleRoomSelect = useCallback(async (uid) => {
    setSelectedRoom(uid);
    try {
      const userDocRef = doc(db, 'messages', uid);
      const chatCollectionRef = collection(userDocRef, 'chat');
      const q = query(chatCollectionRef, orderBy('createdAt'));
  
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const msgs = querySnapshot.docs.map(doc => doc.data());
        setMessages(msgs);
  
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const { unreadMessages = [] } = userDoc.data();
          let updatedUnreadMessages = unreadMessages;
  
          if (unreadMessages.length > 0) {
            updatedUnreadMessages = unreadMessages.filter(msg => msg.isAutoReply);
            const readMessages = unreadMessages.filter(msg => !msg.isAutoReply).map(msg => ({...msg, read: true}));
            
            await updateDoc(userDocRef, {
              unreadMessages: updatedUnreadMessages,
              readMessages: arrayUnion(...readMessages)
            });
          }
          
          setRooms(prevRooms => 
            prevRooms.map(room => 
              room.id === uid 
                ? { ...room, unreadCount: updatedUnreadMessages.length } 
                : room
            )
          );
        }
      });
  
      return () => unsubscribe();
    } catch (error) {
      console.error(t('Error selecting room:'), error);
    }
  }, [t]);

  const sendMessage = async () => {
    if (newMessage.trim() !== '' && selectedRoom) {
      setIsTyping(false);
      try {
        const userDocRef = doc(db, 'messages', selectedRoom);
        const chatCollectionRef = collection(userDocRef, 'chat');

        const newMessageObj = {
          text: newMessage,
          sender: 'agent',
          createdAt: new Date(),
          read: true,
        };

        await addDoc(chatCollectionRef, newMessageObj);
        await updateDoc(userDocRef, {
          readMessages: arrayUnion(newMessageObj),
        });

        setNewMessage('');
      } catch (error) {
        console.error(t('Error sending message:'), error);
      }
    }
  };

  const handleTyping = useCallback(() => {
    setIsTyping(true);
    const timer = setTimeout(() => setIsTyping(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  if (isLoading) {
    return <div className="admin-chat-rooms-loading">{t('Loading...')}</div>;
  }

  return (
    <div className="admin-chat-rooms">
      <div className="admin-chat-rooms-list">
        <h1>{t('Admin Chat Rooms')}</h1>
        {rooms.map((room) => (
          <div
            key={room.id}
            className={`admin-chat-rooms-room ${selectedRoom === room.id ? 'selected' : ''}`}
            onClick={() => handleRoomSelect(room.id)}
          >
            <h2>{t('User')}: {room.name}</h2>
            {room.unreadCount > 0 && (
              <span className="admin-chat-rooms-unread-count">{room.unreadCount}</span>
            )}
          </div>
        ))}
      </div>
      {selectedRoom && (
        <div className="admin-chat-rooms-details">
          <h2>
            {t('Chat with User')}: {rooms.find((room) => room.id === selectedRoom)?.name || t('Unknown User')}
          </h2>
          <div className="admin-chat-rooms-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`admin-chat-rooms-message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
            {isTyping && <TypingIndicator />}
            <div ref={messagesEndRef} />
          </div>
          <div className="admin-chat-rooms-message-input">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleTyping}
              placeholder={t('Type your message here...')}
            />
            <button onClick={sendMessage}>{t('Send')}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminChatRooms;
