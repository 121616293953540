import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { getAuth, deleteUser, sendPasswordResetEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaTrash, FaSort, FaEnvelope } from 'react-icons/fa';
import '../../styles/ManageUsers.css';

export default function Component() {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('displayName');
    const [sortDirection, setSortDirection] = useState('asc');
    const [openDropdown, setOpenDropdown] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'users');
            const usersSnapshot = await getDocs(usersCollection);
            const usersList = usersSnapshot.docs.map(doc => {
                const userData = doc.data();
                const displayName = userData.name || t('User');
                return { 
                    id: doc.id, 
                    displayName: displayName,
                    email: userData.email || '',
                    phoneNumber: userData.phoneNumber || '',
                    ...userData 
                };
            });
            setUsers(usersList);
        };

        fetchUsers();
    }, [t]);

    const handleDeleteUser = async (userId, userEmail) => {
        const result = await Swal.fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Yes, delete it!')
        });

        if (result.isConfirmed) {
            try {
                await deleteDoc(doc(db, 'users', userId));
                const authInstance = getAuth();
                const user = authInstance.currentUser;
                if (user && user.email === userEmail) {
                    await deleteUser(user);
                }
                setUsers(users.filter(user => user.id !== userId));
                Swal.fire(t('Deleted!'), t('The user has been deleted.'), 'success');
            } catch (error) {
                console.error('Error deleting user:', error);
                Swal.fire(t('Error!'), t('There was an issue deleting the user. Please try again later.'), 'error');
            }
        }
    };


    const handleResetPassword = async (userEmail) => {
        const result = await Swal.fire({
          title: t('Confirm Password Reset'),
          text: t('Are you sure you want to send a password reset email to {{email}}?', { email: userEmail }),
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t('Yes, send the mail!'),
          cancelButtonText: t('Cancel')
        });
    
        if (result.isConfirmed) {
          try {
            await sendPasswordResetEmail(auth, userEmail);
            await Swal.fire({
              title: t('Successfully sent!'),
              text: t('Password reset email has been sent to {{email}}.', { email: userEmail }),
              icon: 'success'
            });
          } catch (error) {
            console.error('Error sending password reset email:', error);
            await Swal.fire({
              title: t('Error!'),
              text: t('There was an issue sending the password reset email. Please try again later.'),
              icon: 'error'
            });
          }
        }
    };    

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const toggleDropdown = (userId) => {
        setOpenDropdown(openDropdown === userId ? null : userId);
    };

    const filteredAndSortedUsers = users
        .filter(user =>
            user.displayName.toLowerCase().includes(searchTerm) ||
            user.email.toLowerCase().includes(searchTerm) ||
            user.phoneNumber.includes(searchTerm)
        )
        .sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <div className="manage-users-container">
            <h1>{t('User Management')}</h1>
            <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                    type="text"
                    className="search-input"
                    placeholder={t('Search')}
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <div className="table-container">
                <table className="users-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('displayName')}>
                                {t('Name')} <FaSort className="sort-icon" />
                            </th>
                            <th onClick={() => handleSort('email')}>
                                {t('Email')} <FaSort className="sort-icon" />
                            </th>
                            <th onClick={() => handleSort('phoneNumber')}>
                                {t('Phone Number')} <FaSort className="sort-icon" />
                            </th>
                            <th>{t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAndSortedUsers.map(user => (
                            <tr key={user.id}>
                                <td>{user.displayName}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                                <td>

                                <div className="action-button-container">
                                    <button
                                        className="action-button"
                                        onClick={() => handleResetPassword(user.email)}
                                    >
                                        <FaEnvelope />
                                        <span className="tooltip">{t('Send Password Reset Email')}</span>
                                    </button>
                                    <button
                                        className="action-button delete"
                                        onClick={() => handleDeleteUser(user.id, user.email)}
                                    >
                                        <FaTrash />
                                        <span className="tooltip">{t('Delete Account')}</span>
                                    </button>
                                </div>
                                    {/* <button
                                        className="action-button"
                                        onClick={() => handleResetPassword(user.email)}
                                        title={t('Send Password Reset Email')}
                                    >
                                        <FaEnvelope />
                                    </button>
                                    <button
                                        className="action-button delete"
                                        onClick={() => handleDeleteUser(user.id, user.email)}
                                        title={t('Delete Account')}
                                    >
                                        <FaTrash />
                                    </button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}






