import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';
import '../../styles/SavedOrders.css';
import { Clock } from 'lucide-react';

const SavedOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [savedOrders, setSavedOrders] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const fetchSavedOrders = async () => {
      if (!auth.currentUser) return;

      const q = query(
        collection(db, 'users', auth.currentUser.uid, 'savedOrders'),
        where('expiresAt', '>', new Date())
      );

      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSavedOrders(orders);
    };

    fetchSavedOrders();

    // Update current time every second
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);

    return () => clearInterval(timer);
  }, []);

  const handleResume = (order) => {
    navigate('/payment', { state: order });
  };

  const handleDelete = async (orderId) => {
    try {
      await deleteDoc(doc(db, 'users', auth.currentUser.uid, 'savedOrders', orderId));
      setSavedOrders(savedOrders.filter(order => order.id !== orderId));
    } catch (error) {
      console.error('Error deleting saved order:', error);
      alert(t('Failed to delete the saved order. Please try again.'));
    }
  };

  const formatTimeLeft = (expiresAt) => {
    if (!expiresAt || !expiresAt.toDate) return '00:00:00';
    
    const timeLeft = expiresAt.toDate() - currentTime;
    if (timeLeft <= 0) return '00:00:00';

    const hours = Math.floor(timeLeft / 3600000);
    const minutes = Math.floor((timeLeft % 3600000) / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="saved-orders">
      <h2>{t('Saved Orders')}</h2>
      {savedOrders.length === 0 ? (
        <p className="no-orders">{t('You have no saved orders.')}</p>
      ) : (
        savedOrders.map(order => (
          <div key={order.id} className="saved-order-item">
            <div className="order-header">
              <h3>{t('Booking ID')}: {order.bookingData?.bookingId || t('N/A')}</h3>
              <div className="expiration">
                <Clock className="clock-icon" />
                <span>{formatTimeLeft(order.expiresAt)}</span>
              </div>
            </div>
            <div className="order-details">
              <p>{t('From')}: {order.selectedFlight?.routeSource || t('N/A')}</p>
              <p>{t('To')}: {order.selectedFlight?.routeDestination || t('N/A')}</p>
              <p>{t('Date')}: {order.selectedFlight?.departureDate || t('N/A')}</p>
              <p>{t('Time')}: {order.selectedFlight?.departureTime || t('N/A')}</p>
              <p>{t('Total Cost')}: ${order.bookingData?.totalCost?.toFixed(2) || t('N/A')}</p>
            </div>
            <div className="order-actions">
              <button className="resume-btn" onClick={() => handleResume(order)}>{t('Resume Booking')}</button>
              <button className="delete-btn" onClick={() => handleDelete(order.id)}>{t('Delete')}</button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SavedOrders;