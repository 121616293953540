import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../../config/firebase';
import { updateProfile, onAuthStateChanged, reauthenticateWithCredential, EmailAuthProvider, updatePassword, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { deleteUser } from "firebase/auth";

import OrderHistory from './OrderHistory';
import SavedFlights from './SavedFlights'; 
import SettingsPage from './SettingsPage'; 
import SavedOrders from './SavedOrders';

import { FaPlane, FaHistory, FaUser, FaLock, FaChartLine, FaCreditCard, FaLink, FaCog, FaLanguage, FaBars, FaClock } from 'react-icons/fa';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import '../../styles/AccountPage.css';

function AccountPage() {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [activeSection, setActiveSection] = useState('personal');
    const [activePage, setActivePage] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [fetchingUserData, setFetchingUserData] = useState(true);

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
          if (currentUser) {
            setLoading(true);
            try {
              const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
              if (userDoc.exists() && !currentUser.emailVerified) {
                await signOut(auth);
                Swal.fire({
                  icon: 'warning',
                  title: t('Email Not Verified'),
                  text: t('Please verify your email to access your account.'),
                }).then(() => {
                  navigate('/auth');
                });
              } else {
                setUser(currentUser);
                setDisplayName(currentUser.displayName || '');
                setEmail(currentUser.email);
                setPhoneNumber(currentUser.phoneNumber || '');
                await fetchUserData(currentUser.uid);
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              Swal.fire({
                icon: 'error',
                title: t('Error'),
                text: t('Failed to load user data. Please try again.'),
              });
            } finally {
              setLoading(false);
            }
          } else {
            navigate('/auth');
          }
          setFetchingUserData(false);
        });
      
        return () => unsubscribe();
      }, [navigate, t]);


    const fetchUserData = async (uid) => {
        setFetchingUserData(true);
        try {
          const docRef = doc(db, 'users', uid);
          const docSnap = await getDoc(docRef);
      
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setDisplayName(userData.name || '');
            setEmail(userData.email || '');
            setPhoneNumber(userData.phoneNumber || '');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('Failed to fetch user data. Please try again.'),
          });
        } finally {
          setFetchingUserData(false);
        }
      };

    const handleUpdateProfile = async () => {
        if (!displayName || !email) {
            Swal.fire({
                icon: 'error',
                title: t('Oops...'),
                text: t('Name and Email are required!'),
            });
            return;
        }

        setLoading(true);
        try {
            const userRef = doc(db, 'users', user.uid);
            await setDoc(userRef, {
                name: displayName, 
                email, 
                phoneNumber 
            }, { merge: true });

            await updateProfile(auth.currentUser, {
                displayName,
                phoneNumber,
            });

            Swal.fire({
                icon: 'success',
                title: t('Success'),
                text: t('Your profile has been updated!'),
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            Swal.fire({
                icon: 'error',
                title: t('Error updating profile'),
                text: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePassword = () => {
        if (!password) {
            Swal.fire({
                icon: 'error',
                title: t('Error'),
                text: t('Please enter your current password.'),
            });
            return;
        }

        setLoading(true);
        const credential = EmailAuthProvider.credential(user.email, password);

        reauthenticateWithCredential(user, credential)
            .then(() => {
                updatePassword(user, newPassword).then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: t('Success'),
                        text: t('Password updated successfully!'),
                    });
                    setPassword('');
                    setNewPassword('');
                }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: t('Error'),
                        text: error.message,
                    });
                });
            })
            .catch(error => {
                console.error('Error reauthenticating:', error);
                Swal.fire({
                    icon: 'error',
                    title: t('Error reauthenticating'),
                    text: t('Failed to reauthenticate. Please check your current password.'),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSignOut = () => {
        setLoading(true);
        auth.signOut().then(() => {
          navigate('/auth');
        }).catch((error) => {
          console.error('Error signing out:', error);
          Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('Failed to sign out. Please try again.'),
          });
        }).finally(() => {
          setLoading(false);
        });
    };

    const handleDeleteAccount = async () => {
        const result = await Swal.fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Yes, delete it!')
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                await deleteDoc(doc(db, "users", user.uid));
                await deleteUser(user);
                Swal.fire({
                    icon: 'success',
                    title: t('Success'),
                    text: t('Your account has been successfully deleted.'),
                });
                navigate('/');
            } catch (error) {
                console.error('Error deleting user:', error);
                Swal.fire({
                    icon: 'error',
                    title: t('Error'),
                    text: t('Failed to delete your account. Please try again later.'),
                });
            } finally {
                setLoading(false);
            }
        }
    };

    // const handleLanguageChange = (language) => {
    //     i18n.changeLanguage(language);
    // };

    const handleLanguageChange = async (language) => {
        setLoading(true);
        try {
          await i18n.changeLanguage(language);
          localStorage.setItem('i18nextLng', language);
          if (user) {
            const userRef = doc(db, 'users', user.uid, 'settings', 'preferences');
            await setDoc(userRef, { language }, { merge: true });
          }
          Swal.fire({
            icon: 'success',
            title: t('Success'),
            text: t('Language changed successfully!'),
          });
        } catch (error) {
          console.error('Error changing language:', error);
          Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('Failed to change language. Please try again.'),
          });
        } finally {
          setLoading(false);
        }
      };


    if (loading || fetchingUserData) {
        return (
            <div className="loading-container">
                <PuffLoader color="#3498db" loading={loading} size={60} />
            </div>
        );
    }

    if (!user) {
        return <div className="auth-page">{t('Please log in to access your account.')}</div>;
    }

    const menuItems = [
        { id: 'flights', icon: <FaPlane />, label: t('Saved Flights') },
        { id: 'orderHistory', icon: <FaHistory />, label: t('Order History') },
        { id: 'savedOrders', icon: <FaClock />, label: t('Saved Orders') },
        { id: 'personal', icon: <FaUser />, label: t('Personal Information') },
        { id: 'security', icon: <FaLock />, label: t('Security Settings') },
        { id: 'activity', icon: <FaChartLine />, label: t('Account Activity') },
        { id: 'subscriptions', icon: <FaCreditCard />, label: t('Manage Subscriptions') },
        { id: 'connected', icon: <FaLink />, label: t('Connected Accounts') },
        { id: 'user-actions', icon: <FaCog />, label: t('User Actions') },
        { id: 'languages', icon: <FaLanguage />, label: t('Language') },
    ];
    
    return (
        <div className="account-page-content">
            <div className={`account-page ${isSidebarCollapsed ? 'collapsed' : ''} ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                    <FaBars />
                </button>
                <div className="sidebar">
                    <div className="sidebar-toggle" onClick={toggleSidebar}>
                        {isCollapsed ? <IoMdArrowDropright /> : <IoMdArrowDropleft />}
                    </div>

                    <ul>
                        {menuItems.map((item) => (
                            <li
                                key={item.id}
                                className={activeSection === item.id || activePage === item.id ? 'active' : ''}
                                onClick={() => {
                                    if (['flights', 'orderHistory', 'languages', 'savedOrders'].includes(item.id)) {
                                        setActivePage(item.id);
                                        setActiveSection(null);
                                    } else {
                                        setActiveSection(item.id);
                                        setActivePage(null);
                                    }
                                    setIsMobileMenuOpen(false);
                                }}
                            >
                                <span className="icon">{item.icon}</span>
                                {!isSidebarCollapsed && <span className="label">{item.label}</span>}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="main-content">
                    <h1>{t('Welcome')}, {displayName || t('User')}</h1>

                    {activePage === 'flights' && <SavedFlights loading={loading} setLoading={setLoading} />} 
                    {activePage === 'orderHistory' && <OrderHistory loading={loading} setLoading={setLoading} />} 
                    {activePage === 'languages' && <SettingsPage loading={loading} setLoading={setLoading} />} 
                    {activePage === 'savedOrders' && <SavedOrders loading={loading} setLoading={setLoading} />}

                    {activeSection === 'personal' && (
                        <section className="account-section">
                            <h2>{t('Personal Information')}</h2>
                            <div className="input-group">
                                <label>{t('Display Name')}</label>
                                <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} disabled={loading} />
                            </div>
                            <div className="input-group">
                                <label>{t('Email')}</label>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                            </div>
                            <div className="input-group">
                                <label>{t('Phone Number')}</label>
                                <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} disabled={loading} />
                            </div>
                            <button onClick={handleUpdateProfile} className="primary-btn" disabled={loading}>{t('Update Profile')}</button>
                        </section>
                    )}
                    {activeSection === 'security' && (
                        <section className="account-section">
                            <h2>{t('Security Settings')}</h2>
                            <div className="input-group">
                                <label>{t('Current Password')}</label>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} />
                            </div>
                            <div className="input-group">
                                <label>{t('New Password')}</label>
                                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} disabled={loading} />
                            </div>
                            <button onClick={handleChangePassword} className="primary-btn" disabled={loading}>{t('Change Password')}</button>
                        </section>
                    )}
                    {activeSection === 'activity' && (
                        <section className="account-section">
                            <h2>{t('Account Activity')}</h2>
                            <p>{t('Recent logins, devices, etc.')}</p>
                        </section>
                    )}
                    {activeSection === 'subscriptions' && (
                        <section className="account-section">
                            <h2>{t('Manage Subscriptions')}</h2>
                            <p>{t('Subscription status and payment methods')}</p>
                        </section>
                    )}
                    {activeSection === 'connected' && (
                        <section className="account-section">
                            <h2>{t('Connected Accounts')}</h2>
                            <p>{t('Google, Facebook, etc.')}</p>
                        </section>
                    )}
                    {activeSection === 'user-actions' && (
                        <section className="account-section">
                            <button onClick={handleSignOut} className="secondary-btn" disabled={loading}>{t('Sign Out')}</button>
                            <button onClick={handleDeleteAccount} className="danger-btn" disabled={loading}>{t('Delete Account')}</button>
                        </section>
                    )}
                    {activeSection === 'languages' && (
                        <section className="account-section">
                            <h2>{t('Language Settings')}</h2>
                            <div className="language-buttons">
                                <button onClick={() => handleLanguageChange('en')} className="language-btn" disabled={loading}>
                                    {t('English')}
                                </button>
                                <button onClick={() => handleLanguageChange('he')} className="language-btn" disabled={loading}>
                                    {t('Hebrew')}
                                </button>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AccountPage;