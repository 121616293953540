import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, setDoc, collection, addDoc, query, onSnapshot, orderBy, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import '../styles/ChatWithAgent.css';
import gg from '../assets/PHOTO-2024-09-02-23-27-10.jpg'

const ChatWithAgentComponent = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isAgentTyping, setIsAgentTyping] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'messages', user.uid);
      const chatCollectionRef = collection(userDocRef, 'chat');
      const q = query(chatCollectionRef, orderBy('createdAt'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const msgs = [];
        querySnapshot.forEach((doc) => {
          msgs.push(doc.data());
        });
        setMessages(msgs);
      });
      return () => unsubscribe();
    }
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'messages', user.uid);
        const chatCollectionRef = collection(userDocRef, 'chat');

        const newMessage = {
          text: inputMessage,
          sender: 'user',
          createdAt: new Date(),
          read: true // Always true in chat collection
        };

        const unreadMessage = {
          ...newMessage,
          read: false // False in unreadMessages array
        };

        // Create the document if it doesn't exist
        await setDoc(userDocRef, { 
          uid: user.uid,
          username: user.displayName || 'Unknown User'
        }, { merge: true });

        // Add message to user's unique document
        await addDoc(chatCollectionRef, newMessage);

        // Add message to "unread messages" array
        await updateDoc(userDocRef, {
          unreadMessages: arrayUnion(unreadMessage)
        });

        setInputMessage('');

        // Send automatic message only if it's the first message
        if (messages.length === 0) {
          setIsAgentTyping(true);
          setTimeout(async () => {
            setIsAgentTyping(false);
            const autoReply = {
              text: t('Hi, Thank you for your message, Soon one of our agents will contact you'),
              sender: 'agent',
              createdAt: new Date(),
              read: true
            };
            await addDoc(chatCollectionRef, autoReply);
          }, 2000);
        }
      }
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h1>{t('PLEASUAIR Concierge')}</h1>
      </div>
      <div className="chat-box">
        <div className="chat-messages">
          {messages.length === 0 ? (
            <div className="initial-message">
              <img src={gg} alt="Contact Danny the Agent" className="agent-image" width="200" height="200" />
              <p>{t('Contact Danny, our friendly agent, to assist you with your travel needs!')}</p>
            </div>
          ) : (
            messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.sender}`}
                style={{
                  alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  backgroundColor: message.sender === 'user' ? '#007bff' : '#f1f1f1',
                  color: message.sender === 'user' ? '#fff' : '#000',
                }}
              >
                {message.text}
              </div>
            ))
          )}
          {isAgentTyping && (
            <div className="message agent typing">
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={sendMessage} className="chat-input">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder={t('Type your message here...')}
          />
          <button type="submit">➤</button>
        </form>
      </div>
    </div>
  );
};

export default ChatWithAgentComponent;