






import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useSpring, useInView } from 'framer-motion';
import { FaPlane, FaSearch, FaUser, FaBars, FaStar, FaGlobe, FaHeart, FaTimes, FaCloudSun, FaInfoCircle, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../../styles/Home.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logos/logo PleasuAir.png';
import Wlogo from '../../assets/logos/logo PleasuAir w1.svg';
import ImageGallery from './ImageGallery';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';

const destinations = [
  { 
    name: 'Paris', 
    image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?auto=format&fit=crop&w=1000&q=80',
    articles: [
      { title: "Top 10 Must-Visit Attractions in Paris", url: "#" },
      { title: "A Foodie's Guide to Parisian Cuisine", url: "#" },
    ],
    weather: "Average temperature: 15°C (59°F). Best time to visit: April to October.",
    info: "Paris, the City of Light, is renowned for its art, fashion, gastronomy, and culture. Home to iconic landmarks like the Eiffel Tower and the Louvre, Paris offers a perfect blend of history and modernity."
  },
  { 
    name: 'Tokyo', 
    image: 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?auto=format&fit=crop&w=1000&q=80',
    articles: [
      { title: "Navigating Tokyo's Subway System: A Beginner's Guide", url: "#" },
      { title: "Tokyo's Hidden Gems: Off the Beaten Path", url: "#" },
    ],
    weather: "Average temperature: 16°C (61°F). Best time to visit: March to May and September to November.",
    info: "Tokyo, a city where tradition meets futurism, offers visitors a unique blend of ancient temples, cutting-edge technology, and vibrant pop culture. From sushi to sumo, there's something for everyone in Japan's bustling capital."
  },
  { 
    name: 'New York', 
    image: 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?auto=format&fit=crop&w=1000&q=80',
    articles: [
      { title: "Broadway Shows: What to See and How to Get Tickets", url: "#" },
      { title: "New York on a Budget: Free Things to Do in the Big Apple", url: "#" },
    ],
    weather: "Average temperature: 13°C (55°F). Best time to visit: April to June and September to November.",
    info: "New York City, the city that never sleeps, is a global hub of culture, finance, and entertainment. From the bright lights of Times Square to the tranquility of Central Park, NYC offers a diverse range of experiences for every type of traveler."
  },
  { 
    name: 'Dubai', 
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=1000&q=80',
    articles: [
      { title: "Desert Safari Adventures: What to Expect", url: "#" },
      { title: "Luxury Shopping in Dubai: A Guide to the Best Malls", url: "#" },
    ],
    weather: "Average temperature: 28°C (82°F). Best time to visit: November to March.",
    info: "Dubai, a city of superlatives, is known for its ultramodern architecture, luxury shopping, and vibrant nightlife. From the world's tallest building to artificial islands, Dubai showcases human ingenuity and ambition at its finest."
  },
];

const CountingNumber = ({ value, inView, decimals = 0 }) => {
  const spring = useSpring(0, { duration: 2000 });
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    if (inView) {
      spring.set(value);
    } else {
      spring.set(0);
    }
    return spring.onChange(latest => {
      setDisplayValue(Number(latest.toFixed(decimals)));
    });
  }, [spring, value, inView, decimals]);

  return <span>{displayValue}</span>;
};

export default function Component() {
  const { t } = useTranslation();
  const [currentDestIndex, setCurrentDestIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const location = useLocation();
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [totalDestinations, setTotalDestinations] = useState(0);
  const statsRef = useRef(null);
  const isInView = useInView(statsRef, { once: false, margin: "-100px" });
  const [selectedDestination, setSelectedDestination] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setDirection(1);
      setCurrentDestIndex((prevIndex) => (prevIndex + 1) % destinations.length);
    }, 7000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchTotalPassengers = async () => {
      try {
        const flightsCollection = collection(db, 'flights');
        const flightsSnapshot = await getDocs(flightsCollection);
        let total = 0;
        flightsSnapshot.forEach((doc) => {
          const flightData = doc.data();
          if (flightData.bookings) {
            total += flightData.bookings.length;
          }
        });
        setTotalPassengers(total);
      } catch (error) {
        console.error('Error fetching total passengers:', error);
      }
    };

    const fetchTotalDestinations = async () => {
      try {
        const flightsCollection = collection(db, 'flights');
        const flightsSnapshot = await getDocs(flightsCollection);
        const uniqueDestinations = new Set();
        flightsSnapshot.forEach((doc) => {
          const flightData = doc.data();
          uniqueDestinations.add(flightData.routeDestination);
        });
        setTotalDestinations(uniqueDestinations.size);
      } catch (error) {
        console.error('Error fetching total destinations:', error);
      }
    };

    fetchTotalPassengers();
    fetchTotalDestinations();
  }, []);

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
  };

  return (
    <div className="home-page">
      <main>
        <section className="home-hero">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentDestIndex}
              className="home-hero-image"
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              style={{ backgroundImage: `url(${destinations[currentDestIndex].image})` }}
            />
          </AnimatePresence>
          <div className="home-hero-content">
            <img src={Wlogo} alt={t('PleasuAir Logo')} className="logo-image1" />

            <motion.h1
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              {t('Discover the World with PleasuAir')}
            </motion.h1>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7, duration: 0.8 }}
            >
              {t('Luxurious Flight Experience to Every Destination')}
            </motion.p>
            <motion.div
              className="home-search-bar"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.9, duration: 0.8 }}
            >
              <input type="text" placeholder={t('Where would you like to fly?')} />

              <Link to="/book-flight" className={location.pathname === '/book-flight' ? 'active' : ''}>
                <button className="home-search-button">
                  <FaSearch className='h-s-btn'/> {t('Search Flights')}
                </button>
              </Link>
            </motion.div>
          </div>
        </section>

        <section id="stats" className="home-stats" ref={statsRef}>
          <div className="home-stats-container">
            <motion.div
              className="home-stat-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 0.5 }}
            >
              <FaPlane className="home-stat-icon" />
              <h3><CountingNumber value={totalDestinations} inView={isInView} /></h3>
              <p>{t('Destinations')}</p>
            </motion.div>
            <motion.div
              className="home-stat-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <FaUser className="home-stat-icon" />
              <h3><CountingNumber value={totalPassengers} inView={isInView} /></h3>
              <p>{t('Happy Customers')}</p>
            </motion.div>
            <motion.div
              className="home-stat-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <FaStar className="home-stat-icon" />
              <h3>
                <CountingNumber value={4.3} inView={isInView} decimals={1} />
                /5
              </h3>
              <p>{t('Customer Rating')}</p>
            </motion.div>
          </div>
        </section>

        <section id="destinations" className="home-destinations">
          <h2>{t('Top Destinations')}</h2>
          <div className="home-destination-grid">
            {destinations.map((dest, index) => (
              <motion.div
                key={dest.name}
                className="home-destination-card"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedDestination(dest)}
              >
                <img src={dest.image} alt={t(dest.name)} />
                <h3>{t(dest.name)}</h3>
              </motion.div>
            ))}
          </div>
        </section>

        <AnimatePresence>
          {selectedDestination && (
            <motion.div
              className="destination-modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className="destination-modal-content"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ type: "spring", damping: 15 }}
              >
                <button className="close-modal" onClick={() => setSelectedDestination(null)}>
                  <FaTimes />
                </button>
                <h2>{t(selectedDestination.name)}</h2>
                <img src={selectedDestination.image} alt={t(selectedDestination.name)} />
                <div className="destination-info">
                  <h3>{t('Articles and Guides')}</h3>
                  <ul>
                    {selectedDestination.articles.map((article, index) => (
                      <li key={index}><a href={article.url}>{t(article.title)}</a></li>
                    ))}
                  </ul>
                  <h3>{t('Weather Information')}</h3>
                  <p><FaCloudSun /> {t(selectedDestination.weather)}</p>
                  <h3>{t('Destination Overview')}</h3>
                  <p><FaInfoCircle /> {t(selectedDestination.info)}</p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <section id="services" className="home-services">
          <h2>{t('Our Premium Services')}</h2>
          <div className="home-services-grid">
            <motion.div
              className="home-service-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaPlane className="home-service-icon" />
              <h3>{t('Luxury Flights')}</h3>
              <p>{t('Experience unparalleled comfort with our spacious first-class cabins and gourmet dining.')}</p>
            </motion.div>
            <motion.div
              className="home-service-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <FaUser className="home-service-icon" />
              <h3>{t('Personal Concierge')}</h3>
              <p>{t('Enjoy personalized assistance from booking to arrival, ensuring a seamless travel experience.')}</p>
            </motion.div>
            <motion.div
              className="home-service-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <FaGlobe className="home-service-icon" />
              <h3>{t('Global Lounge Access')}</h3>
              <p>{t('Relax in exclusive airport lounges worldwide, featuring premium amenities and services.')}</p>
            </motion.div>
          </div>
        </section>

        <section id="testimonials" className="home-testimonials">
          <h2>{t('What Our Customers Say')}</h2>
          <div className="home-testimonial-grid">
            <motion.div
              className="home-testimonial-card"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <p>{t('"PleasuAir made my dream vacation a reality. The service was impeccable from start to finish!"')}</p>
              <div className="home-testimonial-author">
                <img src="https://people.com/thmb/4YK39p3zvm6jzE6XjXSHPwuAiS8=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(624x0:626x2):format(webp)/charlotte-triggs-2-fd81e5b143484cc88cc628667a29815f.jpg" alt={t("Sarah J.")} />
                <div>
                  <h4>{t('Sarah J.')}</h4>
                  <div className="home-testimonial-rating">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="home-testimonial-card"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <p>{t('"I\'ve never experienced such luxury in air travel. PleasuAir sets a new standard for flying!"')}</p>
              <div className="home-testimonial-author">
                <img src="https://pic.yeshiva.org.il/SiteProjects/YeshivaModels/Handlers/InternalFiles.ashx?act=pictures&f=35026" alt={t("Michael R.")} />
                <div>
                  <h4>{t('Michael R.')}</h4>
                  <div className="home-testimonial-rating">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section id="cta" className="home-cta">
          <motion.div
            className="home-cta-content"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2>{t('Ready for Your Next Adventure?')}</h2>
            <p>{t('Book your luxurious flight experience with PleasuAir today and embark on a journey of a lifetime.')}</p>

            <Link to="/book-flight" className={location.pathname === '/book-flight' ? 'active' : ''}>
              <button className="home-cta-button">
                <FaHeart /> {t('Book Now')}
              </button>
            </Link>
          </motion.div>
        </section>
      </main>

      <footer className="home-footer">
        <div className="home-footer-content">
          <div className="home-footer-section">
            <h3>{t('PleasuAir')}</h3>
            <p>{t('Luxurious flight experiences to every destination')}</p>
          </div>
          <div className="home-footer-section">
            <h3>{t('Contact Us')}</h3>
            <p>{t('Phone')}: 03-1234567</p>
            <p>{t('Email')}: info@pleasuair.com</p>
          </div>
          <div className="home-footer-section">
          <h3>{t('Developed by:')}</h3>
          <div className="developer-info">
            <a href="https://yehudacollins.com/" target="_blank" rel="noopener noreferrer">
              <FaGlobe /> {t('Yehuda Collins')}
            </a>
          </div>
          <div className="developer-info">
            <a href="https://developer2-website.com" target="_blank" rel="noopener noreferrer">
              <FaGlobe /> {t('Meni Viner')}
            </a>
          </div>
        </div>
        <div className="home-footer-section">
            <h3>{t('Follow Us')}</h3>
            <div className="home-social-icons">
              <a href="https://www.instagram.com/pleasuair" target="_blank" rel="noopener noreferrer" aria-label={t('Follow us on Instagram')}>
                <FaInstagram />
              </a>
              <a href="https://www.facebook.com/pleasuair" target="_blank" rel="noopener noreferrer" aria-label={t('Follow us on Facebook')}>
                <FaFacebookF />
              </a>
            </div>
          </div>
          
        </div>
        <div className="home-footer-bottom">
          <p>&copy; 2023 PleasuAir. {t('All rights reserved.')}</p>
        </div>
       
      </footer>
    </div>
  );
}